import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import * as _ from 'lodash-es';
import { BehaviorSubject, defer, first, from, map, Observable, retry } from 'rxjs';
import { Cart } from '~/database/models/cart';
import { ICartData } from '~/database/models/cart-data.interface';
import { ICartProductData } from '~/database/models/cart-product-data.interface';
import { Product } from '~/database/models/product';
import { Restaurant } from '~/database/models/restaurant';
import { IRestaurantData } from '~/database/models/restaurant-data.interface';
import { AuthenticationService } from './authentication.service';
import { LaravelApiService, resultIsCollection, UriRoute } from './laravel-api.service';

export type CartItem = { product: Product } & Pick<
  ICartProductData,
  | 'price'
  | 'quantity'
  | 'notes'
  | 'offerApplicable'
  | 'promocode'
  | 'promoquantity'
  | 'extras'
  | 'paiditems'
  | 'productPosition'
  | 'teamId'
>;

export interface CartData {
  customerId: string | undefined;
  teamId: string | null | undefined;
}

export interface OrderData {
  orderId: string;
  token: string;
}

export interface UserInfo {
  name: string | null | undefined;
  email: string | null | undefined;
  phoneNumber: string | null | undefined;
  apartmentNumber: string | null | undefined;
  deliveryNotes: string | null | undefined;
  ordertype: string | null | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class ItemService {
  private items: CartItem[] = [];
  private itemsSubject = new BehaviorSubject<CartItem[]>([]);
  currentRestaurant: Restaurant | null | undefined;
  restaurant: Restaurant | null | undefined;
  private selectedRestaurant: string | null = null;
  private restaurantChangeCallback: ((restaurant: string | null) => void) | null = null;
  constructor(
    private storage: Storage,
    private auth: AuthenticationService,
    private api: LaravelApiService
  ) {
    this.initStorage();
    this.loadItemsFromStorage();
  }
  private async initStorage() {
    const storage = await this.storage.create();
    //  await this.storage.clear();
    // Now you can use the storage instance for your operations
  }

  async saveItem(item: CartItem) {
    this.items.push(item as CartItem);
    this.updateStorageAndSubject();
  }

  getItems(): Observable<CartItem[]> {
    return this.itemsSubject.asObservable();
  }

  async loadItemsFromStorage() {
    const storedItems = await this.storage.get('items');

    if (storedItems) {
      this.items = storedItems;

      for (const item of this.items) {
        const product: Product = item.product as Product;
        item.product = product;
      }

      this.updateStorageAndSubject();
    }
  }

  async deleteItem(index: number) {
    // const index = this.items.findIndex(item => item === itemToDelete);

    if (index !== -1) {
      this.items.splice(index, 1); // Remove the item from thgure list
      this.updateStorageAndSubject();
    }
  }

  async deleteItemfromcart(itemToDelete: string | null) {
    const index = this.items.findIndex((item) => item.product.id === itemToDelete);

    if (index !== -1) {
      this.items.splice(index, 1); // Remove the item from the in-memory array
      this.updateStorageAndSubject(); // Update local storage and notify subscribers
    }
  }

  async deleteItemFromStorage(itemToDelete: CartItem): Promise<void> {
    const index = this.items.findIndex((item) => item === itemToDelete);

    if (index !== -1) {
      this.items.splice(index, 1); // Remove the item from the local array
      this.updateStorageAndSubject(); // Update the storage and observable
    }
  }

  private async updateStorageAndSubject() {
    await this.storage.set('items', this.items); // Update the storage
    this.itemsSubject.next([...this.items]); // Emit the updated list
  }

  async updateItem(updatedItem: CartItem) {
    const index = this.items.findIndex((item) => item.product.id === updatedItem.product.id);

    if (index !== -1) {
      this.items[index] = updatedItem;
      this.updateStorageAndSubject();
    }
  }
  async saveSelectedMainCity(cityName: string): Promise<void> {
    await this.storage.set('selectedMainCity', cityName);
    await new Promise((resolve) => setTimeout(resolve, 100));
  }

  // New method to get the selected city name
  async getSelectedMainCity(): Promise<string | null> {
    return await this.storage.get('selectedMainCity');
  }

  async saveSelectedCity(cityName: string): Promise<void> {
    await this.storage.remove('selectedRestaurant');
    await new Promise((resolve) => setTimeout(resolve, 100)); // Add a delay
    await this.storage.set('selectedCity', cityName);
    await new Promise((resolve) => setTimeout(resolve, 100));
  }

  // New method to get the selected city name
  async getSelectedCity(): Promise<string | null> {
    return await this.storage.get('selectedCity');
  }

  async saveSelectedRestaurant(restaurantName: string | null): Promise<void> {
    await this.storage.set('selectedRestaurant', restaurantName);

    if (this.restaurantChangeCallback) {
      const updatedRestaurant = await this.getSelectedRestaurant();
      this.restaurantChangeCallback(updatedRestaurant);
    }
    // You can add a delay here to allow time for the storage operation to complete.
    // For example, use setTimeout.
    await new Promise((resolve) => setTimeout(resolve, 100)); // Adjust the delay time as needed.
  }

  setRestaurantChangeCallback(callback: (restaurant: string | null) => void): void {
    this.restaurantChangeCallback = callback;
  }
  // New method to get the selected restaurant name
  async getSelectedRestaurant(): Promise<string | null> {
    return await this.storage.get('selectedRestaurant');
  }

  // New method to get the selected restaurant name
  async getSelectedRestaurantId(): Promise<string | null> {
    return await this.storage.get('selectedRestaurantId');
  }

  // New method to save the selected restaurant name
  async saveSelectedRestaurantId(restaurantId: string | null): Promise<void> {
    await this.storage.set('selectedRestaurantId', restaurantId);
    // You can add a delay here to allow time for the storage operation to complete.
    // For example, use setTimeout.
    await new Promise((resolve) => setTimeout(resolve, 100)); // Adjust the delay time as needed.
  }

  getSelectedRestaurantIdObservable(): Observable<string | null> {
    return defer(() => from(this.storage.get('selectedRestaurantId')));
  }

  getRestaurantId(): Observable<string | null> {
    return this.getSelectedRestaurantIdObservable();
  }

  async addItemToCart(item: CartItem): Promise<number> {
    const userType = this.auth.currentUserType;

    if (userType == 'regcustomer') {
      const itemsdata = {
        productId: item.product.id,
        price: item.price,
        quantity: item.quantity,
        notes: item.notes,
        offerApplicable: item.offerApplicable,
        promocode: item.promocode,
        promoquantity: item.promoquantity,
        extras: item.extras,
        paiditems: item.paiditems,
      };

      const userId = this.auth.currentUser?.uid;
      await this.getRestaurantByName(item.teamId);
      const cartData: CartData = {
        customerId: userId,
        teamId: this.restaurant?.data.id,
      };

      const cartBody = _.omitBy(cartData, _.isUndefined);

      cartBody.customerId = userId;

      try {
        await this.api
          .post<ICartData>(new UriRoute('user/{session}/addToCart', { session: cartBody.customerId }), {
            ...cartBody,
            itemsdata,
          })
          .pipe(
            first(),
            map(async ({ data }) => {
              if (data) {
                await this.deleteItemFromStorage(item);
                return new Cart(data, data.id, `restaurants/${data.teamId}/cart/}`);
              }

              return null;
            })
          )
          .toPromise();
      } catch (err) {
        // Handle any errors that occur during the API call
        console.error(err);
        return -1;
      }
    }

    // Handle other cases where item addition is not allowed
    return -1;
  }

  async getRestaurantByName(restaurantName: any) {
    try {
      console.log(restaurantName);
      const uri = new UriRoute('teambyname/{team}', { team: restaurantName });
      this.restaurant = this.currentRestaurant = await this.api
        .get<IRestaurantData>(uri)
        .pipe(
          retry(2),
          first(),
          map(async (res: any) => {
            if (!res.data || resultIsCollection(res)) {
              return null;
            }

            return new Restaurant(res.data, res.data.id);
          })
        )
        .toPromise();
    } catch (err: any) {
      console.log(err);
    }
    console.log(this.restaurant);
  }

  // New method to get the selected restaurant name
  async getSelectedCartId(): Promise<string | null> {
    return await this.storage.get('selectedCartId');
  }

  // New method to save the selected restaurant name
  async saveSelectedCartId(restaurantId: string): Promise<void> {
    await this.storage.set('selectedCartId', restaurantId);
    // You can add a delay here to allow time for the storage operation to complete.
    // For example, use setTimeout.
    await new Promise((resolve) => setTimeout(resolve, 100)); // Adjust the delay time as needed.
  }

  // New method to get the user's address
  async getUserAddress(): Promise<string | null> {
    return await this.storage.get('userAddress');
  }

  // New method to save the user's address
  async saveUserAddress(address: string): Promise<void> {
    await this.storage.set('userAddress', address);
    // Add a delay if needed
    await new Promise((resolve) => setTimeout(resolve, 100));
  }

  // New method to get the user's latitude
  async getUserLatitude(): Promise<number | null> {
    const latitudeStr = await this.storage.get('userLatitude');
    return latitudeStr ? parseFloat(latitudeStr) : null;
  }

  // New method to save the user's latitude
  async saveUserLatitude(latitude: number): Promise<void> {
    await this.storage.set('userLatitude', latitude.toString());
    // Add a delay if needed
    await new Promise((resolve) => setTimeout(resolve, 100));
  }

  // New method to get the user's longitude
  async getUserLongitude(): Promise<number | null> {
    const longitudeStr = await this.storage.get('userLongitude');
    return longitudeStr ? parseFloat(longitudeStr) : null;
  }

  // New method to save the user's longitude
  async saveUserLongitude(longitude: number): Promise<void> {
    await this.storage.set('userLongitude', longitude.toString());
    // Add a delay if needed
    await new Promise((resolve) => setTimeout(resolve, 100));
  }

  // New method to get user info
  async getUserInfo(): Promise<UserInfo[] | null> {
    const userInfoStr = await this.storage.get('userInfo');
    if (userInfoStr) {
      try {
        const userInfoArray = JSON.parse(userInfoStr) as UserInfo[];
        return userInfoArray;
      } catch (error) {
        // Handle parsing error if needed
        console.error('Error parsing user info:', error);
      }
    }
    return null;
  }

  // New method to save user info
  async saveUserInfo(userInfoArray: UserInfo[]): Promise<void> {
    const userInfoStr = JSON.stringify(userInfoArray);
    await this.storage.set('userInfo', userInfoStr);
    // Add a delay if needed
    await new Promise((resolve) => setTimeout(resolve, 100));
  }

  // New method to get the user's latitude
  async getDeliveryFee(): Promise<number | null> {
    const deliveryFee = await this.storage.get('deliveryFee');
    return deliveryFee ? parseFloat(deliveryFee) : null;
  }

  // New method to save the user's latitude
  async saveDeliveryFee(deliveryFee: number): Promise<void> {
    await this.storage.set('deliveryFee', deliveryFee);
    // Add a delay if needed
    await new Promise((resolve) => setTimeout(resolve, 100));
  }

  // New method to get the payment token
  async getPaymentToken(): Promise<string | null> {
    return await this.storage.get('paymentToken');
  }

  // New method to save the payment token
  async savePaymentToken(token: string): Promise<void> {
    await this.storage.set('paymentToken', token);
    // Add a delay if needed
    await new Promise((resolve) => setTimeout(resolve, 100));
  }

  // New method to delete the payment token
  async deletePaymentToken(): Promise<void> {
    await this.storage.remove('paymentToken');
  }

  // Method to check if the payment token exists in storage
  async doesPaymentTokenExist(): Promise<boolean> {
    const token = await this.getPaymentToken();
    return token !== null;
  }

  // New method to get orders
  async getOrders(): Promise<OrderData[] | null> {
    const ordersStr = await this.storage.get('orders');
    if (ordersStr) {
      try {
        const ordersArray = JSON.parse(ordersStr) as OrderData[];
        return ordersArray;
      } catch (error) {
        // Handle parsing error if needed
        console.error('Error parsing orders:', error);
      }
    }
    return null;
  }

  // New method to save an order
  async saveOrder(orderId: string, token: string): Promise<void> {
    // Get the existing orders from the cache or initialize an empty array
    const existingOrders = (await this.getOrders()) || [];

    // Add the new order to the array
    existingOrders.push({ orderId, token });

    // Save the updated array in the cache
    const ordersStr = JSON.stringify(existingOrders);
    await this.storage.set('orders', ordersStr);

    // Add a delay if needed
    await new Promise((resolve) => setTimeout(resolve, 100));
  }
}
