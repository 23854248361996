import IFirebaseData from '~lib/database/firebase-data.interface';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';
export type BookingStatusSlug = 'accepted' | 'booked' | 'canceled' | 'attended' | 'confirmed';

export interface IBookingStatusData extends IFirebaseData {
  id: string | null;
  slug: BookingStatusSlug;
  title: string;
  description: string | null;
  value: number;
  createdAt: string | null;
  updatedAt: string | null;
}

/**
 * Representa los detalles de un pedido.
 */
export class BookingStatus extends Model<IBookingStatusData> {
  public static type = 'booking_statuses';

  constructor(data: Partial<IBookingStatusData> = {}, id: string | null = null, parentPath?: string) {
    const defaultData: IBookingStatusData = {
      id: null,
      slug: 'booked',
      title: '',
      description: null,
      value: 0,
      createdAt: null,
      updatedAt: null,
    };

    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
    return this.rawData;
  }

  public get value(): number {
    return this.data.value;
  }

  public get slug(): BookingStatusSlug {
    return this.data.slug;
  }

  public get title() {
    return this.data.title;
  }
  public set title(value) {
    this.data.title = value;
  }
}
