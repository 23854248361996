import * as config from './config';

//import { emulators } from '../../firebase.json';

export const firebaseConfig = {
  apiKey: 'AIzaSyCo65bWEX488S4oVQgCCsOWAjvGEve2eFg',
  authDomain: 'skyliner-app.firebaseapp.com',
  projectId: 'skyliner-app',
  storageBucket: 'skyliner-app.appspot.com',
  messagingSenderId: '2816915519',
  appId: '1:2816915519:web:2551bf95481298724de08a',
  measurementId: 'G-MW179SF5SG',
};

export const environment = {
  production: config.app.production,
  appId: 'cl.skyliner.befaster',
  laravel: config.laravel,
  useEmulators: {
    // auth: ['localhost', emulators.auth.port],n
    // firestore: ['localhost', emulators.firestore.port],
  } as Partial<{ [key: string]: string[] }>,

  googleAuthClientId: '2816915519-9fdgql7crnv2imasalqjgg6ubng8q3kk.apps.googleusercontent.com',
};
