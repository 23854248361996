import { Setting } from '~/database/models/setting';
import IFirebaseData from '~lib/database/firebase-data.interface';
import { defaultedData } from '~lib/helpers';

export interface ICurrencySetting extends IFirebaseData {
  currency: string; // Código ISO de la moneda predeterminada, ej. 'USD'
}

export namespace ICurrencySetting {
  export const KEY = 'restaurant:currency';

  export const valueIs = (value: unknown): value is ICurrencySetting => {
    // TODO: Mejorar detección
    return typeof value === 'object';
  };
  export const defaultValue = (partialData: Partial<ICurrencySetting> = {}): ICurrencySetting => {
    const defaultData: ICurrencySetting = {
      currency: 'VED',
    };

    return defaultedData(partialData, defaultData);
  };

  export const settingIs = (setting: Setting<any>): setting is Setting<ICurrencySetting> => {
    return setting.key === KEY && valueIs(setting.value);
  };
}
