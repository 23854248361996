import { animate, animateChild, query, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import _ from 'lodash-es';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AllRatingsService } from '~/customer-registration/services/all-rating.service';
import { Product } from '~/database/models/product';
import { IProductData } from '~/database/models/product-data.interface';
import { Rating } from '~/database/models/rating';
import { Restaurant } from '~/database/models/restaurant';
import { User } from '~/database/models/user';
import { IUserData } from '~/database/models/user.interface';
import { LaravelApiService, UriRoute, resultIsCollection } from '~shared/services/laravel-api.service';

export interface CommentsData {
  ratings: Rating[];
  users$: Observable<User | null>;
}
interface ProductRatingDetails {
  id: string | null;
  name: string;
  ratings: number;
  comments: string;
}
interface ExtendedRating extends Rating {
  attendant: string | undefined;
  productRatingDetails: ProductRatingDetails[] | undefined;
  username: string | undefined;
  ratingsArray: { comments: string; name: string; ratings: number }[];
}

@Component({
  selector: 'app-comments',
  templateUrl: './comments-modal.component.html',
  styleUrls: ['./comments-modal.component.scss'],
  animations: [
    trigger('ngIfAnimation', [transition(':enter, :leave', [query('@*', animateChild())])]),
    trigger('easeInOut', [
      transition('void => *', [
        style({
          opacity: 0,
        }),
        animate(
          '500ms ease-in',
          style({
            opacity: 1,
          })
        ),
      ]),
      transition('* => void', [
        style({
          opacity: 1,
        }),
        animate(
          '500ms ease-in',
          style({
            opacity: 0,
          })
        ),
      ]),
    ]),
  ],
})
export class CommentsModalComponent implements OnInit {
  @Input()
  public restaurant = Restaurant;
  ratings: ExtendedRating[] | undefined;
  comments: CommentsData[] = [];
  constructor(
    public modalController: ModalController,
    private ratingService: AllRatingsService,
    private api: LaravelApiService
  ) {}
  @Input()
  isAttendant = false;

  ngOnInit() {
    this.ratingService.initializeRatings().subscribe((ratings: Rating[]) => {
      const extendedRatings = ratings as ExtendedRating[];
      extendedRatings.forEach(async (ratingsdata: ExtendedRating, key: any) => {
        const users$ = await this.api
          .get<IUserData>(new UriRoute(`getuser/${ratingsdata.data.userId}`))
          .pipe(
            first(),
            map((res: any) => {
              if (!res.data || resultIsCollection(res)) {
                return null;
              }
              return new User(res.data, res.data.id);
            })
          )
          .subscribe((user) => {
            extendedRatings[key].username = user?.data.name;
          });

        if (ratingsdata.data.attendantId && ratingsdata.data.attendantId != null) {
          const attendant$ = await this.api
            .get<IUserData>(new UriRoute(`getuser/${ratingsdata.data.attendantId}`))
            .pipe(
              first(),
              map((res: any) => {
                if (!res.data || resultIsCollection(res)) {
                  return null;
                }
                return new User(res.data, res.data.id);
              })
            )
            .subscribe((user) => {
              extendedRatings[key].attendant = user?.data.name;
            });
        }

        if (ratingsdata.data.productRatings && ratingsdata.data.productRatings != null) {
          const productratingArray = JSON.parse(JSON.stringify(ratingsdata.data.productRatings));

          const productCommentsArray = JSON.parse(JSON.stringify(ratingsdata.data.productComments));

          //let array:{ [key: string]: {} } = {};
          let array: ProductRatingDetails[] = [];
          JSON.parse(productratingArray).forEach((rating: any) => {
            const product$ = this.api
              .get<IProductData>(new UriRoute(`products/${rating.item_id}`))
              .pipe(
                first(),
                map((res: any) => {
                  if (!res.data || resultIsCollection(res)) {
                    return null;
                  }
                  return new Product(res.data, res.data.id);
                })
              )
              .subscribe((product: Product | null) => {
                if (product) {
                  array.push({
                    id: product.data.id,
                    name: product.data.name,
                    ratings: rating.item_rating,
                    comments: this.getComment(product.data.id, JSON.parse(productCommentsArray)),
                  });
                  //,'comments':this.getComment(rating.item_id,myArray)
                }
                extendedRatings[key].productRatingDetails = array;
              });
          });
        }
      });
      console.log(ratings);
      this.ratings = extendedRatings;
    });
  }
  getComment(id: string | null, finalarray: any) {
    let comment = '';
    finalarray.forEach((element: any) => {
      if (element.item_id == id) {
        comment = element.comment;
      }
    });
    return comment;
  }
  public dismiss() {
    this.modalController.dismiss({}, 'cancel');
  }
}
