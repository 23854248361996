import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import * as _ from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import IFirebaseData from '~lib/database/firebase-data.interface';
import { convertKeysToCamelCase, convertKeysToSnakeCase } from '~lib/helpers';
import { IHasHttpHeaders, IHttpRequestOptions } from '~lib/http-options.interface';
import { IJsonMap } from '~lib/types';

export interface ILaravelApiTokenClaims {
  /**
   * Token para autenticación Bearer
   */
  access_token: string;

  /**
   * Indica si el usuario es temporal. Es decir, se creó para pruebas y desarrollo y no debería afectar el entorno de producción.
   */
  temporary: boolean;

  /**
   * Obtiene un valor que indica si el usuario es un owner/mesero (true) o es un comensal (false).
   */
  is_attendant: boolean;

  /**
   * Indica para qué restaurant se inició sesión.
   */
  restaurant_id: string | null;

  /**
   * Indica para qué sesión de mesa está habilitado el cliente (sólo aplica para comensales).
   */
  // table_id?: string | null;
  table_session_id?: string | null;

  /**
   * Obtiene un valor que indica si el usuario es un owner/mesero (true) o es un comensal (false).
   */
  reg_type: string | null;
}

export interface ILaravelApiSingleResult<T extends IFirebaseData | IJsonMap> {
  data: T | null;
}

export interface ILaravelApiCollectionResult<T extends IFirebaseData | IJsonMap> {
  data: T[];
  links: {
    first: string;
    last: string;
    prev: string | null;
    next: string | null;
  };
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    links: { url: string | null; label: string; active: boolean }[];
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
}

/**
 * Determina si el resultado es un modelo o una colección.
 */
export const resultIsCollection = <T extends IFirebaseData | IJsonMap>(
  result: ILaravelApiSingleResult<T> | ILaravelApiCollectionResult<T>
): result is ILaravelApiCollectionResult<T> => {
  return Array.isArray(result.data);
};

export interface IRouteParams {
  [name: string]: string | number | boolean;
}

export class RouteParam {
  constructor(
    public readonly name: string,
    public readonly value: string | number | boolean
  ) {
    this.name = name.trim();
  }

  /**
   * Exporta el valor como un string.
   * @return [description]
   */
  public exportValue(): string {
    return this.value.toString();
  }
}

export class RouteParamsMap implements ParamMap {
  public readonly items: RouteParam[];

  public get keys(): string[] {
    const keys = this.items.map((item) => {
      return item.name;
    });

    return [...new Set(keys)];
  }

  constructor(params?: IRouteParams | RouteParam[]) {
    if (Array.isArray(params)) {
      this.items = params;
    } else {
      this.items = [];

      if (params) {
        Object.entries(params).forEach(
          ([
            name,
            value,
          ]) => {
            this.add(new RouteParam(name, value));
          }
        );
      }
    }
  }

  public add(param: RouteParam): this {
    this.items.push(param);

    return this;
  }

  public has(name: string): boolean {
    return this.keys.includes(name);
  }

  public get(name: string): string | null {
    const val = this.items.find((item) => {
      return item.name === name;
    });

    return val?.value.toString() || null;
  }

  public getAll(name: string): string[] {
    const val = this.items
      .filter((item) => {
        return item.name === name;
      })
      .map((item) => {
        return item.value.toString();
      });

    return val;
  }

  /**
   * Exporta los parámetros como un map con sus valores como string.
   */
  public exportStringMap(): { [name: string]: string | string[] } {
    const items: { [name: string]: string | string[] } = {};

    this.keys.forEach((name) => {
      let val: string | string[] = this.getAll(name);

      if (val.length === 1) {
        val = val[0];
      }

      items[name] = val;
    });

    return items;
  }

  public replacePlaceholders(input: string): string {
    let path = input;

    this.items.forEach((param) => {
      path = path.replace(`{${param.name}}`, param.exportValue());
    });

    return path;
  }
}

/**
 * Representa a un dominio del que se pueden extraer urls a partir de una ruta.
 */
export class UriEndpoint {
  constructor(
    public readonly domain: string,
    public readonly secure = true,
    public readonly port?: number
  ) {}

  public getUrl(targetRoute: UriRoute): string {
    const proto = this.secure ? 'https' : 'http';
    const port = this.port ? `:${this.port}` : '';

    const domainUrl = `${proto}://${this.domain}${port}`;

    return `${domainUrl}/${targetRoute.uri}`;
  }
}

/**
 * Representa a una ruta de un dominio. Ej: ``/api/users`
 */
export class UriRoute {
  public readonly metadata: {
    path: string;
    params: RouteParamsMap;
    prefix: string;
    secure: boolean;
  } = { path: '', params: new RouteParamsMap(), prefix: '', secure: true };

  /**
   * Obtiene la URL plana según los parámetros construidos en el objeto.
   */
  get uri(): string {
    const path = this.params().replacePlaceholders(this.path());

    return `${this.prefix()}/${path}`;
  }

  constructor(path: string = '', params: RouteParamsMap | IRouteParams | RouteParam[] = [], prefix: string = '') {
    this.path(path).params(params).prefix(prefix);
  }
  public prefix(): string;
  public prefix(routePrefix: string): this;
  public prefix(routePrefix?: string): this | string {
    if (!routePrefix) {
      return this.metadata.prefix;
    }
    this.metadata.prefix = routePrefix;

    return this;
  }

  /**
   * Get the path.
   */
  public path(): string;

  /**
   * Sets the path.
   */
  public path(value: string): this;
  public path(value?: string) {
    if (!value) {
      return this.metadata.path;
    }
    // Eliminar slashes al inicio y al final
    this.metadata.path = value.trim().replace(/^\/+/, '').replace(/\/+$/, '').trim();

    return this;
  }

  /**
   * Get the params.
   */
  public params(): RouteParamsMap;

  /**
   * Sets the params.
   */
  public params(value: RouteParamsMap | IRouteParams | RouteParam[]): this;

  public params(value?: RouteParamsMap | IRouteParams | RouteParam[]): RouteParamsMap | this {
    if (!value) {
      return this.metadata.params;
    }

    if (value instanceof RouteParamsMap) {
      this.metadata.params = value;
    } else {
      this.metadata.params = new RouteParamsMap(value);
    }

    return this;
  }

  public toString(): string {
    return this.uri;
  }
}

export interface IMappingOptions {
  keepSnakeKeys?: boolean;
  recursive?: boolean;
}

export class HttpTokenAuthentication {
  constructor(public readonly token: string) {
    //
  }

  get id() {
    return this.token.split('|').shift();
  }

  toHeaderMap() {
    return {
      Authorization: `Bearer ${this.token}`,
    };
  }

  toHeaderDictionary() {
    return {
      name: 'Authorization',
      value: `Bearer ${this.token}`,
    };
  }
}

@Injectable({
  providedIn: 'root',
})
export class LaravelApiService {
  protected config = environment.laravel.api;
  protected readonly endpoint: UriEndpoint;

  #accessToken: HttpTokenAuthentication | null = null;

  set accessToken(token: HttpTokenAuthentication | string | null) {
    if (token instanceof HttpTokenAuthentication) {
      this.#accessToken = token;
    } else if (typeof token === 'string') {
      this.#accessToken = new HttpTokenAuthentication(token);
    } else {
      this.#accessToken = null;
    }
  }

  getAccessToken(): HttpTokenAuthentication | null {
    return this.#accessToken;
  }

  public post<U extends IFirebaseData = IFirebaseData, T extends IJsonMap = IJsonMap>(
    uri: UriRoute,
    body: Partial<T> = {},
    options: IHttpRequestOptions = {},
    mapingOptions: IMappingOptions = { recursive: true }
  ): Observable<ILaravelApiSingleResult<U>> {
    const url = this.endpoint.getUrl(uri.prefix('api'));

    const requestBody = convertKeysToSnakeCase(_.omitBy(body, _.isUndefined), { recursive: true });

    const response = this.mapResult(
      this.http.post<ILaravelApiSingleResult<IJsonMap>>(url, requestBody, {
        responseType: 'json',
        ...this.addAuthenticationHeaderToOptions(options),
      }),
      mapingOptions
    );

    return response as Observable<ILaravelApiSingleResult<U>>;
  }

  constructor(protected http: HttpClient) {
    const { api } = environment.laravel;

    this.endpoint = new UriEndpoint(api.domain, api.https, api.port);
  }

  protected addAuthenticationHeaderToOptions<T extends IHasHttpHeaders>(options: T) {
    if (this.#accessToken) {
      if (options.headers instanceof HttpHeaders) {
        const { name, value } = this.#accessToken.toHeaderDictionary();

        options.headers.append(name, value);
      } else {
        options.headers = { ...this.#accessToken.toHeaderMap(), ...(options.headers || {}) };
      }
    }

    return options;
  }

  protected mapResult<T extends IFirebaseData>(
    stream: Observable<ILaravelApiSingleResult<IJsonMap>>,
    mapingOptions: IMappingOptions
  ): Observable<ILaravelApiSingleResult<T>>;
  protected mapResult<T extends IFirebaseData>(
    stream: Observable<ILaravelApiCollectionResult<IJsonMap>>,
    mapingOptions: IMappingOptions
  ): Observable<ILaravelApiCollectionResult<T>>;
  protected mapResult<T extends IFirebaseData>(
    stream: Observable<ILaravelApiSingleResult<IJsonMap> | ILaravelApiCollectionResult<IJsonMap>>,
    mapingOptions: IMappingOptions
  ): Observable<ILaravelApiSingleResult<T> | ILaravelApiCollectionResult<T>>;

  /**
   * Renombra las propiedades de la data obtenida a la forma camelCase
   */
  protected mapResult<T extends IFirebaseData>(
    stream: Observable<ILaravelApiSingleResult<IJsonMap> | ILaravelApiCollectionResult<IJsonMap>>,
    mapingOptions: IMappingOptions = { recursive: true }
  ): Observable<ILaravelApiSingleResult<T> | ILaravelApiCollectionResult<T>> {
    return stream.pipe(
      map((result) => {
        if (!result) {
          // En caso de que el body esté vacío
          result = { data: null };
        }

        if (!mapingOptions.keepSnakeKeys) {
          if (resultIsCollection(result)) {
            result.data = result.data.map((item) => {
              return convertKeysToCamelCase(item, { recursive: mapingOptions.recursive });
            });
          } else if (result.data) {
            result.data = convertKeysToCamelCase(result.data, { recursive: mapingOptions.recursive });
          }
        }

        return result as ILaravelApiSingleResult<T> | ILaravelApiCollectionResult<T>;
      })
    );
  }

  public get<T extends IFirebaseData>(
    uri: UriRoute,
    options: IHttpRequestOptions = {},
    mapingOptions: IMappingOptions = { recursive: true }
  ): Observable<ILaravelApiSingleResult<T> | ILaravelApiCollectionResult<T>> {
    const url = this.endpoint.getUrl(uri.prefix('api'));

    return this.mapResult(
      this.http.get<ILaravelApiSingleResult<IJsonMap> | ILaravelApiCollectionResult<IJsonMap>>(url, {
        responseType: 'json',
        ...this.addAuthenticationHeaderToOptions(options),
      }),
      mapingOptions
    );
  }

  public patch<U extends IFirebaseData = IFirebaseData, T extends IJsonMap = IJsonMap>(
    uri: UriRoute,
    body: Partial<T> = {},
    options: IHttpRequestOptions = {},
    mapingOptions: IMappingOptions = { recursive: true }
  ): Observable<ILaravelApiSingleResult<U>> {
    const url = this.endpoint.getUrl(uri.prefix('api'));

    const requestBody = convertKeysToSnakeCase(_.omitBy(body, _.isUndefined), { recursive: true });

    const response = this.mapResult(
      this.http.patch<ILaravelApiSingleResult<IJsonMap>>(url, requestBody, {
        responseType: 'json',
        ...this.addAuthenticationHeaderToOptions(options),
      }),
      mapingOptions
    );

    return response as Observable<ILaravelApiSingleResult<U>>;
  }

  public put<U extends IFirebaseData = IFirebaseData, T extends IJsonMap = IJsonMap>(
    uri: UriRoute,
    body: Partial<T> = {},
    options: IHttpRequestOptions = {},
    mapingOptions: IMappingOptions = { recursive: true }
  ): Observable<ILaravelApiSingleResult<U>> {
    const url = this.endpoint.getUrl(uri.prefix('api'));

    const requestBody = convertKeysToSnakeCase(_.omitBy(body, _.isUndefined), { recursive: true });

    const response = this.mapResult(
      this.http.put<ILaravelApiSingleResult<IJsonMap>>(url, requestBody, {
        responseType: 'json',
        ...this.addAuthenticationHeaderToOptions(options),
      }),
      mapingOptions
    );

    return response as Observable<ILaravelApiSingleResult<U>>;
  }

  public delete<T extends IFirebaseData>(
    uri: UriRoute,
    options: IHttpRequestOptions = {},
    mapingOptions: IMappingOptions = { recursive: true }
  ): Observable<ILaravelApiSingleResult<T>> {
    const url = this.endpoint.getUrl(uri.prefix('api'));

    return this.mapResult(
      this.http.delete<ILaravelApiSingleResult<IJsonMap>>(url, {
        ...this.addAuthenticationHeaderToOptions(options),
      }),
      mapingOptions
    );
  }

  public download(
    uri: UriRoute,
    options: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };

      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;

      withCredentials?: boolean;
    } = {}
  ): Observable<Blob> {
    const defaultOptions: { responseType: 'blob'; observe: 'body' } = { responseType: 'blob', observe: 'body' };
    const requestOptions: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType: 'blob';
      withCredentials?: boolean;
    } = { ...options, ...defaultOptions };

    const url = this.endpoint.getUrl(uri.prefix('api'));

    return this.http.get(url, { ...this.addAuthenticationHeaderToOptions(requestOptions) });
  }

  // TODO: Demás métodos HTTP
}
