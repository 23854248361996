import * as _ from 'lodash-es';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';
import { LaravelApiService } from '~shared/services/laravel-api.service';
import { Order } from './order';
import { IOrderData } from './order-data.interface';
import { IOrderDriverData } from './order-driver-data.interface';

/**
 * Representa los detalles de un pedido.
 */
export class OrderDriver extends Model<IOrderDriverData> {
  public static type = 'order_drivers';
  currentUserOrder: any;
  public teamId: string | undefined;
  public localVar: Order | null | undefined;
  static apiService: LaravelApiService | undefined;

  public current_usr: IOrderData | undefined;

  constructor(data: Partial<IOrderDriverData> = {}, id: string | null = null, parentPath?: string) {
    const defaultData: IOrderDriverData = {
      createdAt: null,
      id: null,
      orderId: '',
      userId: '',
      driverStatus: '',
      updatedAt: null,
    };

    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);

    const params = {
      order: data.orderId,
    };
  }

  get data() {
    return this.rawData;
  }
}
