import { animate, animateChild, query, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { Product } from '~/database/models/product';
import { IRole } from '~/database/models/role.interface';
import { AuthenticationService } from '~shared/services/authentication.service';
import { RestaurantThemeService } from '~shared/services/restaurant-theme.service';
export interface IPromotionData {
  cat_label: string;
  code: string;
  coupon_is_expired: boolean;
  created_at: string;
  created_by: string | null;
  deleted_at: string | null;
  description: string;
  discount_amount: number | null;
  first_quantity: number | null;
  id: string;
  label: string;
  max_discount: number | null;
  min_order_amount: number | null;
  name: string;
  percentage: string;
  productIds: string;
  promocodeApplicable: boolean;
  second_quantity: number | null;
  team_id: string;
  time: Time[];
  type: string;
  updated_at: string;
  updated_by: string | null;
  user_id: string;
}

export interface Time {
  Day: string;
  Start: TimeDetail;
  End: TimeDetail;
}

export interface TimeDetail {
  hours: number;
  minutes: number;
  seconds: number;
}
export interface AddToCartData {
  quantity: number;
  price: number;
  product: Product;
  notes: string;
  offerApplicable: boolean;
  promocode: string;
  promoquantity: number;
  extras: { key: string; value: string }[] | string;
  paiditems: { key: string; value: string }[] | string;
  productPosition: number;
  teamId: string;
}
type ExtraItem = { key: string; value: string };

export interface Specification {
  id: string;
  name: string;
  required: boolean;
  options: Array<{ id: string; name: string }>;
  myname: string;
  enabled: boolean;
  values: string;
  label: string;
  number: number;
  count: number;
}

@Component({
  selector: 'app-modal [view="add-to-cart"]',
  templateUrl: './add-to-cart-modal.component.html',
  styleUrls: ['./add-to-cart-modal.component.scss'],
  animations: [
    trigger('ngIfAnimation', [transition(':enter, :leave', [query('@*', animateChild())])]),
    trigger('panelInOut', [
      transition('void => *', [
        style({ transform: 'translateY(100%)' }),
        animate(800),
      ]),
      transition('* => void', [
        animate(800, style({ transform: 'translateY(100%)' })),
      ]),
    ]),
    trigger('easeInOut', [
      transition('void => *', [
        style({
          opacity: 0,
        }),
        animate(
          '500ms ease-in',
          style({
            opacity: 1,
          })
        ),
      ]),
      transition('* => void', [
        style({
          opacity: 1,
        }),
        animate(
          '500ms ease-in',
          style({
            opacity: 0,
          })
        ),
      ]),
    ]),
  ],
})
export class AddToCartModalComponent implements OnInit {
  customTheme$: Observable<Record<string, string> | null | undefined> | undefined;
  currentUserRole: IRole | null | undefined;
  protected readonly subscriptions = new Subscription();

  adding = false;
  #quantity = 1;
  form: UntypedFormGroup;
  promotiondata: IPromotionData | undefined;
  promotion: Product | undefined;
  promocodeApplicable = false;
  promo_code = '';
  finalQuantity = 0;
  show: boolean = false;
  showDescription: boolean = false;
  weekday = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  extras: { key: string; value: string }[] = [];
  paidItems: { key: string; value: string }[] = [];
  max_calculation: any = [];
  selectedspec: string = '';
  extra_total: number = 0;
  do_selected: any = [];
  result: any[] = [];
  allselectedSpec: string[] = [];
  allshow = false;
  allSpecification: Specification[] = [];
  public get max() {
    // FIXME: También tomar en cuenta si ya este mismo producto ya está en el carrito para el stock.
    return this.product.availableStock;
  }

  requiredSelected: { specIndex: string; value: string }[] = [];
  requiredCount: number = 0;
  allRequiredSelected: boolean = false;
  selectedRequiredCount: number = 0;

  @Input()
  editing = false;

  @Input()
  isAttendant = false;

  @Input()
  public categories = '';

  @Input()
  public notes = '';

  @Input()
  public product!: Product;

  @Input()
  public extraitems: { key: string; value: string }[] | string = [];

  @Input()
  public allpaiditems: string | { key: string; value: string }[] = [];

  @Input()
  public productPosition = 0;

  @Input()
  set quantity(value) {
    if (value > this.max) {
      this.#quantity = this.max;
    }

    this.#quantity = value;
  }

  get quantity() {
    return this.#quantity;
  }
  get price() {
    return +this.product.data.price;
  }
  get newprice() {
    //  const promo = JSON.parse(JSON.stringify(this.promotiondata));
    this.promotion = this.product;
    const promo = this.promotion.data.promotionId;
    if (promo.type == 'percent') {
      return this.product.data.price - (this.product.data.price * promo.percentage) / 100;
    } else if (promo.type == 'amount') {
      return this.product.data.price - promo.discountAmount;
    } else {
      return +this.product.data.price;
    }
  }

  get total() {
    this.promotion = this.product;
    const pr = this.promotion.data.promotionId;

    const promo = _.mapKeys(pr, (v, k) => _.camelCase(k));

    // const promo = JSON.parse(JSON.stringify(this.promotiondata));
    if (this.promocodeApplicable) {
      if (promo.type == 'percent') {
        return (this.product.data.price - (this.product.data.price * promo.percentage) / 100) * this.quantity;
      }
      if (promo.type == 'amount') {
        return (this.product.data.price - promo.discountAmount) * this.quantity;
      }
      if (promo.type == 'quantity') {
        console.log(promo.type);
        if (this.quantity == promo.firstQuantity) {
          this.finalQuantity = promo.secondQuantity;

          return this.product.data.price * promo.secondQuantity;
        } else if (this.quantity > promo.firstQuantity) {
          const remainder = this.quantity % promo.firstQuantity;
          const quotient = Math.round((this.quantity - remainder) / promo.firstQuantity);
          this.finalQuantity = quotient * promo.secondQuantity + remainder;

          return this.product.data.price * this.finalQuantity;
        } else {
          this.finalQuantity = this.quantity;

          return this.product.data.price * this.finalQuantity;
        }
      }
    } else {
      return this.price * this.quantity;
    }
  }

  constructor(
    public modalController: ModalController,
    private auth: AuthenticationService,
    protected restaurantTheme: RestaurantThemeService
  ) {
    const userRoleSub = this.auth.role$.subscribe((role) => {
      this.currentUserRole = role;
      if (
        this.currentUserRole?.key == 'admin' ||
        this.currentUserRole?.key == 'owner' ||
        this.currentUserRole?.key == 'attendant' ||
        this.currentUserRole?.key == 'cashier'
      ) {
        this.customTheme$ = undefined;
      } else {
        this.customTheme$ = restaurantTheme.themeVariables$;
      }
    });
    this.subscriptions.add(userRoleSub);
    this.form = new UntypedFormGroup({
      notes: new UntypedFormControl(),
    });
  }
  showDescriptionArea() {
    this.showDescription = !this.showDescription;
  }
  add_zero(num: number): string {
    if (num < 9) return '0o' + num;
    else return '' + num;
  }
  compareTime(str2: string, str1: string) {
    if (str1 === str2) {
      return 0;
    }
    var time1 = str1.split(':');
    var time2 = str2.split(':');
    if (eval(time1[0]) > eval(time2[0])) {
      return 1;
    } else if (eval(time1[0]) == eval(time2[0]) && eval(time1[1]) >= eval(time2[1])) {
      return 1;
    } else {
      return -1;
    }
  }

  protected extractNameFromJson(
    required: boolean,
    obj: string,
    obj2?: string, // obj2 is optional because is only used when required is false and free is false
    free?: boolean // free is optional because is only used when required is false
  ) {
    if (required == true) {
      let array = [];
      array = Object.values(obj);
      return array;
    }
    if (required == false && free == true) {
      let array = [];
      array = Object.values(obj);
      return array;
    }
    if (required == false && free == false && obj2) {
      let combineArray: any[] = [];
      let array = [];
      array = Object.values(obj2);
      let array2: any = [];
      array2 = Object.values(obj);
      array.forEach((element, key) => {
        let key2 = array2[key];
        combineArray[key2] = element;
      });

      return combineArray;
    }
  }

  getmax_calcualtion_value(i: number) {
    if (
      this.max_calculation['type_' + i]?.selected != undefined &&
      this.max_calculation['type_' + i]?.max != undefined
    ) {
      if (
        this.max_calculation['type_' + i]?.selected &&
        this.max_calculation['type_' + i]?.selected >= this.max_calculation['type_' + i]?.max
      ) {
        return false;
      }
    }
    return true;
  }
  checkvalue(key: string, value: string) {
    if (this.extraitems) {
      let index = (item: { key: string; value: string }) => item.key === key;
      let indexToUpdate = this.extras.findIndex(index);
      if (indexToUpdate >= 0) {
        return true;
      }
    }
  }

  ngOnInit() {
    if (this.product?.data?.specifications) {
      this.allSpecification = JSON.parse(this.product.data.specifications);
    }

    if (this.extraitems) {
      if (Array.isArray(this.extraitems)) {
        this.extras = this.extraitems;
      } else {
        const extraitems: ExtraItem[] = JSON.parse(this.extraitems);
        this.extras = extraitems;
      }
    }

    if (this.allpaiditems) {
      if (Array.isArray(this.allpaiditems)) {
        this.paidItems = this.allpaiditems;
        let newtotal = 0;
        this.allpaiditems.forEach((element: { key: string; value: string }) => {
          newtotal = newtotal + parseFloat(element.value);
        });

        this.extra_total = newtotal;
      } else {
        let allpaiditems: ExtraItem[] = JSON.parse(this.allpaiditems);
        this.paidItems = allpaiditems;
        let newtotal = 0;
        allpaiditems.forEach((element: { key: string; value: string }) => {
          newtotal = newtotal + parseFloat(element.value);
        });

        this.extra_total = newtotal;
      }
    }

    if (this.extraitems || this.paidItems) {
      let indexToBeChecked = 0; // Initialize index counter
      let selectedItem: any = 0;
      this.allSpecification.forEach((spec: Specification, index: number) => {
        if (spec.required) {
          let getextractedSpec = this.extractNameFromJson(spec.required, spec.values, spec.myname, spec.enabled);
          console.log(getextractedSpec);
          this.extras.forEach((extra: { key: string; value: string }) => {
            if (getextractedSpec) {
              if (Array.isArray(getextractedSpec)) {
                const getindex = getextractedSpec.findIndex((item) => item === extra.value);
                if (getindex != -1) {
                  this.allselectedSpec[index] = extra.value;
                  console.log(this.allselectedSpec);
                }
              } else {
                const getindex = Object.keys(getextractedSpec).findIndex((item) => item === extra.value);
                if (getindex != -1) {
                  this.allselectedSpec[index] = extra.value;
                  console.log(this.allselectedSpec);
                }
              }
            }
          });
        }

        if (!spec.required) {
          let typeKey: any = `type_${indexToBeChecked + 1}`;
          console.log(typeKey);

          if (!this.result[typeKey]) this.result[typeKey] = [];

          if (spec.enabled) {
            let getextractedAray = this.extractNameFromJson(spec.required, spec.values, spec.myname, spec.enabled);
            console.log(getextractedAray);
            this.extras.forEach((extra: { key: string; value: string }) => {
              if (getextractedAray && Array.isArray(getextractedAray)) {
                const getindex = getextractedAray.findIndex((item) => item === extra.value);
                if (getindex != -1) {
                  this.result[typeKey].push(getindex);
                  if (this.max_calculation[typeKey]) {
                    this.max_calculation[typeKey] = {
                      max: spec.number,
                      selected: parseInt(this.max_calculation[typeKey].selected) + 1,
                    };
                  } else {
                    this.max_calculation[typeKey] = { max: spec.number, selected: parseInt(selectedItem) + 1 };
                  }
                }
              }
            });

            if (this.max_calculation[typeKey]?.selected >= Number(this.max_calculation[typeKey]?.max)) {
              for (let i = 0; i < spec.count; i++) {
                if (!this.result[typeKey].includes(i)) {
                  this.do_selected[typeKey + '_' + i] = true;
                }
              }
            } else {
              for (let i = 0; i < spec.count; i++) {
                if (this.result[typeKey] && !this.result[typeKey].includes(i)) {
                  this.do_selected[typeKey + '_' + i] = false;
                }
              }
            }
          }
          if (!spec.enabled) {
            let getextractedAray1 = this.extractNameFromJson(spec.required, spec.values, spec.myname, spec.enabled);
            console.log(getextractedAray1);

            this.paidItems.forEach((element: { key: string; value: string }) => {
              if (getextractedAray1) {
                const keyToFind = element.key; // The key you want to find
                const keys = Object.keys(getextractedAray1);

                const getindex11 = keys.indexOf(keyToFind);

                console.log(getindex11);

                if (getindex11 != -1) {
                  this.result[typeKey].push(getindex11);
                }
              }
            });
          }

          // Increment the index for the next occurrence
          indexToBeChecked++;
        }
      });
      console.log(this.allselectedSpec);
    }
    this.promotiondata = JSON.parse(JSON.stringify(this.product.data.promotionId));
    this.promotion = this.product;
    const promo1 = this.promotion.data.promotionId;

    if (promo1 && promo1.code != '' && promo1.code != undefined) {
      this.promo_code = promo1.label;
      const array = JSON.parse(promo1.time);
      const d = new Date();
      const currentDay = this.weekday[d.getDay()];
      const currentTime = d.getHours() + ':' + d.getMinutes();
      this.promocodeApplicable = false;

      array.forEach((value: Time) => {
        const start_time = value.Start.hours + ':' + value.Start.minutes;
        const end_time = value.End.hours + ':' + value.End.minutes;
        if (
          currentDay == value.Day &&
          this.compareTime(start_time, currentTime) >= 0 &&
          this.compareTime(currentTime, end_time) >= 0
        ) {
          this.promocodeApplicable = true;
        }
      });
    }
    if (promo1 && promo1.code == 'undefined') {
      this.promocodeApplicable = false;
    }
    console.log(this.promocodeApplicable);
    console.log('allSpecification:', this.allSpecification);
    this.requiredCount = this.allSpecification.filter((spec) => spec.required).length;
    console.log('Total required options:', this.requiredCount);
  }

  public dismiss() {
    this.modalController.dismiss({}, 'cancel');
  }

  public confirm() {
    if (!this.isButtonDisabled()) {
      const searchValue = this.form.controls.notes.value;
      this.notes = searchValue;
      if (this.promocodeApplicable) {
        const data: AddToCartData = {
          quantity: this.quantity,
          price: this.newprice,
          product: this.product,
          notes: searchValue,
          offerApplicable: this.promocodeApplicable,
          promocode: this.promo_code,
          promoquantity: this.finalQuantity,
          extras: this.extras,
          paiditems: this.paidItems,
          productPosition: this.productPosition,
          teamId: '',
        };
        this.modalController.dismiss(data, 'submit');
      } else {
        const data: AddToCartData = {
          quantity: this.quantity,
          price: this.price,
          product: this.product,
          notes: searchValue,
          offerApplicable: this.promocodeApplicable,
          promocode: '',
          promoquantity: 0,
          extras: this.extras,
          paiditems: this.paidItems,
          productPosition: this.productPosition,
          teamId: '',
        };
        this.modalController.dismiss(data, 'submit');
      }
    }
  }
  isButtonDisabled(): boolean {
    if (this.adding) {
      return this.requiredCount > 0 && !this.allRequiredSelected;
    } else {
      return false;
    }
    //  return this.requiredCount > 0 && !this.allRequiredSelected;
  }

  toggleAdd() {
    this.adding = !this.adding;
    this.show = !this.show;
  }

  public toggle(event: any) {
    if (event.target.classList.contains('selected')) {
      event.target.classList.remove('selected');
      event.target.parentElement.classList.remove('selected');
    } else {
      event.target.classList.add('selected');
      event.target.parentElement.classList.add('selected');
    }
    // console.log(event.target);
  }
  setRequiredItems(event: any, specId: string) {
    const selectedOption = { specIndex: specId, value: event.target.value };

    const index = this.requiredSelected.findIndex((item) => item.specIndex === specId);

    if (index !== -1) {
      this.requiredSelected[index] = selectedOption;
    } else {
      this.requiredSelected.push(selectedOption);
    }

    // Check if all required options are selected
    this.allRequiredSelected = this.requiredSelected.length === this.requiredCount;

    this.requiredSelected.forEach((item) => {
      const indexToUpdate = this.extras.findIndex(
        (extra: { key: string; value: string }) => extra.key === item.specIndex
      );
      if (indexToUpdate >= 0) {
        this.extras[indexToUpdate] = { key: item.specIndex, value: item.value };
      } else {
        this.extras.push({ key: item.specIndex, value: item.value });
      }
    });
    this.allselectedSpec[+specId] = event.target.value;
  }
  setFreeItems(event: any, maximum_number: number, totalItems: number | undefined) {
    console.log(event);
    let selectedtype = event.target.title;
    var s1 = selectedtype.substr(0, selectedtype.lastIndexOf('_'));
    var index = selectedtype.lastIndexOf('_');

    if (event.target.checked == true) {
      if (!this.result[s1]) this.result[s1] = [];
      if (this.result[s1].indexOf(Number(selectedtype.substr(index + 1))) == -1) {
        this.result[s1].push(Number(selectedtype.substr(index + 1)));
      }
    } else {
      if (
        this.result &&
        Array.isArray(this.result[s1]) &&
        this.result[s1].indexOf(Number(selectedtype.substr(index + 1))) >= 0
      ) {
        this.result[s1].splice(this.result[s1].indexOf(Number(selectedtype.substr(index + 1))), 1);
      }
    }

    if (this.max_calculation[s1]) {
      if (event.target.checked == true) {
        this.max_calculation[s1] = { max: maximum_number, selected: parseInt(this.max_calculation[s1].selected) + 1 };
      } else {
        this.max_calculation[s1] = { max: maximum_number, selected: parseInt(this.max_calculation[s1].selected) - 1 };
      }
    } else {
      this.max_calculation[s1] = { max: maximum_number, selected: 1 };
    }
    if (totalItems) {
      if (this.max_calculation[s1]?.selected >= Number(this.max_calculation[s1]?.max)) {
        //   let maxvalue = this.max_calcualtion[s1]?.max;
        for (let i = 0; i < totalItems; i++) {
          if (!this.result[s1].includes(i)) {
            this.do_selected[s1 + '_' + i] = true;
          }
        }
      } else {
        //  let maxvalue = this.max_calcualtion[s1]?.max;
        for (let i = 0; i < totalItems; i++) {
          if (!this.result[s1].includes(i)) {
            this.do_selected[s1 + '_' + i] = false;
          }
        }
      }
    }
    if (event.target.dataset.free == 'no' || event.target.dataset.free == 'yes') {
      let indexToUpdate = this.extras.findIndex(
        (item: { key: string; value: string }) => item.key === event.target.name
      );

      if (indexToUpdate >= 0) {
        if (!event.target.checked) {
          // Unchecked, remove the item
          this.extras.splice(indexToUpdate, 1);
        } else {
          // Checked, update the item
          this.extras[indexToUpdate] = { key: event.target.name, value: event.target.value };
        }
      } else {
        // Not found, add a new item
        let collectedData = { key: event.target.name, value: event.target.value };
        this.extras.push(collectedData);
      }
    }

    if (event.target.dataset.free == 'no') {
      let indexToUpdate1 = this.paidItems.findIndex(
        (item: { key: string; value: string }) => item.key === event.target.name
      );

      if (indexToUpdate1 >= 0) {
        if (!event.target.checked) {
          // Unchecked, remove the item
          this.extra_total -= parseFloat(event.target.value);
          this.paidItems.splice(indexToUpdate1, 1);
        } else {
          // Checked, update the item
          this.paidItems[indexToUpdate1] = { key: event.target.name, value: event.target.value };
        }
      } else {
        // Not found, add a new item
        let collectedData1 = { key: event.target.name, value: event.target.value };
        this.extra_total += parseFloat(event.target.value);
        this.paidItems.push(collectedData1);
      }
    }
  }

  public increaseQuantity(quantity = 1) {
    if (this.quantity <= this.max - quantity) {
      this.quantity += quantity;
    }
  }

  public decreaseQuantity(quantity = 1) {
    if (this.quantity > 1) {
      this.quantity -= quantity;
    }
  }
  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
