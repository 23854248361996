import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';

import { ICartData } from './cart-data.interface';
import { CartProduct } from './cart-product';
import { ICartProductData } from './cart-product-data.interface';
import { User } from './user';

/**
 * Representa una mesa en la base de datos.
 */
export class Cart extends Model<ICartData> {
  public static type = 'carts';

  constructor(data: Partial<ICartData> = {}, id: string | null = null, parentPath?: string) {
    const defaultData: ICartData = {
      createdAt: null,
      customerId: '',
      deletedAt: null,
      id: null,
      updatedAt: null,
      notes: '',
      offerApplicable: false,
      promocode: '',
      promoquantity: 0,
      totalquantity: 0,
      deliveryFee: 0,
      teamId: '',
      orderType: '',
      image: null,
      extras: null,
      paiditems: null,
      totalAmount: 0,
    };

    // Eliminar los ya predeterminados
    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
    return this.rawData;
  }

  public cartProductsCount: number | undefined;

  private _items$: Observable<CartProduct[]> | undefined;
  get items$(): Observable<CartProduct[]> {
    if (!this._items$) {
      // console.log(this.odm().child<IOrderProductData>('order_products').ref.path);

      this._items$ = this.odm()
        .child<ICartProductData>('cart_products')
        .get()
        .pipe(
          map((products) => {
            this.cartProductsCount = products.size;

            return products.docs.map((p) => {
              return CartProduct.fromPayloadDocument(p);
            });
          })
        );
    }

    return this._items$;
  }

  private _cartProducts$: Observable<CartProduct[]> | undefined;
  /**
   * Obtiene un stream de los cambios que ocurran en los items (a diferencia de items$ que sólo los trae una vez)
   */
  get cartProducts$(): Observable<CartProduct[]> {
    if (!this._cartProducts$) {
      this._cartProducts$ = this.odm()
        .child<ICartProductData>('order_products')
        .snapshotChanges()
        .pipe(
          map((products) => {
            return products.map(({ payload }) => {
              return CartProduct.fromPayloadDocument(payload.doc);
            });
          })
        );
    }

    return this._cartProducts$;
  }

  private _customer$: Observable<User> | undefined;
  get customer$(): Observable<User> {
    if (!this._customer$) {
      this._customer$ = new User({}, this.data.customerId)
        .odm()
        .doc()
        .get()
        .pipe(
          map((snapshot) => {
            return new User(snapshot.data(), this.data.customerId);
          })
        );
    }

    return this._customer$;
  }
}
