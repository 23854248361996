import { CommonModule, CurrencyPipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { IonicRatingComponentModule } from '@nelson6e65/ionic-rating-component';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicCurrencyPipe } from '~/currency.pipe';
import { SharedDirectivesModule } from '~shared/directives/shared-directives.module';
import { AccordionComponent } from './accordion/accordion.component';
import { AddToCartCustomerModalComponent } from './add-to-cart-customer-modal/add-to-cart-customer-modal.component';
import { AddToCartModalComponent } from './add-to-cart-modal/add-to-cart-modal.component';
import { AuthFormInputComponent } from './auth-form-input/auth-form-input.component';
import { BackButtonFallbackComponent } from './back-button-fallback/back-button-fallback.component';
import { BookingCardCustomerComponent } from './booking-card-customer/booking-card-customer.component';
import { CategoriesGridComponent } from './categories-grid/categories-grid.component';
import { CategoryCardComponent } from './category-card/category-card.component';
import { ChattingModalComponent } from './chatting-modal/chatting-modal.component';
import { CommentsModalComponent } from './comments-modal/comments-modal.component';
import { ContextCardComponent } from './context-card/context-card.component';
import { CustomerItemComponent } from './customer-item/customer-item.component';
import { CustomerSectionCardComponent } from './customer-section-card/customer-section-card.component';
import { ElapsedTimeCounterComponent } from './elapsed-time-counter/elapsed-time-counter.component';
import { FormInputComponent } from './form-input/form-input.component';
import { GoogleMapsModalComponent } from './google-maps-modal/google-maps-modal.component';
import { GuestCartConfirmModalComponent } from './guest-cart-confirm-modal/guest-cart-confirm-modal.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { NotificationItemComponent } from './notification-item/notification-item.component';
import { OrderCardCustomerComponent } from './order-card-customer/order-card-customer.component';
import { OrderCardComponent } from './order-card/order-card.component';
import { OrderProductItemComponent } from './order-product-item/order-product-item.component';
import { OrderProductsModalComponent } from './order-products-modal/order-products-modal.component';
import { PaymentModalComponent } from './payment-modal/payment-modal.component';
import { ProductItemComponent } from './product-item/product-item.component';
import { ProductsFilteringModalComponent } from './products-filtering-modal/products-filtering-modal.component';
import { QrCodeModalComponent } from './qr-code-modal/qr-code-modal.component';
import { RatingModalComponent } from './rating-modal/rating-modal.component';
import { RestaurantCustomizationLoadingModalComponent } from './restaurant-customization-loading-modal/restaurant-customization-loading-modal.component';
import { SectionCardComponent } from './section-card/section-card.component';

@NgModule({
  declarations: [
    CategoryCardComponent,
    CategoriesGridComponent,
    MenuItemComponent,
    FormInputComponent,
    AuthFormInputComponent,
    SectionCardComponent,
    CustomerSectionCardComponent,
    OrderProductItemComponent,
    AddToCartModalComponent,
    GuestCartConfirmModalComponent,
    CommentsModalComponent,
    QrCodeModalComponent,
    RatingModalComponent,
    ChattingModalComponent,
    AccordionComponent,
    AddToCartCustomerModalComponent,
    OrderCardComponent,
    OrderCardCustomerComponent,
    BookingCardCustomerComponent,
    OrderProductsModalComponent,
    PaymentModalComponent,
    IconButtonComponent,
    BackButtonFallbackComponent,
    NotificationItemComponent,
    CustomerItemComponent,
    ProductItemComponent,
    ProductsFilteringModalComponent,
    ContextCardComponent,
    ElapsedTimeCounterComponent,
    RestaurantCustomizationLoadingModalComponent,
    OrderProductItemComponent,
    GoogleMapsModalComponent,
    DynamicCurrencyPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    FontAwesomeModule,
    TranslateModule,
    SharedDirectivesModule,
    IonicRatingComponentModule,
    RouterModule,
  ],
  providers: [
    CurrencyPipe,
  ],
  exports: [
    CategoryCardComponent,
    CategoriesGridComponent,
    TranslateModule,
    MenuItemComponent,
    IonicRatingComponentModule,
    FormInputComponent,
    AuthFormInputComponent,
    SectionCardComponent,
    CustomerSectionCardComponent,
    OrderProductItemComponent,
    AddToCartModalComponent,
    GuestCartConfirmModalComponent,
    CommentsModalComponent,
    QrCodeModalComponent,
    RatingModalComponent,
    ChattingModalComponent,
    AccordionComponent,
    AddToCartCustomerModalComponent,
    OrderCardComponent,
    OrderCardCustomerComponent,
    BookingCardCustomerComponent,
    OrderProductsModalComponent,
    PaymentModalComponent,
    IconButtonComponent,
    BackButtonFallbackComponent,
    NotificationItemComponent,
    CustomerItemComponent,
    ProductItemComponent,
    ProductsFilteringModalComponent,
    ContextCardComponent,
    ElapsedTimeCounterComponent,
    FontAwesomeModule,
    SharedDirectivesModule,
    IonicRatingComponentModule,
    RestaurantCustomizationLoadingModalComponent,
    OrderProductItemComponent,
    GoogleMapsModalComponent,
    DynamicCurrencyPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedComponentsModule {}
