import { map, Observable } from 'rxjs';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';
import { Price } from './price';
import { IPriceData } from './price.data';
import { IProductData } from './product-data.interface';
interface IPriceFilters {
  countryId?: string;
  currency?: string;
}
export class Product extends Model<IProductData> {
  public static type = 'products';

  constructor(data: Partial<IProductData>, id: string | null = null, parentPath?: string) {
    const defaultData: IProductData = {
      id,
      categoryId: '',
      code: '',
      name: '',
      description: null,
      image: null,
      originalImage: null,
      price: 0,
      availableStock: 0,
      promotionId: null,
      ingredients: [],
      extraIngredients: [],
      specifications: '',
      promotion: null,
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
    };

    const safeData = defaultedData(data, defaultData);
    super(safeData, id ?? data.id ?? null, parentPath);
  }

  /**
   * Obtiene la cantidad de productos disponibles.
   */
  public get availableStock(): number {
    return this.data.availableStock;
  }

  public get promotionId(): any {
    return this.data.promotionId;
  }

  get data() {
    return this.rawData;
  }

  private _prices$: Observable<Price[]> | undefined;

  get prices$(): Observable<Price[]> {
    if (!this._prices$) {
      this._prices$ = this.odm()
        .child<IPriceData>('prices', (ref) => {
          return ref.where('deletedAt', '==', null);
        })
        .snapshotChanges()
        .pipe(map((pricesSnap) => pricesSnap.map(({ payload }) => Price.fromPayloadDocument(payload.doc))));
    }

    return this._prices$;
  }

  /**
   * Filtra los precios por país y/o moneda.
   * @param countryId - ID del país
   * @param currency - Moneda
   * @returns Observable con los precios filtrados
   */
  /**
   * Filtra los precios utilizando los filtros proporcionados.
   *
   * @param {IPriceFilters} filters - Objeto con los filtros para país y/o moneda.
   * @returns {Observable<Price[]>} Observable que emite una lista de precios filtrados.
   */
  prices(filters: IPriceFilters): Observable<Price[]> {
    return this.odm()
      .child<IPriceData>('prices', (ref) => {
        let query = ref.orderBy('updatedAt', 'desc');
        if (filters.countryId) {
          query = query.where('countryId', '==', filters.countryId);
        }
        if (filters.currency) {
          query = query.where('currency', '==', filters.currency);
        }
        return query;
      })
      .snapshotChanges()
      .pipe(map((pricesSnap) => pricesSnap.map(({ payload }) => Price.fromPayloadDocument(payload.doc))));
  }
}
