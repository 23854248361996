import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import IFirebaseData from '~lib/database/firebase-data.interface';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';
import { User } from './user';

export interface IChatData extends IFirebaseData {
  id: string | null;
  senderId: string;
  recieverId: string;
  orderId: string;
  userType: string;
  chatType: string;
  message: string;
  messageStatus: boolean;
  createdAt: string | null;
  updatedAt: string | null;
  deletedAt: string | null;
}

/**
 * Representa un plan que agrupa módulos.
 */
export class Chat extends Model<IChatData> {
  public static type = 'chats';

  constructor(data: Partial<IChatData> = {}, id: string | null = null, parentPath?: string) {
    const defaultData: IChatData = {
      id: '',
      senderId: '',
      recieverId: '',
      orderId: '',
      userType: '',
      chatType: '',
      message: '',
      messageStatus: false,
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
    };

    // Eliminar los ya predeterminados
    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
    return this.rawData;
  }

  private _sender$: Observable<User> | undefined;
  get sender$(): Observable<User> {
    if (!this._sender$) {
      this._sender$ = new User({}, this.data.senderId)
        .odm()
        .doc()
        .get()
        .pipe(
          map((snapshot) => {
            return new User(snapshot.data(), this.data.senderId);
          })
        );
    }

    return this._sender$;
  }
}
