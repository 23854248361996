import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { Category } from '~/database/models/category';
import { Product } from '~/database/models/product';
import { IProductData } from '~/database/models/product-data.interface';
import { IStockData } from '~/database/models/stock.interface';
import { isCustomEvent } from '~lib/helpers';
import { SearchProductFiltersForm, SearchProductForm } from '~shared/forms/search-product.form';
import { resultIsCollection } from '~shared/services/laravel-api.service';
import { SessionService } from '~shared/services/session.service';

@Component({
  selector: 'app-products-filtering-modal',
  templateUrl: './products-filtering-modal.component.html',
  styleUrls: ['./products-filtering-modal.component.scss'],
})
export class ProductsFilteringModalComponent implements OnInit, OnDestroy {
  @Input()
  categoryId: string | undefined;

  products: Product[] = [];

  showCategoryList = true;

  // TODO: Usar esta información para determinar si hay más
  productsMeta:
    | {
        current_page: number;
        from: number;
        last_page: number;
        links: { url: string | null; label: string; active: boolean }[];
        path: string;
        per_page: number;
        to: number;
        total: number;
      }
    | undefined;

  form: SearchProductForm;

  searchBy = 'name';
  oldSearchBy = 'name';

  searchByOptions = [
    {
      value: 'code',
      label: 'Code',
      placeholder: 'Search by code',
    },

    {
      value: 'name',
      label: 'Name',
      placeholder: 'Search by name',
    },
    {
      value: 'description',
      label: 'Description',
      placeholder: 'Search by description',
    },
    {
      value: 'text',
      label: 'Name and description',
      placeholder: 'Search by name and description',
    },
  ];

  restaurantId: string;
  categories$: Observable<Category[]>;
  categories: Category[] | undefined;

  private categoriesSubscription: Subscription | undefined;

  constructor(
    private modalCtrl: ModalController,
    private sessionService: SessionService
  ) {
    const restaurant = sessionService.restaurant;

    if (!restaurant?.id) {
      throw new Error('No restaurant');
    }

    const restaurantId = restaurant.id;

    this.restaurantId = restaurantId;
    this.form = new SearchProductForm(this.restaurantId);

    // FIXME: Controlar un cambio del restaurante
    this.categories$ = restaurant.categories$;

    this.categoriesSubscription = this.categories$.subscribe((categories) => {
      this.categories = categories;
    });
  }

  // FIXME: ¿Esto es necesario?
  public reloadCategories() {
    const restaurant = this.sessionService.restaurant;
    if (!restaurant?.id) {
      if (this.categoriesSubscription) {
        this.categoriesSubscription.unsubscribe();
      }

      throw new Error('No restaurant');
    }

    const restaurantId = restaurant.id;

    this.restaurantId = restaurantId;
    this.form = new SearchProductForm(this.restaurantId);

    this.categories$ = restaurant.categories$;

    this.categoriesSubscription = this.categories$.subscribe((categories) => {
      this.categories = categories;
    });
  }

  ngOnInit(): void {
    //
  }

  ngOnDestroy(): void {
    this.categoriesSubscription?.unsubscribe();
  }

  async search() {
    if (this.form.submiting) {
      return;
    }

    const result = await this.form.submit<IProductData & { stock: IStockData }>(true);

    if (resultIsCollection(result)) {
      // console.log('🚧', result);
      this.showCategoryList = false;
      this.products = result.data.map((productData) => {
        const prod = new Product(productData, productData.id, `restaurants/${this.restaurantId}`);
        // prod.stock = new Stock(productData.stock, undefined, prod.odm().doc().ref.path);

        return prod;
      });
      this.productsMeta = result.meta;
    }
  }

  public async dismiss(product?: Product) {
    await this.modalCtrl.dismiss({ product }, product ? 'select' : 'cancel');
  }

  public onSearchByChange(ev: Event) {
    if (!isCustomEvent<{ value: string }>(ev)) {
      return;
    }

    const oldValue = this.oldSearchBy;
    const newValue = ev.detail.value;

    if (oldValue === newValue) {
      return;
    }

    this.oldSearchBy = this.searchBy = newValue;

    const filtersForm = this.form.get('filters') as SearchProductFiltersForm;

    const oldControl = filtersForm.get(oldValue) as UntypedFormControl | null;

    let searchText = '';

    if (oldControl) {
      searchText = oldControl.value ?? '';
      oldControl.reset('', { emitEvent: false });
    }

    const newControl = filtersForm.get(newValue) as UntypedFormControl | null;

    if (newControl) {
      newControl.patchValue(searchText);
    }

    if (searchText) {
      this.search();
    }

    // console.log({ oldValue, newValue, searchText });
  }

  public async onSearchTextChange(ev: Event) {
    if (!isCustomEvent<{ value: string }>(ev)) {
      return;
    }

    const val = ev.detail.value;
    const valTrim = val.trim();

    // console.log(valTrim);

    if (val !== valTrim) {
      // Si sólo cambia espacios, no dispara el search
      return;
    }

    let shouldSkip = false;

    const filtersForm = this.form.get('filters') as SearchProductFiltersForm;

    // const searchControl = filtersForm.get(this.searchBy) as FormControl;
    const categoryControl = filtersForm.get('category_id') as UntypedFormControl;

    if (valTrim.length === 0) {
      if (!categoryControl.value) {
        shouldSkip = true;
      }

      if (shouldSkip && this.products.length > 0) {
        this.products = []; // Limpiar los productos manualmente
        this.productsMeta = undefined;
      }
    }

    /*
    else if (valTrim.length < 3) {

      if (!categoryControl.value) {
        shouldSkip = true;
      }
    }
    */
    if (shouldSkip) {
      return;
    }

    await this.search();
  }
  onClick() {
    this.showCategoryList = true;
    this.reloadCategories();
  }
  async onCategoryClick(categoryId: string | null) {
    const filtersForm = this.form.get('filters') as SearchProductFiltersForm;

    const categoryControl = filtersForm.get('category_id') as UntypedFormControl;
    categoryControl.setValue(categoryId);

    await this.search();
  }
  async onCategoryChange(ev: Event) {
    if (!isCustomEvent<{ value: string }>(ev)) {
      return;
    }

    if (!ev.detail.value) {
      const filtersForm = this.form.get('filters') as SearchProductFiltersForm;
      const searchControl = filtersForm.get(this.searchBy) as UntypedFormControl;

      if (!searchControl.value) {
        this.products = [];
        this.productsMeta = undefined;
        return;
      }
    }

    await this.search();
  }
}
