import IFirebaseData from '~lib/database/firebase-data.interface';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';

export interface IRatingData extends IFirebaseData {
  id: string | null;
  befasterComments: string;
  teamId: string;
  userId: string;
  attendantId: string;
  orderId: string;
  productRatings: {};
  productComments: string;
  befasterRatings: string;
  attendantRatings: string;
  attendantComments: string;
  createdAt: string | null;
  updatedAt: string | null;
  deletedAt: string | null;
}

/**
 * Representa un plan que agrupa módulos.
 */
export class Rating extends Model<IRatingData> {
  public static type = 'ratings';

  constructor(data: Partial<IRatingData> = {}, id: string | null = null, parentPath?: string) {
    const defaultData: IRatingData = {
      id: '',
      befasterComments: '',
      teamId: '',
      userId: '',
      orderId: '',
      attendantId: '',
      productRatings: {},
      productComments: '',
      befasterRatings: '',
      attendantRatings: '',
      attendantComments: '',
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
    };

    // Eliminar los ya predeterminados
    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
    return this.rawData;
  }
}
