import firebase from 'firebase/compat/app';
import IFirebaseData from '~lib/database/firebase-data.interface';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';

export interface IGeneralMetadata extends IFirebaseData {
  type: {
    label: string;
  };
}
export interface IAddressData<TMetadata extends IFirebaseData = IGeneralMetadata> extends IFirebaseData {
  city: string | null;
  collection: string | null;
  countryId: string | null;
  createdAt: string | null;
  geoPoint: firebase.firestore.GeoPoint | null;
  id: string | null;
  isDefault: boolean | false;
  ownerId: string | null;
  ownerType: string | null;
  postalCode: string | null;
  updatedAt: string | null;
  metadata: TMetadata;
  extraLines: string | null;
  title: string | null;
  reference: string | null;
}

/**
 * Representa un plan que agrupa módulos.
 */
export class Address extends Model<IAddressData> {
  public static type = 'addresses';

  constructor(data: Partial<IAddressData> = {}, id: string | null = null, parentPath?: string) {
    const defaultData: IAddressData = {
      city: '',
      collection: '',
      countryId: '',
      createdAt: null,
      geoPoint: new firebase.firestore.GeoPoint(0, 0),
      id: '',
      isDefault: false,
      ownerId: '',
      ownerType: '',
      postalCode: '',
      updatedAt: null,
      metadata: { type: { label: '' } },
      extraLines: '',
      title: 'No title',
      reference: 'No reference',
    };

    // Eliminar los ya predeterminados
    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
    return this.rawData;
  }
}
