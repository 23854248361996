import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import IFirebaseData from '../firebase-data.interface';
import Model, { ModelConstructor } from '../model';

/**
 * Contenedor para definir las relaciones de un modelo a modo de acceder directamente.
 */
export class Relationships<TModel extends Model<TData>, TData extends IFirebaseData> {
  public readonly model: TModel;

  constructor(model: TModel) {
    this.model = model;
  }

  public get data() {
    return this.model.data;
  }

  public get id() {
    return this.model.id;
  }

  /**
   * Obtiene la relación usando un campo existente en el modelo.
   *
   * @param id ID del documento foráneo.
   * @param parentPath Ruta base para acceder al documento. Por defecto se usa el mismo que el del modelo, lo cual es
   * útil si los modelos son hermanos. Para usar la raíz base, pasar una cadena vacía ('').
   */
  protected belongsTo<TRelatedData extends IFirebaseData, TRelated extends Model<TRelatedData>>(
    relatedClass: ModelConstructor<Partial<TRelatedData>, TRelated>,
    relatedField: string,
    parentPath?: string
  ): Observable<TRelated | null> {
    const RelatedClass = relatedClass as ModelConstructor<Partial<TRelatedData>, TRelated> & typeof Model;
    const id = this.model.data[relatedField] as string | null;

    //const observable = (new RelatedClass({}, id, parentPath ?? this.model.parentPath) as TRelated)
    const observable = (new (RelatedClass as any)({}, id, parentPath ?? this.model.parentPath) as TRelated)
      .odm()
      .doc()
      .snapshotChanges()
      .pipe(
        map((actionSnapshot) => {
          // console.log('🚧', actionSnapshot.payload.ref.path);
          return RelatedClass.fromDocumentChange(actionSnapshot.payload);
        })
      );

    return observable as Observable<TRelated | null>;
  }
}
