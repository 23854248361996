import IFirebaseData from '~lib/database/firebase-data.interface';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';
import { IImageData } from './image-data.interface';

export interface IRestaurantTypeData extends IFirebaseData {
  id: string | null;
  name: string;
  slug: string;
  image: IImageData | null;
  createdAt: string | null;
  updatedAt: string | null;
  deletedAt: string | null;
}

/**
 * Representa un plan que agrupa módulos.
 */
export class RestaurantType extends Model<IRestaurantTypeData> {
  public static type = 'restaurant_types';

  constructor(data: Partial<IRestaurantTypeData> = {}, id: string | null = null, parentPath?: string) {
    const defaultData: IRestaurantTypeData = {
      id: '',
      name: '',
      slug: '',
      image: null,
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
    };

    // Eliminar los ya predeterminados
    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
    return this.rawData;
  }
}
