import { Injectable, OnDestroy } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { BehaviorSubject, Observable, Subscription, distinctUntilChanged, shareReplay } from 'rxjs';
import { UserSettingValue } from '~/database/models/user';
import { AuthenticationService } from './authentication.service';

export interface IUserSettingColorSchemeMetadata {
  faIcon: IconProp;
}

export const USER_SETTING_COLOR_SCHEME_METADATA: Record<
  NonNullable<UserSettingValue<'app:color_scheme'>>,
  IUserSettingColorSchemeMetadata
> = {
  light: {
    faIcon: [
      'far',
      'sun',
    ],
  },
  dark: {
    faIcon: [
      'fas',
      'cloud-moon',
    ],
  },
  system: {
    faIcon: [
      'fas',
      'laptop',
      // 'mobile-screen' // FA v6,
    ],
  },
} as const;

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService implements OnDestroy {
  private readonly currentUserSubscription: Subscription;
  private settingValuesSubscriptions: Subscription | undefined;

  protected readonly colorSchemeSubject: BehaviorSubject<UserSettingValue<'app:color_scheme'> | undefined>;

  /**
   * Esquema de colores del usuario. Sólo es `undefined` cuando no hay un usuario autenticado.
   */
  public readonly colorScheme$: Observable<UserSettingValue<'app:color_scheme'> | undefined>;

  protected readonly colorSchemeMetadataSubject = new BehaviorSubject<IUserSettingColorSchemeMetadata | undefined>(
    undefined
  );

  /**
   * Metadatos extra del esquema de color actual.
   */
  public readonly colorSchemeMetadata$: Observable<IUserSettingColorSchemeMetadata | undefined>;

  constructor(auth: AuthenticationService) {
    this.colorSchemeSubject = new BehaviorSubject<UserSettingValue<'app:color_scheme'> | undefined>(undefined);
    this.colorScheme$ = this.colorSchemeSubject.asObservable().pipe(shareReplay(1));
    this.colorSchemeMetadata$ = this.colorSchemeMetadataSubject.asObservable().pipe(shareReplay(1));

    this.currentUserSubscription = auth.currentUserInstance$
      .pipe(
        // Sólo disparar cuando cambia el usuario
        distinctUntilChanged((a, b) => {
          return a?.id === b?.id;
        })
      )
      .subscribe((currentUser) => {
        this.settingValuesSubscriptions?.unsubscribe();

        if (!currentUser) {
          this.colorSchemeSubject.next(undefined);
          this.colorSchemeMetadataSubject.next(undefined);

          return;
        }

        this.settingValuesSubscriptions = new Subscription();

        const colorSchemeSubscription = currentUser.getSetting$('app:color_scheme').subscribe((colorScheme) => {
          if (!colorScheme || !colorScheme.value) {
            this.colorSchemeSubject.next(null);
            this.colorSchemeMetadataSubject.next(undefined);

            return;
          }

          this.colorSchemeSubject.next(colorScheme.value);
          this.colorSchemeMetadataSubject.next(USER_SETTING_COLOR_SCHEME_METADATA[colorScheme.value]);
        });

        this.settingValuesSubscriptions.add(colorSchemeSubscription);
      });
  }

  public ngOnDestroy(): void {
    this.currentUserSubscription.unsubscribe();
    this.settingValuesSubscriptions?.unsubscribe();
  }
}
