import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { OrderProduct } from '~/database/models/order-product';
import { IOrderProductData } from '~/database/models/order-product-data.interface';
import { Product } from '~/database/models/product';
import { IRole } from '~/database/models/role.interface';
import { AuthenticationService } from '~shared/services/authentication.service';
import { RestaurantThemeService } from '~shared/services/restaurant-theme.service';
import { ExtraItem } from '../order-card/order-card.component';

export type OrderItem = { product?: Observable<Product | null> } & Pick<
  IOrderProductData,
  'price' | 'quantity' | 'notes' | 'offerApplicable' | 'promocode' | 'promoquantity' | 'extras' | 'paiditems'
>;

@Component({
  selector: 'app-order-products-modal',
  templateUrl: './order-products-modal.component.html',
  styleUrls: ['./order-products-modal.component.scss'],
})
export class OrderProductsModalComponent implements OnInit, OnDestroy {
  customTheme$: Observable<Record<string, string> | null | undefined> | undefined;
  currentUserRole: IRole | null | undefined;
  protected readonly subscriptions = new Subscription();

  #orderProducts: OrderProduct[] = [];
  #orderTeamId: string = '';

  @Input()
  set orderTeamId(orderTeamId) {
    this.#orderTeamId = orderTeamId;
  }

  @Input()
  set orderProducts(products) {
    this.#orderProducts = products;
  }
  get orderTeamId() {
    return this.#orderTeamId;
  }
  get orderProducts() {
    return this.#orderProducts;
  }

  items: OrderItem[] = [];
  total = 0;

  constructor(
    public modalController: ModalController,
    private auth: AuthenticationService,
    protected restaurantTheme: RestaurantThemeService
  ) {
    const userRoleSub = this.auth.role$.subscribe((role) => {
      this.currentUserRole = role;
      if (
        this.currentUserRole?.key == 'admin' ||
        this.currentUserRole?.key == 'owner' ||
        this.currentUserRole?.key == 'attendant' ||
        this.currentUserRole?.key == 'cashier'
      ) {
        this.customTheme$ = undefined;
      } else {
        this.customTheme$ = restaurantTheme.themeVariables$;
      }
    });
    this.subscriptions.add(userRoleSub);
  }

  ngOnInit() {
    this.total = 0;
    this.items = this.#orderProducts.map((orderProduct) => {
      const item: OrderItem = {
        price: +orderProduct.data.price,
        quantity: orderProduct.data.quantity,
        notes: orderProduct.data.notes,
        offerApplicable: orderProduct.data.offerApplicable,
        promocode: orderProduct.data.promocode,
        promoquantity: orderProduct.data.promoquantity,
        extras: orderProduct.data.extras,
        paiditems: orderProduct.data.paiditems,
      };

      const ref = orderProduct.restaurantRef();

      if (ref) {
        const p = new Product({}, orderProduct.data.productId, ref.path);

        const p$ = p
          .odm()
          .doc()
          .get()
          .pipe(
            map((r) => {
              const data = r.data();
              // console.log(r);

              if (!data) {
                return null;
              }

              return new Product(data, r.id, ref.path);
            })
          );

        item.product = p$;
      } else {
        if (this.orderTeamId) {
          const p = new Product({}, orderProduct.data.productId, `restaurants/${this.#orderTeamId}`);
          //restaurants/${restaurant.id}/order/${data.id}
          const p$ = p
            .odm()
            .doc()
            .get()
            .pipe(
              map((r) => {
                const data = r.data();
                // console.log(r);

                if (!data) {
                  return null;
                }

                return new Product(data, r.id, `restaurants/${this.#orderTeamId}`);
              })
            );

          item.product = p$;
        }
      }
      let extra_total = 0;
      if (item.promoquantity) {
        this.total += +item.price * item.promoquantity;
      } else {
        this.total += +item.price * item.quantity;
      }

      if (item.paiditems) {
        let paidItems = item.paiditems;

        // Check if it's a double-escaped JSON string
        if (typeof paidItems === 'string') {
          paidItems = JSON.parse(paidItems);
        }
        if (Array.isArray(paidItems)) {
          paidItems.forEach((element: { key: string; value: string }) => {
            extra_total = extra_total + parseFloat(element.value);
          });
          this.total = this.total + extra_total;
        }
      }

      return item;
    });
  }

  public dismiss() {
    this.modalController.dismiss({}, 'cancel');
  }
  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
