import { Color as IonColor, Mode as IonMode } from '@ionic/core';
import { Setting } from '~/database/models/setting';
import IFirebaseData from '~lib/database/firebase-data.interface';
import { defaultedData } from '~lib/helpers';

// FIXME: Organize elements in different files

// FIXME: It is required to enable `exactOptionalPropertyTypes` compiler option to improve typing

export type ColorScheme = 'dark' | 'light';

export type Color = IonColor | 'deep' | string;
export type Mode = IonMode | string;

export type IColorDefinition = {
  /**
   * Base color (HEX).
   */
  base: string;
  rgb: string;
  contrast: string;
  contrastRgb: string;
  shade: string;
  tint: string;
};

export type ICustomIonicTheme = {
  core: Record<string, string> | null;
  colors: Record<Color, IColorDefinition> | null;
  overrides: Record<Mode, Omit<ICustomIonicTheme, 'overrides'>> | null;
  disabled?: boolean;
};

export interface IIonicThemeSetting extends IFirebaseData {
  disabled: boolean;
  // /**
  //  * TODO: Complete implementation
  //  */
  // config?: Pick<IonicConfig, 'mode' | 'animated'>;

  defaultColorScheme: ColorScheme;

  applicationCss: Record<string, string>;
  applicationCssVariables: Record<string, string>;

  /**
   * Map of CSS variables.
   */
  css: Record<ColorScheme, ICustomIonicTheme>;

  /**
   * `css` ass CSS variables instead of a map.
   */
  cssVariables: Record<ColorScheme, Record<string, string>>;
}

export namespace IIonicThemeSetting {
  export const KEY = 'restaurant:ionic_theme';

  export const valueIs = (value: unknown): value is IIonicThemeSetting => {
    // TODO: Mejorar detección
    return typeof value === 'object';
  };

  export const settingIs = (setting: Setting<any>): setting is Setting<IIonicThemeSetting> => {
    return setting.key === KEY && valueIs(setting.value);
  };

  export const defaultValue = (partialData: Partial<IIonicThemeSetting>): IIonicThemeSetting => {
    const defaultData: IIonicThemeSetting = {
      disabled: false,
      applicationCss: {},
      applicationCssVariables: {},
      css: {
        dark: {
          colors: {},
          core: {},
          overrides: null,
        },
        light: {
          colors: {},
          core: {},
          overrides: null,
        },
      },
      cssVariables: { dark: {}, light: {} },
      defaultColorScheme: 'dark',
    };

    return defaultedData(partialData, defaultData);
  };
}
