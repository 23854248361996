/**
 * Métodos de extensión para cadenas.
 *
 * @packageDocumentation
 *
 *
 */

// tslint:disable interface-name

import * as _ from 'lodash-es';
import * as pluralize from 'pluralize';

declare global {
  interface String {
    /**
     * Dasherizes the string.
     *
     * 'Hola  Mundo' => 'hola-mundo'
     * 'HolaMundo' => 'hola-mundo'
     *
     * @param separatorChar Character to separate the strings. By default: '-'
     *
     *
     * @author Nelson Martell <nelson6e65@gmail.com>
     */
    dasherize(separatorChar?: string): string;

    /**
     * Extracts the words in the string.
     *
     * @author Nelson Martell <nelson6e65@gmail.com>
     */
    toWords(pattern?: string): string[];

    /**
     * Convert the string to its plural form.
     *
     * If there are many words, it will change the last one only.
     *
     * @author Nelson Martell <nelson6e65@gmail.com>
     */
    pluralize(): string;

    /**
     * Convert the string to its singular form.
     *
     * If there are many words, it will change the last one only.
     *
     * @author Nelson Martell <nelson6e65@gmail.com>
     */
    singularize(): string;

    /**
     * Converts string to camel case.
     *
     * Alias for `_.camelCase`.
     */
    toCamelCase(): string;
    toPascalCase(): string;
    toSnakeCase(): string;
  }
}

String.prototype.pluralize = function (): string {
  return pluralize.plural(this as string);
};

String.prototype.singularize = function (): string {
  return pluralize.singular(this as string);
};

String.prototype.dasherize = function (separatorChar: string = '-'): string {
  return (
    (this as string)
      // Quitar espacios extra y caracteres extraños
      .toCamelCase()
      // Prefija las mayúsculas con el dash
      .replace(/[A-Z]/g, (char) => separatorChar + char)
      // Convierte las mayúsculas en minúsculas
      .toLowerCase()
  );
};

String.prototype.toWords = function (pattern?: string): string[] {
  return _.words(this as string, pattern);
};

String.prototype.toCamelCase = function (): string {
  return _.camelCase(this as string);
};

String.prototype.toSnakeCase = function (): string {
  return _.snakeCase(this as string);
};

String.prototype.toPascalCase = function (): string {
  return _.upperFirst(this.toCamelCase());
};
