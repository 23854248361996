import IFirebaseData from '~lib/database/firebase-data.interface';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';
export interface ITableLocationData extends IFirebaseData {
  id: string | null;
  teamId: string;
  name: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  deletedAt: string | null;
}
/**
 * Representa un plan que agrupa módulos.
 */
export class TableLocation extends Model<ITableLocationData> {
  public static type = 'table_locations';

  constructor(data: Partial<ITableLocationData> = {}, id: string | null = null, parentPath?: string) {
    const defaultData: ITableLocationData = {
      id: null,
      teamId: '',
      name: '',
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
    };

    // Eliminar los ya predeterminados
    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
    return this.rawData;
  }
}
