import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Category } from '~/database/models/category';
import {
  IPosition,
  IResponsiveColumns,
  IResponsiveColumnsSizes,
  ItemsLayout,
} from '~shared/services/ionic-layout.setting';
import { RestaurantThemeService } from '~shared/services/restaurant-theme.service';

/**
 * Muestra una lista de categorías en tarjetas, distribuyéndolas según las preferencias del restaurante.
 */
@Component({
  selector: 'app-categories-grid',
  templateUrl: './categories-grid.component.html',
  styleUrls: ['./categories-grid.component.scss'],
})
export class CategoriesGridComponent implements OnInit, OnDestroy {
  public categoriesLayout: ItemsLayout = 'cards';
  private readonly subscriptions: Subscription;

  protected categoryLabelPosition: IPosition = IPosition.defaulted();

  protected categoryGridSizes: IResponsiveColumnsSizes = IResponsiveColumnsSizes.defaulted();

  @Input()
  categories: Category[] = [];

  @Input()
  linkResolver: ((category: Category) => string[] | undefined) | undefined;

  @Input()
  badgeColor: string | undefined = 'primary'; // pasado al category-card

  @Input()
  deliveryFee: number | undefined = 0; // pasado al category-card

  constructor(private restaurantThemeService: RestaurantThemeService) {
    this.subscriptions = new Subscription();
  }

  ngOnInit(): void {
    const layoutSubscription = this.restaurantThemeService.ionicLayout$.subscribe((layout) => {
      this.categoriesLayout = layout?.data.value?.categoriesLayout ?? 'cards';
      this.categoryGridSizes = IResponsiveColumns.toColumnSizes(layout?.data.value?.categoriesColumns ?? {});
      this.categoryLabelPosition = IPosition.defaulted(layout?.data.value?.categoryLabelPosition);
    });

    this.subscriptions.add(layoutSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
