import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '~/database/models/user';
import { AuthenticationService } from '~shared/services/authentication.service';
import { SessionService } from '~shared/services/session.service';

import { Rating } from '~/database/models/rating';
import { RatingsService } from '~shared/services/ratings.service';
/**
 * Servicio para inicializar y mantener un observable de los pedidos del cliente actual.
 */
@Injectable({
  providedIn: 'root',
})
export class AllRatingsService {
  #ratings$: Observable<Rating[]> | undefined;

  public get bookings$(): Observable<Rating[]> | undefined {
    return this.#ratings$;
  }
  public currentUser: User | null | undefined;
  constructor(
    private ratings: RatingsService,
    private auth: AuthenticationService,
    private restaurantService: SessionService
  ) {
    const userSub = this.auth.currentUserInstance$.subscribe((user) => {
      this.currentUser = user;
    });
  }

  initializeRatings(options = { force: false }): Observable<Rating[]> {
    this.#ratings$ = this.ratings.getBookings();
    return this.#ratings$;
  }
}
