import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinct } from 'rxjs/operators';
import { Restaurant } from '~/database/models/restaurant';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  private currentLangSubject: BehaviorSubject<string>;
  private localeSubject: BehaviorSubject<string>;
  public readonly localeSubject$: Observable<string>;

  private subscriptions: Subscription | undefined;
  private restaurantCountry: string | undefined = 'VE';
  constructor(protected session: SessionService) {
    const defaultLang = 'es';
    const defaultLocale = `${defaultLang}-VE`;

    this.currentLangSubject = new BehaviorSubject<string>(defaultLang);
    this.localeSubject = new BehaviorSubject<string>(defaultLocale);
    this.localeSubject$ = this.localeSubject.asObservable();

    this.currentLangSubject.subscribe((lang) => this.updateLocale(lang, this.restaurantCountry));

    session.restaurant$
      .pipe(
        distinct((value) => {
          if (!value) {
            return value;
          }
          return value.id;
        })
      )
      .subscribe((restaurant) => {
        this.init({ restaurant });
      });
  }

  public init({ restaurant }: { restaurant: Restaurant | null | undefined }) {
    if (!restaurant) {
      // handle case when restaurant is null or undefined
      console.log('Restaurant is null or undefined');
      return;
    }

    // Here you can put logic to fetch and handle restaurant-specific data
    // For example:

    restaurant.configuration$.subscribe((configuration) => {
      console.log('RESTAURANT COUNTRY', configuration[0].data.countryId);
      this.restaurantCountry = configuration[0].data.countryId;
      this.currentLangSubject.subscribe((lang) => this.updateLocale(lang, this.restaurantCountry));
    });
  }

  public setCurrentLang(lang: string) {
    this.currentLangSubject.next(lang);
  }

  public updateLocale(lang: string, country: string | undefined) {
    const locale = `${lang}-${country}`;
    this.localeSubject.next(locale);
    // console.log('Locale updated to:', locale);
  }

  public clean(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
      this.subscriptions = undefined;
    }
  }
}
