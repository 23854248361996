import { Component, Input, OnInit } from '@angular/core';
import { Category } from '~/database/models/category';
import { IPosition, ItemsLayout } from '~shared/services/ionic-layout.setting';

@Component({
  selector: 'app-category-card',
  templateUrl: './category-card.component.html',
  styleUrls: ['./category-card.component.scss'],
})
export class CategoryCardComponent implements OnInit {
  promotion: any;
  weekday = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  //offercodeApplicable = false;

  @Input()
  item: Category | undefined;

  @Input()
  link: string | Array<string | null> | undefined;

  @Input()
  badgeColor: string | undefined = 'primary';

  @Input()
  deliveryFee: number | undefined = 0;

  @Input()
  public layout: ItemsLayout = 'cards';

  @Input()
  public labelPosition: IPosition = IPosition.defaulted();

  protected get background(): string {
    return `url('${this.item?.data.image?.src}')`;
  }

  protected get minHeight(): string {
    // Nota: El ancho lo va a determinar el contenedor
    let height = '250px';

    switch (this.layout) {
      case 'list':
        height = '0';
        break;

      case 'small-grid':
        height = '96px';
        break;
    }

    return height;
  }
  constructor() {}
  add_zero(num: number): string {
    if (num < 9) return '0o' + num;
    else return '' + num;
  }
  compareTime(str2: string, str1: string) {
    if (str1 === str2) {
      return 0;
    }
    var time1 = str1.split(':');
    var time2 = str2.split(':');
    if (eval(time1[0]) > eval(time2[0])) {
      return 1;
    } else if (eval(time1[0]) == eval(time2[0]) && eval(time1[1]) >= eval(time2[1])) {
      return 1;
    } else {
      return -1;
    }
  }
  checkforlabel() {
    this.promotion = this.item;
    const offer = this.promotion.rawData.offer;
    this.promotion.rawData.offercodeApplicable = false;
    if (offer) {
      const array = JSON.parse(offer);
      const d = new Date();
      const currentDay = this.weekday[d.getDay()];
      const currentTime = d.getHours() + ':' + d.getMinutes();

      array.forEach((value: any) => {
        const start_time = value.Start.hours + ':' + value.Start.minutes;
        const end_time = value.End.hours + ':' + value.End.minutes;

        if (
          currentDay == value.Day &&
          this.compareTime(start_time, currentTime) >= 0 &&
          this.compareTime(currentTime, end_time) >= 0
        ) {
          this.promotion.rawData.offercodeApplicable = true;
        }
      });
      this.item = this.promotion;
    }
  }
  ngOnInit() {
    setInterval(() => {
      this.checkforlabel();
    }, 10000);
  }
}
