// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: contents;
  --icon-size: 4em;
}
:host ion-card {
  margin: 0;
  margin-inline: 0;
  --ion-grid-padding: 0;
}
:host ion-card ion-grid ion-row ion-col {
  color: var(--ion-text-color);
}
:host ion-card ion-grid ion-row ion-col.icon-column {
  font-size: var(--icon-size);
}
:host ion-card ion-grid ion-row ion-col.content-column ion-card {
  margin: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 5px;
}

.card-background {
  height: 100px;
  width: 100px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  border-radius: 50%;
  background-position: center !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/section-card/section-card.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EAGA,gBAAA;AADF;AAGE;EACE,SAAA;EACA,gBAAA;EAEA,qBAAA;AAFJ;AAMQ;EACE,4BAAA;AAJV;AAKU;EACE,2BAAA;AAHZ;AAOY;EACE,SAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;AALd;;AAeA;EACE,aAAA;EACA,YAAA;EACA,mCAAA;EACA,uCAAA;EACA,kBAAA;EACA,sCAAA;AAZF","sourcesContent":[":host {\n  display: contents;\n\n  // --content-background: none;\n  --icon-size: 4em;\n\n  ion-card {\n    margin: 0;\n    margin-inline: 0;\n\n    --ion-grid-padding: 0;\n\n    ion-grid {\n      ion-row {\n        ion-col {\n          color: var(--ion-text-color);\n          &.icon-column {\n            font-size: var(--icon-size);\n          }\n\n          &.content-column {\n            ion-card {\n              margin: 0;\n              border-radius: 0;\n              box-shadow: none;\n              padding: 5px;\n            }\n            // background: var(--content-background);\n          }\n        }\n      }\n    }\n  }\n}\n\n.card-background {\n  height: 100px;\n  width: 100px;\n  background-size: contain !important;\n  background-repeat: no-repeat !important;\n  border-radius: 50%;\n  background-position: center !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
