// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ion-label {
  font-size: 12px;
  display: inline-block;
  margin: 5px 0;
  width: 100%;
  padding: 0 5px;
}
:host .product_bg {
  display: block;
  border: 2px solid #121212;
  opacity: 1;
}
:host .comment {
  float: left;
}
:host .time {
  float: right;
}
:host .attendant_name {
  float: right;
  background: purple;
  padding: 5px 10px;
  border-radius: 11px;
}
:host .product_name {
  float: right;
  background: green;
  padding: 5px 10px;
  border-radius: 11px;
}
:host .username {
  float: left;
}

::ng-deep .comments .modal-wrapper.sc-ion-modal-md {
  height: 75%;
  width: 95%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/comments-modal/comments-modal.component.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,qBAAA;EACA,aAAA;EACA,WAAA;EACA,cAAA;AAAJ;AAEE;EACE,cAAA;EACA,yBAAA;EACA,UAAA;AAAJ;AAGE;EACE,WAAA;AADJ;AAGE;EACE,YAAA;AADJ;AAGE;EACE,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,mBAAA;AADJ;AAGE;EACE,YAAA;EACA,iBAAA;EACA,iBAAA;EACA,mBAAA;AADJ;AAGE;EACE,WAAA;AADJ;;AAIA;EACE,WAAA;EACA,UAAA;AADF","sourcesContent":[":host {\n  ion-label {\n    font-size: 12px;\n    display: inline-block;\n    margin: 5px 0;\n    width: 100%;\n    padding: 0 5px;\n  }\n  .product_bg {\n    display: block;\n    border: 2px solid #121212;\n    opacity: 1;\n  }\n\n  .comment {\n    float: left;\n  }\n  .time {\n    float: right;\n  }\n  .attendant_name {\n    float: right;\n    background: purple;\n    padding: 5px 10px;\n    border-radius: 11px;\n  }\n  .product_name {\n    float: right;\n    background: green;\n    padding: 5px 10px;\n    border-radius: 11px;\n  }\n  .username {\n    float: left;\n  }\n}\n::ng-deep .comments .modal-wrapper.sc-ion-modal-md {\n  height: 75%;\n  width: 95%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
