import { env } from '~lib/helpers/env';

/**
 * Configuración del backend de Laravel
 */
export const laravel = {
  api: {
    https: env('LARAVEL_API_HTTPS', true),
    domain: env('LARAVEL_API_DOMAIN', 'befaster.app'),
    baseUrl: env('LARAVEL_API_BASE_URL', 'api'),
    port: env('LARAVEL_API_PORT') as number | undefined, // default
  },
};

/**
 * Configuración general de la aplicación.
 */
export const app = {
  production: env('APP_PRODUCTION') === true,
};
