import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subscription, interval } from 'rxjs';
import { Product } from '~/database/models/product';
import { normalizeBooleanAttribute } from '~lib/helpers';

@Component({
  selector: 'app-order-product-item',
  templateUrl: './order-product-item.component.html',
  styleUrls: ['./order-product-item.component.scss'],
})
export class OrderProductItemComponent implements OnInit, AfterViewInit, OnDestroy {
  #price = 0;
  #hideTotal = false;
  #button: string | boolean | undefined;
  #disabled: string | boolean | undefined;
  extra_total: number = 0;
  paidItems: { key: string; value: string }[] = [];
  productitem: any;
  @Input()
  product: Product | undefined;

  @Input()
  quantity = 1;

  @Input()
  promoquantity = 1;

  @Input()
  extras: string | { key: string; value: string }[] | null | undefined = '';
  parsedExtras: { key: string; value: string }[] | undefined;
  @Input()
  paiditems: string | { key: string; value: string }[] | null | undefined = '';

  @Input()
  lines: string | undefined;

  @Input()
  notes: string | undefined;

  @Input()
  set hideTotal(value: boolean | string | undefined) {
    this.#hideTotal = normalizeBooleanAttribute(value);
  }

  containsNumber(value: string): boolean {
    return /\d/.test(value);
  }

  get hideTotal() {
    return this.#hideTotal;
  }
  @Input()
  set button(value: boolean | string | undefined) {
    this.#button = normalizeBooleanAttribute(value);
  }
  get button() {
    return this.#button;
  }

  @Input()
  set disabled(value: boolean | string | undefined) {
    this.#disabled = normalizeBooleanAttribute(value);
  }
  get disabled() {
    return this.#disabled;
  }

  @ViewChild('img') imgEl: ElementRef<HTMLImageElement> | undefined;
  protected updatingImageSizes = false;
  protected updateImageSizesSubscription: Subscription | undefined;
  sizes = '1px';

  @Input()
  set price(value: string | number) {
    this.#price = +value;
  }
  get price() {
    return this.#price;
  }

  get total() {
    if (this.promoquantity && this.promoquantity > 0) {
      return this.#price * this.promoquantity;
    } else {
      return this.#price * this.quantity;
    }
  }

  constructor(public platform: Platform) {}

  ngOnInit() {
    if (this.extras) {
      this.getdata(this.extras);
    }
    this.productitem = this.product;
    this.product = this.productitem;
    if (this.paiditems) {
      let extra_total = 0;
      // Check if it's a double-escaped JSON string
      if (typeof this.paiditems === 'string') {
        this.paidItems = JSON.parse(this.paiditems);
        this.paidItems.forEach((element: { key: string; value: string }) => {
          extra_total = extra_total + parseFloat(element.value);
        });
        this.extra_total = extra_total;
      }
      if (Array.isArray(this.paiditems)) {
        this.paiditems.forEach((element: { key: string; value: string }) => {
          extra_total = extra_total + parseFloat(element.value);
        });
        this.extra_total = extra_total;
      }
    }
  }

  async ngAfterViewInit() {
    const source = interval(2000);
    this.updateImageSizes();
    this.updateImageSizesSubscription = source.subscribe(() => {
      this.updateImageSizes();
    });
  }

  getdata(extras: any) {
    try {
      let parsed = extras;
      // Check if it's a double-escaped JSON string
      if (typeof parsed === 'string') {
        parsed = JSON.parse(parsed);
      }
      this.parsedExtras = parsed;
    } catch {
      return null;
    }
  }
  checkvalue(value: any) {
    if (value == 'Infinity') {
      return 0;
    } else {
      if (Number(value)) {
        return value;
      } else {
        return 0;
      }
    }
  }
  updateImageSizes() {
    if (this.updatingImageSizes) {
      return;
    }

    this.updatingImageSizes = true;
    const innerWidth: number = this.platform.width();

    const nativeEl = this.imgEl?.nativeElement;

    if (!nativeEl) {
      this.updatingImageSizes = false;
      return;
    }

    window.requestAnimationFrame(() => {
      const currentWidth = nativeEl.getBoundingClientRect().width;

      // console.log(innerWidth, currentWidth);
      if (currentWidth > 0) {
        this.sizes = Math.ceil((currentWidth / innerWidth) * 100) + 'vw';
        this.updateImageSizesSubscription?.unsubscribe();
      }

      this.updatingImageSizes = false;
    });
  }

  ngOnDestroy() {
    if (this.updateImageSizesSubscription && !this.updateImageSizesSubscription.closed) {
      this.updateImageSizesSubscription.unsubscribe();
    }
  }
}
