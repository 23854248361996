import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';
import { IPriceData } from './price.data';

/**
 * Represents a price entry.
 */
export class Price extends Model<IPriceData> {
  public static type = 'prices';

  constructor(data: Partial<IPriceData> = {}, id: string | null = null, parentPath?: string) {
    const defaultData: IPriceData = {
      id: '',
      ownerType: '',
      ownerId: '',
      countryId: '',
      currency: '',
      baseValue: 0,
      baseScale: 0,
      value: 0,
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
    };

    // Eliminate already predefined data
    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
    return this.rawData;
  }
}
