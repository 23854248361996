// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-background {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

fa-icon[slot=icon-only] {
  font-size: 1.8em;
  color: #fff;
}

::ng-deep .modal-wrapper.sc-ion-modal-md {
  height: 75%;
  width: 95%;
}

.payment_image img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/payment-modal/payment-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,sBAAA;EACA,4BAAA;EACA,2BAAA;AACF;;AACA;EACE,gBAAA;EACA,WAAA;AAEF;;AACA;EACE,WAAA;EACA,UAAA;AAEF;;AACA;EACE,iBAAA;EACA,kBAAA;EACA,cAAA;AAEF","sourcesContent":[".card-background {\n  height: 100%;\n  width: 100%;\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n}\nfa-icon[slot='icon-only'] {\n  font-size: 1.8em;\n  color: #fff;\n}\n\n::ng-deep .modal-wrapper.sc-ion-modal-md {\n  height: 75%;\n  width: 95%;\n}\n\n.payment_image img {\n  margin-left: auto;\n  margin-right: auto;\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
