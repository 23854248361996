import { Component, Input, NgZone, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-back-button-fallback',
  templateUrl: './back-button-fallback.component.html',
  styleUrls: ['./back-button-fallback.component.scss'],
})
export class BackButtonFallbackComponent implements OnInit {
  @Input()
  url: string | string[] = '/';

  constructor(
    private navCtrl: NavController,
    private zone: NgZone
  ) {}

  ngOnInit() {}

  onClick() {
    this.zone.run(() => {
      this.navCtrl.navigateBack(this.url);
    });
  }
}
