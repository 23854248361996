import { FirebaseDataType } from '~lib/database/firebase-data.interface';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';
import { IIonicLayoutSetting } from '~shared/services/ionic-layout.setting';
import { IIonicThemeSetting } from '~shared/services/ionic-theme-setting';
import { ISettingData } from './setting.data';

/**
 * Morphed settings for any other model.
 */
export class Setting<TValue extends FirebaseDataType = FirebaseDataType> extends Model<ISettingData<TValue>> {
  public static type = 'settings';

  constructor(data: Partial<ISettingData<TValue>> = {}, id: string | null = null, parentPath?: string) {
    const defaultData: ISettingData<TValue> = {
      id: '',
      settingKeyId: '',
      settingableId: '',
      key: '',
      keyCollection: '',
      keySlug: '',
      settingableType: '',
      createdAt: null,
      updatedAt: null,
      value: null,
    };

    if (!parentPath) {
      parentPath = `${defaultData.settingableType}/${defaultData.settingableId}`;

      if (defaultData.settingableType === 'teams') {
        // For the moment, 'teams' type are only used for restaurants
        parentPath = `restaurants/${defaultData.settingableId}`;
      }
    }

    const safeData = defaultedData(data, defaultData);

    if (safeData.value !== null) {
      switch (safeData.key) {
        case IIonicLayoutSetting.KEY:
          safeData.value = IIonicLayoutSetting.defaultValue(safeData.value as IIonicLayoutSetting) as unknown as TValue;
          break;

        case IIonicThemeSetting.KEY:
          safeData.value = IIonicThemeSetting.defaultValue(safeData.value as IIonicThemeSetting) as unknown as TValue;
          break;
        // TODO: Agregar otras conversiones de valores
      }
    }

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  public get key(): string {
    return this.data.key;
  }

  public get value(): TValue | null {
    return this.data.value;
  }
}
