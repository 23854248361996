import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IfHasAnyFeaturesDirective } from './if-has-any-features.directive';
import { IfHasFeaturesDirective } from './if-has-features.directive';
// import { ActivePlanService } from '~shared/services/active-plan.service';

@NgModule({
  imports: [CommonModule],
  declarations: [
    IfHasFeaturesDirective,
    IfHasAnyFeaturesDirective,
  ],
  exports: [
    IfHasFeaturesDirective,
    IfHasAnyFeaturesDirective,
  ],
  // providers: [ActivePlanService],
})
export class SharedDirectivesModule {}
