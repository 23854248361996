import { HttpParams } from '@angular/common/http';
import { UserSettingKey, UserSettingValue } from '~/database/models/user';
import { ApiForm } from '~lib/helpers/api-form';
import { IJsonMap } from '~lib/types';
import { UriRoute } from './laravel-api.service';

export interface IUserSettingFormData<
  TKey extends UserSettingKey = UserSettingKey,
  TValue extends UserSettingValue<TKey> = UserSettingValue<TKey>,
> extends IJsonMap {
  value: TValue | null;
}

/**
 * Agrega o actualiza un setting de usuario.
 */
export class UserSettingForm<TKey extends UserSettingKey = UserSettingKey> extends ApiForm<IUserSettingFormData<TKey>> {
  constructor(
    userId: string,
    settingKey: TKey,
    initialData: Partial<IUserSettingFormData<TKey>> = {},
    queryParams?: HttpParams
  ) {
    super(
      {
        method: 'PUT',
        uri: new UriRoute(`users/${userId}/settings/${settingKey}`),
        queryParams,
      },
      initialData,
      [
        'value',
      ]
    );
  }
}
