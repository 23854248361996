// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: contents;
  --icon-size: 4em;
}
:host ion-card-title {
  font-size: 12px;
  white-space: pre-line;
  height: 100%;
  text-transform: capitalize;
  margin-top: 15px;
}
:host ion-card {
  background: none;
  box-shadow: none;
  display: inline-flex;
  margin: 0;
  flex-direction: column;
  margin-inline: 0;
  --ion-grid-padding: 0;
}
:host ion-card ion-grid ion-row ion-col {
  min-height: 120px;
}
:host ion-card ion-grid ion-row ion-col.icon-column {
  font-size: var(--icon-size);
}
:host ion-card ion-grid ion-row ion-col.content-column ion-card {
  margin: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 5px;
}

.small {
  font-size: 11px !important;
  line-height: 13px;
}

.card-background {
  height: 64px;
  width: 64px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  border-radius: 50%;
  background-position: center !important;
}

@media screen and (max-width: 360px) {
  .card-background {
    height: 70px;
    width: 70px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/customer-section-card/customer-section-card.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EAGA,gBAAA;AADF;AAEE;EACE,eAAA;EACA,qBAAA;EACA,YAAA;EACA,0BAAA;EACA,gBAAA;AAAJ;AAGE;EACE,gBAAA;EACA,gBAAA;EACA,oBAAA;EACA,SAAA;EACA,sBAAA;EACA,gBAAA;EAEA,qBAAA;AAFJ;AAMQ;EACE,iBAAA;AAJV;AAKU;EACE,2BAAA;AAHZ;AAOY;EACE,SAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;AALd;;AAcA;EACE,0BAAA;EACA,iBAAA;AAXF;;AAaA;EACE,YAAA;EACA,WAAA;EACA,mCAAA;EACA,uCAAA;EACA,kBAAA;EACA,sCAAA;AAVF;;AAaA;EACE;IACE,YAAA;IACA,WAAA;EAVF;AACF","sourcesContent":[":host {\n  display: contents;\n\n  // --content-background: none;\n  --icon-size: 4em;\n  ion-card-title {\n    font-size: 12px;\n    white-space: pre-line;\n    height: 100%;\n    text-transform: capitalize;\n    margin-top: 15px;\n  }\n\n  ion-card {\n    background: none;\n    box-shadow: none;\n    display: inline-flex;\n    margin: 0;\n    flex-direction: column;\n    margin-inline: 0;\n\n    --ion-grid-padding: 0;\n\n    ion-grid {\n      ion-row {\n        ion-col {\n          min-height: 120px;\n          &.icon-column {\n            font-size: var(--icon-size);\n          }\n\n          &.content-column {\n            ion-card {\n              margin: 0;\n              border-radius: 0;\n              box-shadow: none;\n              padding: 5px;\n            }\n            // background: var(--content-background);\n          }\n        }\n      }\n    }\n  }\n}\n.small {\n  font-size: 11px !important;\n  line-height: 13px;\n}\n.card-background {\n  height: 64px;\n  width: 64px;\n  background-size: contain !important;\n  background-repeat: no-repeat !important;\n  border-radius: 50%;\n  background-position: center !important;\n}\n\n@media screen and (max-width: 360px) {\n  .card-background {\n    height: 70px;\n    width: 70px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
