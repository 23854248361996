// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: contents;
}
:host .description {
  max-height: calc(4.4em + 10px);
  overflow: hidden;
}
:host .stock-description {
  font-style: italic;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/product-item/product-item.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AACE;EACE,8BAAA;EACA,gBAAA;AACJ;AAEE;EACE,kBAAA;EACA,iBAAA;AAAJ","sourcesContent":[":host {\n  display: contents;\n\n  .description {\n    max-height: calc(4.4em + 10px); // 3 líneas + el padding\n    overflow: hidden;\n  }\n\n  .stock-description {\n    font-style: italic;\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
