// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ion-item {
  --border-style: none;
}
:host ion-item fa-icon {
  font-size: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/menu-item/menu-item.component.scss"],"names":[],"mappings":"AACE;EACE,oBAAA;AAAJ;AACI;EACE,eAAA;AACN","sourcesContent":[":host {\n  ion-item {\n    --border-style: none;\n    fa-icon {\n      font-size: 24px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
