import { Injectable } from '@angular/core';
import { omitBy } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { distinct, map, shareReplay } from 'rxjs/operators';
import { Restaurant } from '~/database/models/restaurant';
import { Setting } from '~/database/models/setting';
import { ISettingData } from '~/database/models/setting.data';
import { IIonicLayoutSetting } from './ionic-layout.setting';
import { IIonicThemeSetting } from './ionic-theme-setting';
import { SessionService } from './session.service';
import { ThemeService } from './theme.service';

/**
 * Controla la session del restaurant activo.
 */
@Injectable({
  providedIn: 'root',
})
export class RestaurantThemeService {
  private _initializing: boolean | undefined = undefined;

  public get initializing(): boolean | undefined {
    return this._initializing;
  }

  protected readonly ionicThemeSubject: BehaviorSubject<Setting<IIonicThemeSetting> | null | undefined>;

  protected readonly ionicLayoutSubject = new BehaviorSubject<Setting<IIonicLayoutSetting> | null | undefined>(
    undefined
  );

  /**
   * Stream de la configuración del Theme de Ionic.
   *
   * Nota: Si el valor es `undefined`, significa que aún no se ha inicializado.
   */
  public readonly ionicTheme$: Observable<Setting<IIonicThemeSetting> | null | undefined>;

  /**
   * Stream de configuración de layouts del restaurante.
   *
   * Nota: Si el valor es `undefined`, significa que aún no se ha inicializado.
   */
  public readonly ionicLayout$: Observable<Setting<IIonicLayoutSetting> | null | undefined>;

  /**
   * Si el valor es undefined, significa que aún no se ha inicializado.
   */
  public get ionicTheme(): Setting<IIonicThemeSetting> | null | undefined {
    return this.ionicThemeSubject.value;
  }

  protected readonly themeVariablesSubject = new BehaviorSubject<Record<string, string> | null | undefined>(undefined);

  public readonly themeVariables$: Observable<Record<string, string> | null | undefined>;

  protected subscriptions: Subscription | undefined;

  constructor(
    protected session: SessionService,
    protected userTheme: ThemeService
  ) {
    this.ionicThemeSubject = new BehaviorSubject<Setting<IIonicThemeSetting> | null | undefined>(undefined);

    this.ionicTheme$ = this.ionicThemeSubject.asObservable().pipe(shareReplay(1));

    this.ionicLayout$ = this.ionicLayoutSubject.asObservable().pipe(shareReplay(1));

    this.themeVariables$ = this.themeVariablesSubject.asObservable().pipe(shareReplay(1));

    session.restaurant$
      .pipe(
        distinct((value) => {
          // TODO: Check if this distinct detection is enough
          if (!value) {
            // For changes between null and undefined
            return value;
          }
          return value.id;
        })
      )
      .subscribe((restaurant) => {
        this.init({ restaurant });
      });
  }

  public init({ restaurant, force = false }: { restaurant: Restaurant | null | undefined; force?: boolean }) {
    // if (!force) {
    //   if (this.resta)
    // }

    // console.log('🌹 ', restaurant);

    if (!restaurant) {
      // TODO: `strict` ts's compilerOption should be enabled to avoid unnecessary conditionals
      this.ionicThemeSubject.next(restaurant === null ? null : undefined);

      return;
    }

    if (this.initializing) {
      return;
    }

    this.clean();

    this.subscriptions = new Subscription();

    const settingSubscription = restaurant
      .odm()
      .child<ISettingData<IIonicThemeSetting | IIonicLayoutSetting>>('settings', (query) => {
        return query
          .where('key', 'in', [
            IIonicThemeSetting.KEY,
            IIonicLayoutSetting.KEY,
          ])
          .where('value.disabled', '==', false);
      })
      .snapshotChanges()
      .pipe(
        map((settingSnapshot) => {
          return settingSnapshot.map(({ payload }) => {
            return Setting.fromPayloadDocument(payload.doc) as Setting<IIonicThemeSetting | IIonicLayoutSetting>;
          });
        })
      )
      .subscribe((settings) => {
        if (settings.length === 0) {
          this.ionicThemeSubject.next(null);
          this.themeVariablesSubject.next(null);
          this.ionicLayoutSubject.next(null);

          return;
        }

        settings.forEach((setting) => {
          if (IIonicThemeSetting.settingIs(setting)) {
            this.ionicThemeSubject.next(setting);
          } else if (IIonicLayoutSetting.settingIs(setting)) {
            this.ionicLayoutSubject.next(setting);
          }
        });
      });

    this.subscriptions.add(settingSubscription);

    const themeSubscription = combineLatest([
      this.userTheme.colorScheme$,
      this.ionicTheme$,
    ]).subscribe(
      ([
        userColorScheme,
        ionicThemeSetting,
      ]) => {
        if (!ionicThemeSetting || !ionicThemeSetting.value) {
          this.themeVariablesSubject.next(null);

          return;
        }

        const theme = ionicThemeSetting.value;

        let colorScheme = userColorScheme ?? theme.defaultColorScheme;

        let cssVariablesForColorScheme = {} as Record<string, string>;

        // Detecta si ese esquema está habilitado. Si no es así, no se cargan sus variables CSS
        if (!theme.css[colorScheme].disabled) {
          cssVariablesForColorScheme = theme.cssVariables[colorScheme];
        }

        console.log('colorScheme', colorScheme, cssVariablesForColorScheme);

        // Omit variables without a value
        const themeVariables = omitBy(
          {
            ...theme.applicationCssVariables,
            ...cssVariablesForColorScheme,
          },
          (val) => !val
        );

        this.themeVariablesSubject.next(themeVariables);
      }
    );

    this.subscriptions.add(themeSubscription);
  }

  public clean(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
      this.subscriptions = undefined;
    }

    this.ionicThemeSubject.next(undefined);
    this.ionicLayoutSubject.next(undefined);
    this._initializing = undefined;
  }
}
