import { animate, animateChild, query, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, HostListener, Input, NgZone, OnInit } from '@angular/core';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import _ from 'lodash-es';
import { ItemService } from '~shared/services/item.service';

@Component({
  selector: 'app-modal [view="add-to-cart-customer"]',
  templateUrl: './guest-cart-confirm-modal.component.html',
  styleUrls: ['./guest-cart-confirm-modal.component.scss'],
  animations: [
    trigger('ngIfAnimation', [transition(':enter, :leave', [query('@*', animateChild())])]),
    trigger('panelInOut', [
      transition('void => *', [
        style({ transform: 'translateY(100%)' }),
        animate(800),
      ]),
      transition('* => void', [
        animate(800, style({ transform: 'translateY(100%)' })),
      ]),
    ]),
    trigger('easeInOut', [
      transition('void => *', [
        style({
          opacity: 0,
        }),
        animate(
          '500ms ease-in',
          style({
            opacity: 1,
          })
        ),
      ]),
      transition('* => void', [
        style({
          opacity: 1,
        }),
        animate(
          '500ms ease-in',
          style({
            opacity: 0,
          })
        ),
      ]),
    ]),
  ],
})
export class GuestCartConfirmModalComponent implements OnInit {
  @Input()
  public selectedTeamid = '';

  constructor(
    private zone: NgZone,
    public modalController: ModalController,
    public popoverController: PopoverController,
    public navCtrl: NavController,
    private cdr: ChangeDetectorRef,
    private itemService: ItemService
  ) {}

  async ionViewDidEnter() {
    this.itemService.saveSelectedCartId(this.selectedTeamid);
  }
  ngOnInit() {}
  async buyasaguest() {
    await this.modalController.dismiss(null, 'cancel');
    await this.zone.run(async () => {
      await this.navCtrl.navigateForward(['/guest/checkout']);
    });
  }
  async goTo(path: string) {
    await this.modalController.dismiss(null, 'cancel');
    await this.zone.run(async () => {
      await this.navCtrl.navigateForward([path]);
    });
  }

  public dismiss() {
    this.modalController.dismiss(null, 'cancel');
  }
}
