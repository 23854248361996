// src/app/services/google-maps-loader.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { firebaseConfig } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class GoogleMapLoaderService {
  public scriptLoadedSubject = new BehaviorSubject<boolean>(false);
  scriptLoaded$ = this.scriptLoadedSubject.asObservable();
  private scriptLoaded: boolean = false;

  apiKey: string | undefined;
  constructor() {}
  load(): Promise<void> {
    if (this.scriptLoaded) {
      this.scriptLoadedSubject.next(true);

      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      this.apiKey = firebaseConfig.apiKey;
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&v=beta&callback=`;
      script.async = true;
      script.defer = true;

      script.onload = () => {
        this.scriptLoaded = true;
        this.scriptLoadedSubject.next(true);
        resolve();
      };

      script.onerror = (error) => {
        this.scriptLoadedSubject.next(false);

        reject(new Error('Google Maps JavaScript API could not load.'));
      };

      document.head.appendChild(script);
    });
  }
}
