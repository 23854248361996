import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Observable, Subscription } from 'rxjs';
import { AllRestaurantTypesService } from '~/customer-registration/services/all-restaurant-types.service';
import { Configuration } from '~/database/models/configuration';
import { Website } from '~/database/models/website';
import { normalizeBooleanAttribute } from '~lib/helpers';

@Component({
  selector: 'customer-app-section-card',
  templateUrl: './customer-section-card.component.html',
  styleUrls: ['./customer-section-card.component.scss'],
})
export class CustomerSectionCardComponent implements OnInit {
  #disabled = false;
  #button: boolean | undefined;

  @Input()
  color: string | undefined;

  @Input()
  contentColor: string | undefined;

  @Input()
  innerClass: string | undefined;

  @Input()
  faIcon: IconProp | undefined;

  @Input()
  title: string | undefined;

  @Input()
  deliveryFee: string | undefined;

  @Input()
  distance: number | string | undefined;

  @Input()
  newDistance: number | string | undefined;

  @Input()
  configuration: Observable<Configuration[]> | string | undefined;
  configurations: Configuration[] | undefined;
  private readonly subscriptions: Subscription = new Subscription();
  @Input()
  website: Website[] | string | undefined;
  restaurantType: string | undefined;
  @Input()
  type: string | undefined;

  @Input()
  subtitle: string | undefined;

  @Input()
  set disabled(value: boolean | string | undefined) {
    this.#disabled = normalizeBooleanAttribute(value);
  }
  get disabled() {
    return this.#disabled;
  }

  @Input()
  set button(value: string | boolean | undefined) {
    if (value === undefined) {
      this.#button = undefined;
      return;
    }

    this.#button = normalizeBooleanAttribute(value);
  }
  get button() {
    if (this.#button === undefined) {
      return this.clicked.observers.length > 0;
    }

    return this.#button;
  }

  /**
   * Obtiene el ID de la mesa cuando se le da clic al card.
   */
  @Output()
  public readonly clicked: EventEmitter<MouseEvent>;
  selectedType: string | undefined;
  restaurantArray: any = {};
  constructor(private restaurantService: AllRestaurantTypesService) {
    this.clicked = new EventEmitter<MouseEvent>();
    this.restaurantService.initializeCommerces().subscribe((restauranttypedata: any) => {
      restauranttypedata.forEach((restauranttype: any) => {
        this.restaurantArray[restauranttype.data.slug] = {
          name: restauranttype.data.name,
          image: restauranttype.data.image,
        };
      });
    });
  }

  ngOnInit() {
    if (this.configuration && typeof this.configuration !== 'string') {
      const configSub = this.configuration.subscribe((configuration) => {
        this.configurations = configuration;
      });
      this.subscriptions.add(configSub);
    }
    if (this.website && typeof this.website !== 'string') {
      this.restaurantType = this.website[0].data.restaurantType;
    }
  }

  triggerClicked(ev: MouseEvent | CustomEvent | Event) {
    this.clicked.emit(ev as MouseEvent);
  }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
