// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: contents;
}
:host .label-contrast {
  color: var(--ion-color-primary-contrast);
}
:host .text-color {
  color: var(--ion-text-color);
}
:host ion-card {
  margin: 0;
}
:host ion-card ion-grid {
  --ion-grid-column-padding: 5px;
}
:host sup {
  top: -0.5em;
  background: #ff4961;
  color: #fff;
  padding: 8px 6px;
  border-radius: 50%;
  font-size: 10px;
}
:host .big-icon {
  font-size: 2.1em;
}
:host .fa-layers svg.svg-inline--fa {
  color: red;
}
:host .small-text {
  font-size: 11px;
}
:host app-icon-button {
  font-size: 12px;
}
:host ion-text {
  font-size: 12px;
}
:host .ion-activatable {
  overflow: hidden;
  position: relative;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/order-card-customer/order-card-customer.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAAE;EACE,wCAAA;AAEJ;AAAE;EACE,4BAAA;AAEJ;AAAE;EACE,SAAA;AAEJ;AAAI;EACE,8BAAA;AAEN;AACE;EACE,WAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;AACJ;AACE;EACE,gBAAA;AACJ;AACE;EACE,UAAA;AACJ;AACE;EACE,eAAA;AACJ;AACE;EACE,eAAA;AACJ;AACE;EACE,eAAA;AACJ;AAEE;EACE,gBAAA;EACA,kBAAA;EACA,eAAA;AAAJ","sourcesContent":[":host {\n  display: contents;\n  .label-contrast {\n    color: var(--ion-color-primary-contrast);\n  }\n  .text-color {\n    color: var(--ion-text-color);\n  }\n  ion-card {\n    margin: 0;\n\n    ion-grid {\n      --ion-grid-column-padding: 5px;\n    }\n  }\n  sup {\n    top: -0.5em;\n    background: #ff4961;\n    color: #fff;\n    padding: 8px 6px;\n    border-radius: 50%;\n    font-size: 10px;\n  }\n  .big-icon {\n    font-size: 2.1em;\n  }\n  .fa-layers svg.svg-inline--fa {\n    color: red;\n  }\n  .small-text {\n    font-size: 11px;\n  }\n  app-icon-button {\n    font-size: 12px;\n  }\n  ion-text {\n    font-size: 12px;\n  }\n\n  .ion-activatable {\n    overflow: hidden;\n    position: relative;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
