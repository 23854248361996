import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, share } from 'rxjs';
import IFirebaseData from '~lib/database/firebase-data.interface';
import { LaravelApiService, resultIsCollection, UriRoute } from './laravel-api.service';
export interface ICountryDataAttributes extends IFirebaseData {
  id: string;
  type: string;
  attributes: {
    geoPoint: { latitude: number; longitude: number };
    name: string;
    nativeName: string;
    isAvailable: boolean;
    code: string;
    numericCode: string;
    currency: string;
    timezone: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private availableCountriesSubject = new BehaviorSubject<ICountryDataAttributes[]>([]);
  public readonly availableCountries$ = this.availableCountriesSubject.asObservable();

  constructor(private api: LaravelApiService) {
    this.api
      .get<ICountryDataAttributes>(new UriRoute('countries?filter[available]=true'))
      .pipe(
        share(),
        map((res) => {
          if (resultIsCollection(res)) {
            return res.data;
          } else {
            return [];
          }
        })
      )
      .subscribe((data) => {
        this.availableCountriesSubject.next(data);
      });
  }
}
