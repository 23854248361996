// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-order-product-item ::ng-deep ion-item {
  --background: var(--ion-item-background) !important;
}
app-order-product-item ::ng-deep ion-item ion-label {
  --color: var(--ion-color-step-500) !important;
}
app-order-product-item ::ng-deep ion-item ion-label ion-row ion-col ion-text ion-icon {
  color: var(--ion-text-color) !important;
}
app-order-product-item ::ng-deep ion-item ion-label ion-row ion-col ion-text strong {
  color: var(--ion-text-color) !important;
}
app-order-product-item ::ng-deep ion-item ion-label ion-row ion-col strong p {
  color: var(--ion-text-color) !important;
}
app-order-product-item ::ng-deep ion-item ion-label ion-row ion-col ion-label span.price-content {
  color: var(--ion-text-color) !important;
}

ion-toolbar {
  --background: var(--ion-color-light);
}
ion-toolbar ion-title {
  color: var(--ion-text-color);
}
ion-toolbar ion-back-button {
  color: var(--ion-text-color);
}
ion-toolbar fa-icon {
  color: var(--ion-text-color);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/order-products-modal/order-products-modal.component.scss"],"names":[],"mappings":"AAGE;EACE,mDAAA;AAFJ;AAGI;EACE,6CAAA;AADN;AAKY;EACE,uCAAA;AAHd;AAKY;EACE,uCAAA;AAHd;AAOY;EACE,uCAAA;AALd;AAUY;EACE,uCAAA;AARd;;AAgBA;EACE,oCAAA;AAbF;AAcE;EACE,4BAAA;AAZJ;AAcE;EACE,4BAAA;AAZJ;AAcE;EACE,4BAAA;AAZJ","sourcesContent":["//TODO: fix hardcoded styles for this component. This is a temporal solution\n\napp-order-product-item ::ng-deep {\n  ion-item {\n    --background: var(--ion-item-background) !important;\n    ion-label {\n      --color: var(--ion-color-step-500) !important;\n      ion-row {\n        ion-col {\n          ion-text {\n            ion-icon {\n              color: var(--ion-text-color) !important;\n            }\n            strong {\n              color: var(--ion-text-color) !important;\n            }\n          }\n          strong {\n            p {\n              color: var(--ion-text-color) !important;\n            }\n          }\n\n          ion-label {\n            span.price-content {\n              color: var(--ion-text-color) !important;\n            }\n          }\n        }\n      }\n    }\n  }\n}\nion-toolbar {\n  --background: var(--ion-color-light);\n  ion-title {\n    color: var(--ion-text-color);\n  }\n  ion-back-button {\n    color: var(--ion-text-color);\n  }\n  fa-icon {\n    color: var(--ion-text-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
