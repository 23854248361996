// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: contents;
}
:host ion-chip ion-icon:first-child {
  margin-right: 4px;
}
:host .notification__title {
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/notification-item/notification-item.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAII;EACE,iBAAA;AAFN;AAOI;EAEE,gBAAA;AANN","sourcesContent":[":host {\n  display: contents;\n\n  ion-chip {\n    // border-radius: 8px;\n    // height: auto;\n    ion-icon:first-child {\n      margin-right: 4px;\n    }\n  }\n\n  .notification {\n    &__title {\n      // font-size: 1.1em;\n      font-weight: 500;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
