import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription as RxSubscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { FeatureSlug } from '~/database/models/feature';
import { Plan } from '~/database/models/plan';
import { FeatureWithPivot } from '~/database/models/plan.feature-with-pivot';
import { Restaurant } from '~/database/models/restaurant';
import { RestaurantRelationships } from '~/database/models/restaurant.relationships';
import { SubscriptionRelationships } from '~/database/models/subscription.relationships';

import { SessionService } from './session.service';

export interface ActivePlanServiceInitOptions {
  restaurant: Restaurant | null | undefined;
  force?: boolean;
}

/**
 * Controla y administra el plan actual habilitado por el restaurant y sus módulos habilitados.
 */
@Injectable({
  providedIn: 'root',
})
export class ActivePlanService {
  private _busy = false;

  get busy() {
    return this._busy;
  }

  protected readonly subscriptionSubject: BehaviorSubject<SubscriptionRelationships | null | undefined>;

  public readonly subscription$: Observable<SubscriptionRelationships | null | undefined>;

  public get subscription(): SubscriptionRelationships | null | undefined {
    return this.subscriptionSubject.value;
  }

  protected subscriptionSub: RxSubscription | undefined;
  protected planSub: RxSubscription | undefined;
  protected featuresSub: RxSubscription | undefined;

  /**
   */
  protected readonly planSubject: BehaviorSubject<Plan | null | undefined>;

  /**
   * Si el valor es undefined, significa que aún no se ha inicializado (sucede cuando se cambia/inicializa el Restaurant).
   */
  public readonly plan$: Observable<Plan | null | undefined>;

  /**
   * Si el valor es undefined, significa que aún no se ha inicializado.
   */
  public get plan(): Plan | null | undefined {
    return this.planSubject.value;
  }

  protected readonly featuresSubject: BehaviorSubject<FeatureWithPivot[] | undefined>;

  /**
   * Obtiene un stream de los módulos habilitados para el Plan actual del restaraunt.
   *
   * Si el valor es undefined, significa que aún no se han inicializado (sucede cuando se cambia/inicializa el Plan)
   */
  public readonly features$: Observable<FeatureWithPivot[] | undefined>;

  /**
   * Si el valor es undefined, significa que aún no se han inicializado.
   */
  public get features(): FeatureWithPivot[] | undefined {
    return this.featuresSubject.value;
  }

  private restaurant: RestaurantRelationships | null | undefined;

  private subscriptions: RxSubscription | undefined;

  constructor(session: SessionService) {
    this.subscriptionSubject = new BehaviorSubject<SubscriptionRelationships | null | undefined>(undefined);
    this.subscription$ = this.subscriptionSubject.asObservable().pipe(shareReplay(1));

    this.planSubject = new BehaviorSubject<Plan | null | undefined>(undefined);
    this.plan$ = this.planSubject.asObservable().pipe(shareReplay(1));

    this.featuresSubject = new BehaviorSubject<FeatureWithPivot[] | undefined>(undefined);
    this.features$ = this.featuresSubject.asObservable().pipe(shareReplay(1));

    // FIXME: Agregar lógica para desuscribirse
    session.restaurant$.subscribe((restaurant) => {
      this.init({ restaurant });
    });
  }

  public init(options: ActivePlanServiceInitOptions) {
    if (this.busy) {
      if (!options.force) {
        console.warn('ActivePlanService is busy with initialization', {
          Restaurant: this.restaurant?.id,
          Plan: this.plan?.id,
          modules: this.features?.length,
        });
        return;
      } else {
        console.log('Force initialization requested.');
      }
    }
  
    this._busy = true;
  
    // If the restaurant is not defined, reset all related subjects and stop the initialization
    if (!options.restaurant?.id) {
      this.restaurant = null;
      this.subscriptions?.unsubscribe();
  
      this.subscriptionSubject.next(null);
      this.planSubject.next(null);
      this.featuresSubject.next([]);
  
      this._busy = false;
      return;
    }
  
    // If the restaurant is already initialized, skip unless forced
    if (
      this.restaurant?.id === options.restaurant.id &&
      this.restaurant.data.subscriptionId === options.restaurant.data.subscriptionId &&
      !options.force
    ) {
      console.warn(
        'ActivePlanService already initialized the specified restaurant. Skipping. Use `force` to force initialization',
        {
          id: options.restaurant.id,
        }
      );
      this._busy = false;
      return;
    }
  
    // Update the restaurant relationships and reset subscriptions
    this.restaurant = new RestaurantRelationships(options.restaurant);
  
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.subscriptions = new RxSubscription();
  
    if (this.subscriptionSub?.closed === false) {
      this.subscriptions.remove(this.subscriptionSub);
      this.subscriptionSub.unsubscribe();
    }
  
    this.planSub?.unsubscribe();
    this.featuresSub?.unsubscribe();
  
    // Subscribe to the restaurant's subscription
    this.subscriptionSub = this.restaurant.subscription().subscribe((subscription) => {
      if (subscription) {
        let nextSubscription: SubscriptionRelationships | null = this.subscription || null;
  
        if (nextSubscription?.id === subscription.id) {
          nextSubscription.model.fill(subscription.data);
        } else {
          nextSubscription = new SubscriptionRelationships(subscription);
        }
  
        this.subscriptionSubject.next(nextSubscription);
  
        if (!nextSubscription) {
          this.planSub?.unsubscribe();
          this.planSubject.next(null);
          this.featuresSub?.unsubscribe();
          this.featuresSubject.next([]);
        } else {
          // Update plan and features
          if (this.subscription?.data.planId !== this.plan?.id) {
            this.planSub?.unsubscribe();
  
            this.planSub = nextSubscription.plan().subscribe((plan) => {
              if (this.plan && this.plan.id === plan?.id) {
                this.plan?.fill(plan.data);
                this.planSubject.next(this.plan);
              } else {
                this.planSubject.next(plan);
                this.featuresSub?.unsubscribe();
                this.featuresSubject.next(undefined);
  
                if (!!plan) {
                  this.featuresSub = plan.features$.subscribe((features) => {
                    this.featuresSubject.next(features);
                  });
  
                  this.subscriptions?.add(this.featuresSub);
                }
              }
            });
  
            this.subscriptions?.add(this.planSub);
          }
        }
      }
    });
  
    if (this.subscriptionSub) {
      this.subscriptions.add(this.subscriptionSub);
    }
  
    this._busy = false;
  }
  
  
  /**
   * Obtiene un stream indicando si el plan actual tiene habilitadas TODAS las funcionalidades especificadas.
   *
   * @return Devuelve `undefined` si aún no se han cargado.
   */
  public hasFeature$(slug: FeatureSlug | FeatureSlug[]): Observable<boolean | undefined> {
    const slugs = [...new Set(typeof slug === 'string' ? [slug] : slug)];

    if (slugs.length === 0) {
      return of(false);
    }

    return this.features$.pipe(
      map((features) => {
        if (!features) {
          return undefined;
        }
        // console.log(
        //   slugs,
        //   features.map((a) => a.slug)
        // );

        return slugs.every((s) => {
          // features.forEach((value)=>{
          //   if(value.slug === s && value.data.pivot.planId == this.plan?.id){
          //     console.log(value.id,value.slug,value.data.pivot.planId,this.plan?.id)
          //   }
          // })

          return features.findIndex((feat) => feat.slug === s && feat.data.pivot.planId == this.plan?.id) !== -1;
        });
      })
    );
  }

  /**
   * Obtiene un stream indicando si el plan actual tiene habilitadas AL MENOS UNA de las funcionalidades especificadas.
   *
   * @return Devuelve `undefined` si aún no se han cargado.
   */
  public hasAnyFeature$(slug: FeatureSlug | FeatureSlug[]): Observable<boolean | undefined> {
    const slugs = [...new Set(typeof slug === 'string' ? [slug] : slug)];

    if (slugs.length === 0) {
      return of(false);
    }

    return this.features$.pipe(
      map((features) => {
        if (!features) {
          return undefined;
        }

        return (
          slugs.find((s) => {
            //  features.forEach((value)=>{
            //     if(value.slug === s && value.data.pivot.planId == this.plan?.id){
            //       console.log(s,value.id,value.slug,value.data.pivot.planId,this.plan?.id)
            //     }
            //   })

            // return features.findIndex((feat) => feat.slug === s) !== -1;
            return features.findIndex((feat) => feat.slug === s && feat.data.pivot.planId == this.plan?.id) !== -1;
          }) !== undefined
        );
      })
    );
  }
}
