import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard, AuthGuardParams } from '~shared/guards/auth.guard';
import { RestaurantSelectedGuard } from '~shared/guards/restaurant-selected.guard';
import { TableSelectedGuard } from '~shared/guards/table-selected.guard';

const routes: Routes = [
  // División de módulos
  {
    path: 'attendant',
    loadChildren: () => import('./attendant/attendant.module').then((m) => m.AttendantPageModule),
    canActivate: [AuthGuard],
    data: {
      authGuardParams: { checkIsType: 'attendant' },
    } as { authGuardParams: AuthGuardParams },
  },
  {
    path: 'driver',
    loadChildren: () => import('./driver/driver.module').then((m) => m.DriverPageModule),
    canActivate: [AuthGuard],
    data: {
      authGuardParams: { checkIsType: 'attendant' },
    } as { authGuardParams: AuthGuardParams },
  },
  {
    path: 'customer',
    loadChildren: () => import('./customer/customer.module').then((m) => m.CustomerPageModule),
    canActivate: [
      AuthGuard,
      TableSelectedGuard,
      RestaurantSelectedGuard,
    ],
    data: {
      authGuardParams: { checkIsType: 'customer' },
    } as { authGuardParams: AuthGuardParams },
  },
  {
    path: 'regcustomer',
    loadChildren: () =>
      import('./customer-registration/customer-registration.module').then((m) => m.CustomerRegistratonPageModule),
    canActivate: [AuthGuard],
    data: {
      authGuardParams: { checkIsType: 'regcustomer' },
    } as { authGuardParams: AuthGuardParams },
  },
  {
    path: 'preparation',
    loadChildren: () => import('./preparation/preparation.module').then((m) => m.PreparationPageModule),
    canActivate: [AuthGuard],
    data: {
      authGuardParams: { checkIsType: 'attendant' },
    } as { authGuardParams: AuthGuardParams },
  },
  {
    path: '',
    loadChildren: () => import('./shared/shared.module').then((m) => m.SharedPageModule),
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
