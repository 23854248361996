import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Query } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IonContent, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Chat, IChatData } from '~/database/models/chat';
import { OrderDriver } from '~/database/models/order-driver';
import { OrderProduct } from '~/database/models/order-product';
import { IOrderProductData } from '~/database/models/order-product-data.interface';
import { Product } from '~/database/models/product';
import { Restaurant } from '~/database/models/restaurant';
import { IRestaurantData } from '~/database/models/restaurant-data.interface';
import { User } from '~/database/models/user';
import { AuthenticationService } from '~shared/services/authentication.service';
import { LaravelApiService, UriRoute, resultIsCollection } from '~shared/services/laravel-api.service';
import { ScrollService } from './scroll.service';

export type OrderItem = { product?: Observable<Product | null> } & Pick<
  IOrderProductData,
  'price' | 'quantity' | 'notes' | 'offerApplicable' | 'promocode' | 'promoquantity' | 'paiditems'
>;

@Component({
  selector: 'app-chatting-modal',
  templateUrl: './chatting-modal.component.html',
  styleUrls: ['./chatting-modal.component.scss'],
})
export class ChattingModalComponent implements OnInit {
  #orderProducts: OrderProduct[] = [];
  #orderTeamId: string = '';
  #userType: string = '';
  #orderAddressType: string = '';
  #attendantId: string = '';
  #orderId: string = '';
  #customerId: string | null = '';
  #driver: Observable<OrderDriver | null | undefined> | undefined;
  driver_id: string = '';
  attendant_id: string = '';
  form: UntypedFormGroup;
  driverName: string = '';
  #driverId: string | null = '';
  customerName: string | null = '';
  chats$: Observable<Chat[]> | undefined;
  chats: Chat[] | undefined;
  scrolledToBottom = false;
  senderId: string | null | undefined;
  recieverId: string | null | undefined;
  chatType: string | null | undefined;
  #otherUserType: string | null | undefined;
  clicked: boolean = false;
  public restaurants$: Observable<Restaurant | null | undefined> | undefined;
  private user$: Observable<User | null | undefined> | undefined;
  private customer$: Observable<User | null | undefined> | undefined;

  @HostListener('scroll', ['$event'])
  @Input()
  set userType(userType) {
    this.#userType = userType;
  }

  @Input()
  set otherUserType(otherUserType) {
    this.#otherUserType = otherUserType;
  }

  set orderAddressType(orderAddressType) {
    this.#orderAddressType = orderAddressType;
  }
  @Input()
  set orderTeamId(orderTeamId) {
    this.#orderTeamId = orderTeamId;
  }
  @Input()
  set attendantId(attendantId) {
    this.#attendantId = attendantId;
  }
  @Input()
  set orderId(orderId) {
    this.#orderId = orderId;
  }

  @Input()
  set driverId(driverId) {
    this.#driverId = driverId;
  }

  @Input()
  set customerId(customerId) {
    this.#customerId = customerId;
  }

  @Input()
  set orderProducts(products) {
    this.#orderProducts = products;
  }

  get otherUserType() {
    return this.#otherUserType;
  }
  get userType() {
    return this.#userType;
  }
  get orderAddressType() {
    return this.#orderAddressType;
  }

  get orderId() {
    return this.#orderId;
  }
  get driverId() {
    return this.#driverId;
  }
  get customerId() {
    return this.#customerId;
  }
  get orderTeamId() {
    return this.#orderTeamId;
  }
  get attendantId() {
    return this.#attendantId;
  }
  get orderProducts() {
    return this.#orderProducts;
  }

  items: OrderItem[] = [];
  total = 0;
  errorMessages: Record<string,string> = {} as Record<string,string>;

  @ViewChild('scrollMe') scrollMe!: ElementRef;
  @ViewChild(IonContent) content!: IonContent;
  constructor(
    private auth: AuthenticationService,
    public modalController: ModalController,
    private api: LaravelApiService,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private scrollservice: ScrollService
  ) {
    this.form = new UntypedFormGroup({
      message: new UntypedFormControl(),
    });

    //this.form.controls.productcomments = new FormArray([]);
  }
  scrollToBottom() {
    setTimeout(() => {
      if (this.content.scrollToBottom) {
        this.content.scrollToBottom(800);
      }
    }, 400);
  }
  async getOrderChat(orderId: string) {
    this.chats$ = await new Chat()
      .odm()
      .collection((ref) => {
        let q: Query<IChatData> | undefined;
        if (q) {
          q = q.where('orderId', '==', orderId).orderBy('createdAt', 'asc');
        } else {
          q = ref.where('orderId', '==', orderId).orderBy('createdAt', 'asc');
        }
        return q;
      })
      .snapshotChanges()
      .pipe(
        map((documents) => {
          return documents.map((doc) => {
            return new Chat(doc.payload.doc.data(), doc.payload.doc.id);
          });
        })
      );
  }

  async sendMessage(orderId: string) {
    this.clicked = true;
    let message = this.form.controls.message.value;

    // console.log(senderId,recieverId,this.orderAddressType,this.userType)
    // return;
    const loading = await this.loadingCtrl.create({
      cssClass: 'custom-loading',
      backdropDismiss: false,
      message: 'Please wait...',
    });

    try {
      const uri = new UriRoute('orders/addchats');
      const res = await this.api
        .post(uri, {
          senderId: this.senderId,
          recieverId: this.recieverId,
          userType: this.userType,
          orderId: orderId,
          message: message,
          chatType: this.chatType,
        })
        .pipe(first())
        .toPromise();
      if (res) {
        const categoryControl = this.form.get('message') as UntypedFormControl;
        categoryControl.setValue('');
        this.getOrderChat(orderId);
        this.clicked = false;
      }
    } catch (err: any) {
      this.errorMessages = err.error.errors;
    }
  }

  async setUpLocalVariable() {
    if (this.userType == 'customer' && this.orderAddressType !== 'pickup') {
      this.senderId = this.auth.currentUser?.uid;
      this.recieverId = this.#driverId;
      this.chatType = this.otherUserType;
    }
    if (this.userType == 'customer' && this.orderAddressType == 'pickup') {
      this.senderId = this.auth.currentUser?.uid;
      this.recieverId = this.customerId;
      this.chatType = this.otherUserType;
    }
    if (this.userType == 'driver') {
      this.senderId = this.auth.currentUser?.uid;
      this.recieverId = this.customerId;
      this.chatType = this.otherUserType;
    }
    if (this.userType == 'kitchen-display' && this.orderAddressType !== 'pickup') {
      this.senderId = this.auth.currentUser?.uid;
      this.recieverId = this.driverId;
      this.chatType = this.otherUserType;
    }
    if (this.userType == 'kitchen-display' && this.orderAddressType == 'pickup') {
      this.senderId = this.auth.currentUser?.uid;
      this.recieverId = this.customerId;
      this.chatType = this.otherUserType;
    }

    try {
      const uri = new UriRoute('orders/updatechats');
      const res = await this.api
        .post(uri, {
          senderId: this.senderId,
          recieverId: this.recieverId,
          userType: this.userType,
          orderId: this.orderId,
          chatType: this.chatType,
        })
        .pipe(first())
        .toPromise();
      if (res) {
      }
    } catch (err) {
      console.error('Unable so save state', err);
    } finally {
    }
  }

  async ngOnInit() {
    this.setUpLocalVariable();
    await this.getOrderChat(this.orderId);
    setTimeout(() => {
      this.scrollToBottom();
    }, 2500);

    this.total = 0;

    this.items = this.#orderProducts.map((orderProduct) => {
      const item: OrderItem = {
        price: +orderProduct.data.price,
        quantity: orderProduct.data.quantity,
        notes: orderProduct.data.notes,
        offerApplicable: orderProduct.data.offerApplicable,
        promocode: orderProduct.data.promocode,
        promoquantity: orderProduct.data.promoquantity,
        paiditems: orderProduct.data.paiditems,
      };

      const ref = orderProduct.restaurantRef();

      if (ref) {
        const p = new Product({}, orderProduct.data.productId, ref.path);

        const p$ = p
          .odm()
          .doc()
          .get()
          .pipe(
            map((r) => {
              const data = r.data();
              // console.log(r);

              if (!data) {
                return null;
              }

              return new Product(data, r.id, ref.path);
            })
          );

        item.product = p$;
      } else {
        if (this.orderTeamId) {
          const p = new Product({}, orderProduct.data.productId, `restaurants/${this.#orderTeamId}`);
          //restaurants/${restaurant.id}/order/${data.id}
          const p$ = p
            .odm()
            .doc()
            .get()
            .pipe(
              map((r) => {
                const data = r.data();
                // console.log(r);

                if (!data) {
                  return null;
                }

                return new Product(data, r.id, `restaurants/${this.#orderTeamId}`);
              })
            );

          item.product = p$;
        }
      }
      if (this.customerId) {
        const customer$ = new User({}, this.customerId)
          .odm()
          .doc()
          .get()
          .pipe(
            map((snapshot) => {
              return new User(snapshot.data(), this.customerId);
            })
          )
          .subscribe((user) => {
            if (user) {
              this.customerId = user.data.id;
              this.customerName = user.data.name;
            }
          });
      }

      // if(this.#driver){

      //   this.#driver.subscribe((driver:any)=>{
      //     if(driver && driver.length > 0)
      //     {
      //       const driver$ = new User({}, driver[0].data.userId)
      //     .odm()
      //     .doc()
      //     .get()
      //     .pipe(
      //       map((snapshot) => {
      //         return new User(snapshot.data(), driver[0].data.userId);
      //       })).subscribe((user)=>{
      //         if(user){

      //           this.driverName = user.data.name;
      //           this.driverId = user.data.id;
      //           console.log(this.driverId)
      //         }
      //       });

      //     }

      //   })
      // }

      if (this.orderTeamId) {
        this.restaurants$ = this.api.get<IRestaurantData>(new UriRoute(`teams/${this.orderTeamId}`)).pipe(
          first(),
          map((res) => {
            if (!res.data || resultIsCollection(res)) {
              return null;
            }
            return new Restaurant(res.data, res.data.id);
          })
        );
      }
      if (this.attendantId) {
        this.user$ = new User({}, this.attendantId)
          .odm()
          .doc()
          .get()
          .pipe(
            map((snapshot) => {
              return new User(snapshot.data(), this.attendantId);
            })
          );
      }
      let extra_total = 0;
      if (item.promoquantity) {
        this.total += +item.price * item.promoquantity;
      } else {
        this.total += +item.price * item.quantity;
      }

      if (item.paiditems) {
        JSON.parse(item.paiditems.toString()).forEach((element: any) => {
          extra_total = extra_total + parseFloat(element.value);
        });
        this.total = this.total + extra_total;
      }

      return item;
    });
  }
  myFunction(event: any) {
    console.log(event);
    console.log('hiiiiiiiiiiiiiiiiiiiiiiiii');
    var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    var scrolled = (winScroll / height) * 100;
    console.log(scrolled);
  }

  onContainerScroll() {
    console.log('hi');
  }
  public dismiss() {
    this.modalController.dismiss({}, 'cancel');
  }
  showProductReviewBox(productid: string) {
    if (productid) {
      document.getElementById(productid)?.classList.remove('d-none');
      document.getElementById(productid)?.classList.add('d-block');
    }
  }
}
