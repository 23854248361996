import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, pairwise, shareReplay, startWith, tap } from 'rxjs/operators';
import { Configuration } from '~/database/models/configuration';
import { Restaurant } from '~/database/models/restaurant';
import { ActivePlanService } from '~shared/services/active-plan.service';
import { RestaurantThemeService } from '~shared/services/restaurant-theme.service';
import { SessionService } from '~shared/services/session.service';

/**
 * Loading status for restaurant progress.
 */
export type LoadingStatus = 'loading' | 'unloading' | 'loaded' | 'unloaded' | undefined;

export interface IRestaurantLoadingData {
  name: string | null;
  restaurant: Restaurant | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class RestaurantLoadingService implements OnDestroy {
  configuration$: Observable<Configuration[]> | undefined;
  currentRestaurantLogo: string | undefined;
  protected readonly statusSubject = new BehaviorSubject<LoadingStatus>(undefined);

  /**
   * Estado de carga de la info y personalizaciones del restaurante.
   */
  public readonly status$: Observable<LoadingStatus>;

  protected set status(value: LoadingStatus) {
    switch (value) {
      case 'unloading':
        if (this.statusSubject.value !== 'unloading') {
          //TODO: Find a better solution to loading bar movement
          /*To prevent a back and forth on the progress bar when entering regcustomer table session*/
          this.dataLoadingProgressSubject.next(0);
          this.themeLoadingProgressSubject.next(0);
          this.featuresLoadingProgressSubject.next(0);
        }
        break;

      case 'loading':
        if (this.statusSubject.value !== 'loading') {
          this.dataLoadingProgressSubject.next(0);
          this.themeLoadingProgressSubject.next(0);
          this.featuresLoadingProgressSubject.next(0);
        }
        break;

      default:
        break;
    }

    this.statusSubject.next(value);
  }
  protected get status(): LoadingStatus {
    return this.statusSubject.value;
  }

  private readonly dataLoadingProgressSubject = new BehaviorSubject<number>(0);
  private readonly themeLoadingProgressSubject = new BehaviorSubject<number>(0);
  private readonly featuresLoadingProgressSubject = new BehaviorSubject<number>(0);

  protected readonly dataLoadingProgress$: Observable<number>;
  protected readonly themeLoadingProgress$: Observable<number>;
  protected readonly featuresLoadingProgress$: Observable<number>;

  /**
   * Stream del progreso combinado de la carga (entre 0 y 1).
   *
   * Starts at 0 when the restaurant is loading, and starts at 1 when the restaurant is unloading.
   */
  public readonly loadingProgress$: Observable<number>;

  // Mocked data para el restaurante
  protected readonly restaurantDataSubject = new BehaviorSubject<IRestaurantLoadingData | null>(null);

  /**
   * Getter para el Observable del restaurante
   */
  public readonly restaurantData$: Observable<IRestaurantLoadingData | null>;

  private readonly subscriptions = new Subscription();

  public constructor(
    private readonly session: SessionService,
    private readonly plan: ActivePlanService,
    private readonly theme: RestaurantThemeService
  ) {
    this.status$ = this.statusSubject.asObservable().pipe(shareReplay(1));

    this.restaurantData$ = this.restaurantDataSubject.asObservable().pipe(shareReplay(1));

    this.dataLoadingProgress$ = this.dataLoadingProgressSubject.asObservable().pipe(shareReplay(1));
    this.themeLoadingProgress$ = this.themeLoadingProgressSubject.asObservable().pipe(shareReplay(1));
    this.featuresLoadingProgress$ = this.featuresLoadingProgressSubject.asObservable().pipe(shareReplay(1));

    // El restaurante va a definir cuándo está loading y cuándo se está unloading
    const mainSub = this.session.restaurant$.pipe(startWith(undefined), pairwise()).subscribe(
      ([
        previousRestaurant,
        currentRestaurant,
      ]) => {
        // Importante: Este stream sólo va a modificar el estado entre 'loading', 'unloading' y undefined
        // El 'loaded' y 'unloaded' se construyen en otro lado.

        console.log('🅰️', previousRestaurant);
        console.log('🅱️', currentRestaurant);

        if (previousRestaurant === undefined) {
          if (currentRestaurant === undefined) {
            // Estado inicial

            this.statusSubject.next(undefined);
          } else if (currentRestaurant === null) {
            // Se ha inicializado el servicio y no hay restaurante activo
            this.status = 'unloaded';
          } else {
            this.status = 'loading';
            this.restaurantDataSubject.next({
              name: currentRestaurant.data.name,
              restaurant: currentRestaurant,
            });
          }
        } else if (previousRestaurant === null) {
          if (currentRestaurant !== null) {
            // Empieza a cargar el restaurante
            this.status = 'loading';
          }
        } else {
          if (!currentRestaurant) {
            this.status = 'unloading';
            setTimeout(() => {
              this.status = 'unloaded';
            }, 1500);
          }
        }

        // Data progress
        if (!!currentRestaurant) {
          this.dataLoadingProgressSubject.next(1);

          // TODO: Cargar data en restaurantDataSubject
        } else {
          this.dataLoadingProgressSubject.next(0);

          this.restaurantDataSubject.next(null);
        }
      }
    );

    this.subscriptions.add(mainSub);

    this.loadingProgress$ = combineLatest([
      this.dataLoadingProgress$,
      this.themeLoadingProgress$,
      this.featuresLoadingProgress$,
    ]).pipe(
      shareReplay(1),
      map((loadingProgressList) => {
        return loadingProgressList.reduce((prevValue, currentValue) => prevValue + currentValue, 0) / 3;
      })
    );

    // Esta suscripción es la que cambia a 'loaded' y 'unloaded', basado en el progress
    const completedSub = this.loadingProgress$
      .pipe(
        // TODO: Verificar si este filter realmente mejora el desempeño
        filter((value) => value <= 0 || value >= 1)
      )
      .subscribe((value) => {
        switch (this.status) {
          case 'loading':
            if (value >= 1) {
              this.status = 'loaded';
            }
            break;

          case 'unloading':
            if (value <= 0) {
              this.status = 'unloaded';
            }

            break;
        }
      });

    this.subscriptions.add(completedSub);

    const themeLoadingSubscription = this.theme.themeVariables$.subscribe((themeVariables) => {
      console.log('themeLoadingSubscription', { themeVariables }); // DEBUG
      this.themeLoadingProgressSubject.next(themeVariables === undefined ? 0 : 1); // Algunos restaurantes no tienen tema
    });

    this.subscriptions.add(themeLoadingSubscription);

    const featuresLoadingSubscription = this.plan.features$.subscribe((features) => {
      console.log('featuresLoadingSubscription', { features }); // DEBUG
      this.featuresLoadingProgressSubject.next(features !== undefined ? 1 : 0);
    });

    this.subscriptions.add(featuresLoadingSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
