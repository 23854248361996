import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import _ from 'lodash-es';
import { first } from 'rxjs/operators';
import IFirebaseData from '~lib/database/firebase-data.interface';
import { IJsonMap } from '~lib/types';
import {
  ILaravelApiCollectionResult,
  ILaravelApiSingleResult,
  LaravelApiService,
  UriRoute,
  resultIsCollection,
} from '~shared/services/laravel-api.service';

import { firstValueFrom } from 'rxjs';
import { objectToQueryString } from './index';
import { ServiceLocator } from './service-locator';

export type RequestMethod = 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'GET';

export interface ApiFormOptions {
  /**
   * Método a usar para enviar el formulario.
   */
  method: RequestMethod;

  /**
   * Configuración del endpoint.
   */
  uri: UriRoute;

  /**
   * Parámetros estáticos para usar al enviar el formulario.
   */
  queryParams?: HttpParams;

  /**
   * Controles de FormGroup que que se agregarán como extra en los query params.
   *
   * Estos controles se deberán agregar manualmente usando el método this.addControl('{name}', new FormGroup(...))
   *
   * Ejemplo: 'filter', el cuál no se enviaría en el cuerpo del formulario, sino como query param.
   */
  queryParamsControls?: string[];
}

/**
 * Formulario genérico para enviar peticiones a la API de Laravel, usando las validaciones de backend.
 *
 * Provee auto-asignación de los errores. Estos se reinician al cambiar el valor del control.
 */
export class ApiForm<T extends IJsonMap> extends UntypedFormGroup {
  static apiService: LaravelApiService | undefined;

  protected readonly initialData: Partial<T>;

  protected readonly apiOptions: ApiFormOptions;

  public submitting: boolean = false;

  /**
   * @deprecated Use submitting
   */
  public get submiting(): boolean {
    return this.submitting;
  }
  public set submiting(value: boolean) {
    // FIXME: Remove this typo
    this.submitting = value;
  }

  constructor(apiOptions: ApiFormOptions, initialData: Partial<T>, controlsKeys: Array<keyof T> = []) {
    const controls: {
      [key: string]: AbstractControl;
    } = {};

    controlsKeys.forEach((key) => {
      controls[key as string] = new UntypedFormControl(initialData[key]);
    });

    super(controls);

    this.apiOptions = apiOptions;

    this.initialData = initialData;

    this.submitting = false;
  }

  getRawValue() {
    return super.getRawValue() as T;
  }

  /**
   * Controla el error de validación (422). Si el error es de otro tipo, lo lanza de nuevo
   */
  protected handleValidationError(err: HttpErrorResponse | unknown) {
    if (!(err instanceof HttpErrorResponse)) {
      throw err;
    }

    if (err.status !== 422) {
      throw err;
    }

    const data = err.error as { message: string; errors: { [key: string]: string[] } };

    // TODO: Hacer compatible con hijos que son FormGroup que extienden ApiForm (objetos)

    for (const name in data.errors) {
      if (data.errors.hasOwnProperty(name)) {
        this.controls[name]?.setErrors(data.errors[name], { emitEvent: true });
      }
    }

    // console.error('Error capturado', data);

    // this.setErrors(data.errors.email, { emitEvent: true });
  }

  /**
   * Envía el formulario, obteniendo sólo el ID afectado o si fue exitoso o no (true|false)
   */
  async submit(): Promise<boolean | string>;

  /**
   * Envía el formulario, obteniendo la respuesta original.
   */
  async submit<U extends IFirebaseData = IFirebaseData>(
    getOriginalResponse: true
  ): Promise<ILaravelApiSingleResult<U> | ILaravelApiCollectionResult<U>>;

  async submit<U extends IFirebaseData = IFirebaseData>(
    getOriginalResponse?: boolean
  ): Promise<boolean | string | ILaravelApiSingleResult<U> | ILaravelApiCollectionResult<U>> {
    if (this.submitting) {
      throw new Error('Alredy submiting');
    }

    let success = false;
    this.submitting = true;

    let resultId: string | null = null;
    let result: ILaravelApiSingleResult<U> | ILaravelApiCollectionResult<U> = { data: null };

    // this.disable();

    let queryParams: HttpParams | undefined;
    let formData = this.getRawValue();

    if (this.apiOptions.queryParamsControls && this.apiOptions.queryParamsControls.length > 0) {
      let qParams = new HttpParams({
        fromString: objectToQueryString(_.pick(this.getRawValue(), this.apiOptions.queryParamsControls)),
      });

      if (this.apiOptions.queryParams) {
        const optionsQueryParams = this.apiOptions.queryParams;

        optionsQueryParams.keys().forEach((key) => {
          (optionsQueryParams.getAll(key) ?? []).forEach((val) => {
            // console.log(key, val);
            qParams = qParams.append(key, val);
          });
        });
      }

      formData = _.omit(this.getRawValue(), this.apiOptions.queryParamsControls) as T;

      queryParams = qParams;

      // console.log('🚧', { queryParams: queryParams.toString(), formData });
    } else {
      queryParams = this.apiOptions.queryParams;
    }

    try {
      // if ('1' === ' 1'.trim()) {
      //   throw new Error('🚧');
      // }
      switch (this.apiOptions.method) {
        case 'GET':
          // console.log(objectToQueryString(data));

          let params = new HttpParams({ fromString: objectToQueryString(formData) });

          if (queryParams) {
            // console.log(this.apiOptions.queryParams);
            const optionsQueryParams = queryParams;

            optionsQueryParams.keys().forEach((key) => {
              (optionsQueryParams.getAll(key) ?? []).forEach((val) => {
                // console.log(key, val);
                params = params.append(key, val);
              });
            });
          }

          // console.log(params);

          result = await firstValueFrom(
            (this.constructor as typeof ApiForm).api
              .get<U>(this.apiOptions.uri, {
                params,
              })
              .pipe(first())
          );
          break;

        case 'POST':
          result = await firstValueFrom(
            (this.constructor as typeof ApiForm).api
              .post<U>(this.apiOptions.uri, formData, {
                params: queryParams,
              })
              .pipe(first())
          );

          if (!resultIsCollection(result)) {
            resultId = (result.data?.id as string | null) ?? null;
          }
          break;

        case 'PATCH':
          result = await firstValueFrom(
            (this.constructor as typeof ApiForm).api
              .patch<U>(this.apiOptions.uri, formData, {
                params: queryParams,
              })
              .pipe(first())
          );
          if (!resultIsCollection(result)) {
            resultId = (result.data?.id as string | null) ?? null;
          }
          break;

        case 'PUT':
          result = await firstValueFrom(
            (this.constructor as typeof ApiForm).api
              .put<U>(this.apiOptions.uri, formData, {
                params: queryParams,
              })
              .pipe(first())
          );
          if (!resultIsCollection(result)) {
            resultId = (result.data?.id as string | null) ?? null;
          }
          break;

        default:
          throw new Error(`Not implemented method for sending the form: ${this.apiOptions.method}`);
      }

      success = true;
    } catch (err) {
      // this.enable();
      this.handleValidationError(err);
    } finally {
      // this.enable();
      this.submitting = false;
    }

    return getOriginalResponse ? result : resultId ?? success;
  }

  static get api() {
    if (!this.apiService) {
      this.apiService = ServiceLocator.injector.get(LaravelApiService);
    }

    return this.apiService;
  }
}
