import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthenticationService } from '~shared/services/authentication.service';

import { LaravelApiService, resultIsCollection, UriRoute } from '~shared/services/laravel-api.service';

import { Booking } from '~/database/models/booking';
import { BookingStatus, IBookingStatusData } from '~/database/models/booking-status';
import { Restaurant } from '~/database/models/restaurant';
import { SessionService } from '~shared/services/session.service';
@Component({
  selector: 'app-booking-card-customer',
  templateUrl: './booking-card-customer.component.html',
  styleUrls: ['./booking-card-customer.component.scss'],
})
export class BookingCardCustomerComponent implements OnInit, OnDestroy {
  #statuses: IBookingStatusData[] = [];
  currentUserType: string | null | undefined;
  @Input()
  booking: Booking | undefined;

  restaurant: Restaurant | null | undefined;

  @Output()
  public readonly cardClick = new EventEmitter<string>();

  @Output()
  public readonly confirmClick = new EventEmitter<string>();

  @Input()
  set statuses(statuses) {
    this.#statuses = statuses.sort((a, b) => {
      return a.value - a.value;
    });
  }
  get statuses() {
    return this.#statuses;
  }

  get status(): BookingStatus {
    const f = this.statuses.find((item) => {
      return item.id === this.booking?.data.bookingStatusId;
    }) || {
      slug: 'booked',
      title: 'Booked',
      value: 0,
      createdAt: null,
      description: null,
      id: null,
      updatedAt: null,
    };

    return new BookingStatus(f);
  }

  /**
   * Permite detectar si hay algún hadler del evento cardClick asignado.
   */
  get hasCardClickHandlers() {
    // console.log('🚧', this.clicked.observers.length);
    return this.cardClick.observers.length > 0;
  }

  constructor(
    private modalController: ModalController,
    private api: LaravelApiService,
    private currentRestaurantService: SessionService,
    private auth: AuthenticationService
  ) {
    this.auth.currentUserInstance$.subscribe((user) => {
      this.currentUserType = user?.data.regType;
    });
  }

  ngOnInit() {
    this.currentRestaurantService.restaurant$.subscribe((restaurant) => {
      this.restaurant = restaurant;
    });
  }

  ngOnDestroy(): void {}

  onCardClick() {
    const bookingId = this.booking?.id;
    // console.log('⭐ onCardClick()');

    if (!bookingId) {
      return;
    }

    this.cardClick.emit(bookingId);
  }

  onConfirmClick(ev: Event) {
    ev.stopPropagation();
    const bookingId = this.booking?.id;
    if (!bookingId) {
      return;
    }
    this.confirmClick.emit(bookingId);
  }
  onOrderStatusClick(ev: Event) {
    ev.stopPropagation();
  }
}
