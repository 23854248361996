// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-grid.not-black {
  background: #fff !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/products-filtering-modal/products-filtering-modal.component.scss"],"names":[],"mappings":"AAGA;EACE,2BAAA;AAFF","sourcesContent":[":host {\n  //\n}\nion-grid.not-black {\n  background: #fff !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
