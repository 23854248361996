import { Query } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import IFirebaseData from '~lib/database/firebase-data.interface';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';
import { DeliveryCharge, IDeliveryChargeData } from './delivery-charge';
import { IImageData } from './image-data.interface';
export interface IConfigurationData extends IFirebaseData {
  id: string | null;
  userId: string;
  teamId: string;
  squareimage: IImageData | null;
  bannerimage: IImageData | null;
  circleimage: IImageData | null;
  timeIntervals: {} | null;
  enabled: boolean | false;
  isOpen: string | null;
  city: string;
  mainCity: string;
  country: string;
  countryId: string;
  address: string;
  showhide: boolean | false;
  reordered: boolean | false;
  latitude: string | null;
  maximumRange: string | null;
  longitude: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  deletedAt: string | null;
}
/**
 * Representa un plan que agrupa módulos.
 */
export class Configuration extends Model<IConfigurationData> {
  public static type = 'configurations';

  constructor(data: Partial<IConfigurationData> = {}, id: string | null = null, parentPath?: string) {
    const defaultData: IConfigurationData = {
      id: null,
      userId: '',
      teamId: '',
      squareimage: null,
      bannerimage: null,
      circleimage: null,
      enabled: false,
      showhide: true,
      city: '',
      mainCity: '',
      country: '',
      countryId: '',
      address: '',
      isOpen: '',
      timeIntervals: null,
      latitude: '',
      longitude: '',
      reordered: true,
      maximumRange: '',
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
    };

    // Eliminar los ya predeterminados
    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
    return this.rawData;
  }

  private _deliveryCharge$: Observable<DeliveryCharge[]> | undefined;
  get deliveryCharges$(): Observable<DeliveryCharge[]> {
    if (!this._deliveryCharge$) {
      this._deliveryCharge$ = new DeliveryCharge()
        .odm()
        .collection((ref) => {
          let q: Query<IDeliveryChargeData> | undefined;
          const myCity = [
            this.data.mainCity,
            this.data.city,
          ];
          if (q) {
            q = q.where('city', 'in', myCity);
          } else {
            q = ref.where('city', 'in', myCity);
          }
          return q;
        })
        .get()
        .pipe(
          map((deliveryCharges) => {
            return deliveryCharges.docs.map((p) => {
              return DeliveryCharge.fromPayloadDocument(p);
            });
          })
        );
    }
    return this._deliveryCharge$;
  }
}
