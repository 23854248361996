// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: contents;
}
:host .block {
  display: block;
}
:host ion-card {
  margin: 0;
}
:host ion-card ion-grid {
  --ion-grid-column-padding: 5px;
}
:host .big-icon {
  font-size: 2.1em;
}
:host .ion-activatable {
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
:host ion-badge {
  border-radius: 15px;
  border: none;
  opacity: 1;
  box-shadow: 0px 0px 10px none;
  text-transform: inherit;
  font: normal normal 500 14px/18px Inter;
  color: #000;
  display: inline-flex;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  padding: 4px 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/booking-card-customer/booking-card-customer.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAAE;EACE,cAAA;AAEJ;AAAE;EACE,SAAA;AAEJ;AAAI;EACE,8BAAA;AAEN;AAEE;EACE,gBAAA;AAAJ;AAGE;EACE,gBAAA;EACA,kBAAA;EACA,eAAA;AADJ;AAIE;EACE,mBAAA;EACA,YAAA;EAEA,UAAA;EACA,6BAAA;EACA,uBAAA;EACA,uCAAA;EACA,WAAA;EACA,oBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,yBAAA;EACA,iBAAA;AAHJ","sourcesContent":[":host {\n  display: contents;\n  .block {\n    display: block;\n  }\n  ion-card {\n    margin: 0;\n\n    ion-grid {\n      --ion-grid-column-padding: 5px;\n    }\n  }\n\n  .big-icon {\n    font-size: 2.1em;\n  }\n\n  .ion-activatable {\n    overflow: hidden;\n    position: relative;\n    cursor: pointer;\n  }\n\n  ion-badge {\n    border-radius: 15px;\n    border: none;\n\n    opacity: 1;\n    box-shadow: 0px 0px 10px none;\n    text-transform: inherit;\n    font: normal normal 500 14px/18px Inter;\n    color: #000;\n    display: inline-flex;\n    font-size: 12px;\n    line-height: 18px;\n    letter-spacing: 0.06em;\n    text-transform: uppercase;\n    padding: 4px 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
