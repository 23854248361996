// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: contents;
  --context-column-size: 1.25em;
  --context-icon-size: calc(var(--context-column-size) - 4px);
  --ion-card-margin: 5px;
  --ion-card-padding: 4px;
}
:host > ion-card {
  margin: var(--ion-card-margin);
}
:host > ion-card ion-grid {
  --ion-grid-padding: 0;
  --ion-grid-column-padding: 0;
}
:host .context ion-card {
  margin: 0;
  height: 100%;
  border-radius: 0;
  width: var(--context-column-size);
}
:host .context ion-card fa-icon {
  font-size: var(--context-icon-size);
}
:host .context ion-card ion-grid {
  --ion-grid-padding: 0;
  --ion-grid-column-padding: 0;
  height: 100%;
}
:host .context ion-card ion-grid ion-row {
  height: 100%;
}
:host .content {
  padding: var(--ion-card-padding);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/context-card/context-card.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EAEA,6BAAA;EACA,2DAAA;EACA,sBAAA;EACA,uBAAA;AAAF;AAEE;EACE,8BAAA;AAAJ;AAEI;EACE,qBAAA;EACA,4BAAA;AAAN;AAKI;EACE,SAAA;EAEA,YAAA;EACA,gBAAA;EACA,iCAAA;AAJN;AAMM;EACE,mCAAA;AAJR;AAOM;EACE,qBAAA;EACA,4BAAA;EAEA,YAAA;AANR;AAQQ;EACE,YAAA;AANV;AAYE;EACE,gCAAA;AAVJ","sourcesContent":[":host {\n  display: contents;\n\n  --context-column-size: 1.25em;\n  --context-icon-size: calc(var(--context-column-size) - 4px);\n  --ion-card-margin: 5px;\n  --ion-card-padding: 4px;\n\n  & > ion-card {\n    margin: var(--ion-card-margin);\n\n    ion-grid {\n      --ion-grid-padding: 0;\n      --ion-grid-column-padding: 0;\n    }\n  }\n\n  .context {\n    ion-card {\n      margin: 0;\n\n      height: 100%;\n      border-radius: 0;\n      width: var(--context-column-size);\n\n      fa-icon {\n        font-size: var(--context-icon-size);\n      }\n\n      ion-grid {\n        --ion-grid-padding: 0;\n        --ion-grid-column-padding: 0;\n\n        height: 100%;\n\n        ion-row {\n          height: 100%;\n        }\n      }\n    }\n  }\n\n  .content {\n    padding: var(--ion-card-padding);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
