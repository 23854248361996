import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ColorScheme } from './ionic-theme-setting';
import { UserSettingsService } from './user-settings.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService implements OnDestroy {
  protected readonly systemColorSchemeSubject: BehaviorSubject<ColorScheme | null>;
  public readonly systemColorScheme$: Observable<ColorScheme | null>;

  /**
   * Representa el esquema de color del dispositivo del usuario.
   */
  protected readonly colorSchemeSubject: BehaviorSubject<ColorScheme | null | undefined>;

  /**
   * Si el valor es undefined, significa que aún no se ha inicializado (sucede cuando se cambia/inicializa el Restaurant).
   */
  public readonly colorScheme$: Observable<ColorScheme | null | undefined>;

  /**
   * Si el valor es undefined, significa que aún no se ha inicializado.
   */
  public get colorScheme(): ColorScheme | null | undefined {
    return this.colorSchemeSubject.value;
  }
  public set colorScheme(scheme: ColorScheme | null | undefined) {
    this.colorSchemeSubject.next(scheme);
  }

  public get isDark() {
    return this.colorScheme === 'dark';
  }

  public get isLight() {
    return this.colorScheme === 'light';
  }

  private readonly subscriptions = new Subscription();

  constructor(private userSettingsService: UserSettingsService) {
    this.colorSchemeSubject = new BehaviorSubject<ColorScheme | null | undefined>(undefined);
    this.colorScheme$ = this.colorSchemeSubject.asObservable().pipe(shareReplay(1));

    this.systemColorSchemeSubject = new BehaviorSubject<ColorScheme | null>(null);
    this.systemColorScheme$ = this.systemColorSchemeSubject.asObservable().pipe(shareReplay(1));

    try {
      this.systemColorSchemeSubject.next(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');

      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (ev) => {
        // TODO: Comprobar si realmente es un sistema binario ('dark'|'light)
        this.systemColorSchemeSubject.next(ev.matches ? 'dark' : 'light');
      });
    } catch (err) {
      console.error('Error on matching prefers-color-scheme', err);
    }

    const changesSubscription = combineLatest([
      this.systemColorScheme$,
      this.userSettingsService.colorScheme$,
    ]).subscribe(
      ([
        systemColorScheme,
        userColorScheme,
      ]) => {
        if (!userColorScheme || userColorScheme === 'system') {
          // Si el usuario no ha definido dark o light, se usa el valor del dispositivo
          this.colorSchemeSubject.next(systemColorScheme || 'dark');

          return;
        }

        // TODO: Manejar demás opciones como "auto" (según la hora) o programada de forma personalizada

        this.colorSchemeSubject.next(userColorScheme);
      }
    );

    this.subscriptions.add(changesSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
