import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { Observable, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { Order } from '~/database/models/order';
import { OrderDriver } from '~/database/models/order-driver';
import { OrderProduct } from '~/database/models/order-product';
import { IOrderStatusData, OrderStatus } from '~/database/models/order-status';
import { AuthenticationService } from '~shared/services/authentication.service';
import { LaravelApiService, UriRoute } from '~shared/services/laravel-api.service';
import { SessionService } from '~shared/services/session.service';
import { OrderProductsModalComponent } from '../order-products-modal/order-products-modal.component';
import { AddReviewData, RatingModalComponent } from '../rating-modal/rating-modal.component';
export type ExtraItem = { key: string; value: string };

@Component({
  selector: 'app-order-card',
  templateUrl: './order-card.component.html',
  styleUrls: ['./order-card.component.scss'],
})
export class OrderCardComponent implements OnInit, OnDestroy {
  #statuses: IOrderStatusData[] = [];
  //currentUserType:string | null | undefined;
  showButton: boolean = true;
  newTeamId: string | null | undefined;
  @Input()
  order: Order | undefined;
  @Input() currentUserId: string | null | undefined;
  @Input() customerUserId: string | null = null;
  @Input() currentUserType: string | null | undefined;
  @Input()
  products: OrderProduct[] = [];

  private _products$: Observable<OrderProduct[]> | undefined;
  faIcon: IconProp | undefined;
  @Input()
  set products$(products$: Observable<OrderProduct[]> | undefined) {
    if (this.productsSub && !this.productsSub.closed) {
      this.productsSub.unsubscribe();
    }

    if (products$) {
      this.productsSub = products$.subscribe((products) => {
        this.products = products;
      });
    } else {
      this.products = [];
    }

    this._products$ = products$;
  }
  get products$() {
    return this._products$;
  }

  private productsSub: Subscription | undefined;

  @Input()
  set statuses(statuses) {
    this.#statuses = statuses.sort((a, b) => {
      return a.value - a.value;
    });
  }
  get statuses() {
    return this.#statuses;
  }

  get status(): OrderStatus {
    const f = this.statuses.find((item) => {
      return item.id === this.order?.data.orderStatusId;
    }) || {
      slug: 'waiting',
      title: 'Waiting',
      value: 0,
      createdAt: null,
      description: null,
      id: null,
      updatedAt: null,
    };

    return new OrderStatus(f);
  }

  get progressValue() {
    if (this.status.value <= 6) {
      return this.status.value / 6;
    } else if (this.status.value > 12 && this.status.value < 16) {
      return this.status.value / 16;
    } else {
      return this.status.value / this.progressTotal;
    }
  }

  get progressTotal() {
    return this.statuses[this.statuses.length - 1].value;
  }

  get total() {
    let t = 0;

    this.products.forEach((item) => {
      let extra_total = 0;
      if (item.data.promoquantity) {
        t += item.data.promoquantity * +item.data.price;
      } else {
        t += item.data.quantity * +item.data.price;
      }
      if (item.data.paiditems) {
        let paidItems = item.data.paiditems;
        // Check if it's a double-escaped JSON string
        if (typeof paidItems === 'string') {
          paidItems = JSON.parse(paidItems);
        }
        if (Array.isArray(paidItems)) {
          paidItems.forEach((element: { key: string; value: string }) => {
            extra_total = extra_total + parseFloat(element.value);
          });
          t = t + extra_total;
        }
      }
    });

    return t;
  }

  @Output()
  public readonly billClick = new EventEmitter<string>();

  @Output()
  public readonly cardClick = new EventEmitter<string>();

  /**
   * Permite detectar si hay algún hadler del evento cardClick asignado.
   */
  get hasCardClickHandlers() {
    // console.log('🚧', this.clicked.observers.length);
    return this.cardClick.observers.length > 0;
  }

  constructor(
    private modalController: ModalController,
    private api: LaravelApiService,
    private currentSession: SessionService,
    private auth: AuthenticationService
  ) {}

  ngOnInit() {
    if (this.status.faIcon) {
      this.faIcon = this.status.faIcon;
    }

    this.auth.currentUserInstance$.subscribe((user) => {
      this.currentUserId = user?.id;
      this.currentUserType = user?.data.regType;
      // console.log('currentUserId:', this.currentUserId); // Add this line
      // console.log('currentUserType:', this.currentUserType); // Add this line
      // console.log('Order data:', this.order); // Add this line
    });
  }

  ngOnDestroy(): void {
    if (this.productsSub && !this.productsSub.closed) {
      this.productsSub.unsubscribe();
    }
  }

  public async showProductsModal(ev: Event) {
    ev.stopPropagation();

    const modal = await this.modalController.create({
      component: OrderProductsModalComponent,

      componentProps: {
        orderProducts: this.products,
      },
    });

    await modal.present();
  }

  onBillClick(ev: Event) {
    ev.stopPropagation();

    const billId = this.order?.data.billId;
    // console.log('⭐ onBillClick()', billId);

    if (!billId) {
      return;
    }

    this.billClick.emit(billId);
  }

  onCardClick() {
    const orderId = this.order?.id;
    // console.log('⭐ onCardClick()');

    if (!orderId) {
      return;
    }

    this.cardClick.emit(orderId);
  }

  onOrderStatusClick(ev: Event) {
    ev.stopPropagation();
  }

  public async ratingComponent(
    ev: Event,
    orderId: String | null | undefined,
    drivers$: OrderDriver | Observable<OrderDriver[]> | undefined
  ) {
    ev.stopPropagation();
    console.log('Rating component called');
    const modal = await this.modalController.create({
      component: RatingModalComponent,
      componentProps: {
        orderProducts: this.products,
        orderTeamId: this.order?.data.teamId,
        orderId: orderId,
        attendantId: this.order?.data.attendantId,
        driver: drivers$,
      },
    });

    await modal.present();

    const result: OverlayEventDetail<AddReviewData> = await modal.onDidDismiss();

    try {
      if (result.role === 'submit' && result.data) {
        this.newTeamId = this.order?.data.teamId;
        if (!this.newTeamId) {
          this.newTeamId = this.currentSession.restaurant?.id;
        }
        const uri = new UriRoute('orders/addreviews');
        const res = await this.api
          .post(uri, {
            userId: this.auth.currentUser?.uid,
            orderTeamId: this.newTeamId,
            driverId: result.data?.driverId,
            orderId: result.data?.orderId,
            befastercomments: result.data?.befastercomments,
            attendantcomments: result.data?.attendantcomments,
            productcomments: result.data?.productcomments,
            drivercomments: result.data?.drivercomments,
            driverRating: result.data?.driverRating,
            befasterRating: result.data?.befasterRating,
            attendantRating: result.data?.attendantRating,
            productRating: result.data?.productRating,
            attendantId: result.data?.attendantId,
          })
          .pipe(first())
          .toPromise();
        if (res) {
          console.log(res);
          this.showButton = false;
        }
      }
    } catch (err: any) {}
  }
}
