// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .image-icon img {
  margin-right: 25px;
  height: 15px;
}

::ng-deep .md .modal-default .hydrated .show-modal {
  height: 75%;
  width: 95%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/guest-cart-confirm-modal/guest-cart-confirm-modal.component.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,YAAA;AAAJ;;AAIA;EACE,WAAA;EACA,UAAA;AADF","sourcesContent":[":host {\n  .image-icon img {\n    margin-right: 25px;\n    height: 15px;\n  }\n}\n\n::ng-deep .md .modal-default .hydrated .show-modal {\n  height: 75%;\n  width: 95%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
