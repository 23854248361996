import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import IFirebaseData from '~lib/database/firebase-data.interface';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';
import { IJsonMap } from '~lib/types';
import { ColorScheme } from '~shared/services/ionic-theme-setting';
import { Address, IAddressData } from './address';
import { Order } from './order';
import { Setting } from './setting';
import { ISettingData } from './setting.data';
import { UserAddress } from './user-address';
import { IUserAddressData } from './user-address-data.interface';
import { IUserData } from './user.interface';

// TODO: Agregar otras opciones, como 'auto' o programado, similar a como tiene Telegram
export type ColorSchemePreference = ColorScheme | 'system';

const SETTINGS_MAP = {
  'app:color_scheme': '' as ColorSchemePreference,
  'app:language': '' as string,
} as const satisfies IJsonMap & IFirebaseData;

export type UserSettingKey = keyof typeof SETTINGS_MAP;
export type UserSettingValue<TKey extends UserSettingKey> = (typeof SETTINGS_MAP)[TKey] | null;

/**
 * Representa a un usuario.
 */
export class User extends Model<IUserData> {
  public static type = 'users';

  constructor(data: Partial<IUserData> = {}, id: string | null = null, parentPath?: string) {
    const defaultData: IUserData = {
      name: '',
      deletedAt: null,
      createdAt: null,
      email: null,
      id: null,
      profilePhotoUrl: null,
      updatedAt: null,
      gender: null,
      regType: null,
      openTableSession: null,
      phoneNumber: null,
      countryCode: null,
      userStatus: null,
    };

    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
    return this.rawData;
  }

  private _orders$: Observable<Order | null> | undefined;

  get orders(): Observable<Order | null> {
    if (!this._orders$) {
      this._orders$ = new Order({}, this.data.id)
        .odm()
        .doc()
        .get()
        .pipe(
          map((snapshot) => {
            const data = snapshot.data();

            if (!snapshot.exists || !data) {
              return null;
            }

            return new Order(data, this.data.id);
          })
        );
    }

    return this._orders$;
  }

  _userAddresses$: Observable<UserAddress[]> | undefined;
  // TODO: Cambiar user_addresses por addresses
  get userAddresses$(): Observable<UserAddress[]> {
    if (!this._userAddresses$) {
      this._userAddresses$ = this.odm()
        .child<IUserAddressData>('user_addresses')
        .snapshotChanges()
        .pipe(
          map((addresses) => {
            return addresses.map((address) => {
              return UserAddress.fromPayloadDocument(address.payload.doc);
            });
          })
        );
    }

    return this._userAddresses$;
  }
  _addresses$: Observable<Address[]> | undefined;
  // TODO: Cambiar user_addresses por addresses
  get addresses$(): Observable<Address[]> {
    if (!this._addresses$) {
      this._addresses$ = this.odm()
        .child<IAddressData>('addresses', (ref) => {
          return ref.where('deletedAt', '==', null).orderBy('isDefault', 'desc').orderBy('updatedAt', 'desc');
        })
        .snapshotChanges()
        .pipe(
          map((addresses) => {
            return addresses.map((address) => {
              return Address.fromPayloadDocument(address.payload.doc);
            });
          })
        );
    }
    return this._addresses$;
  }

  /**
   * Gets a stream for the specified setting key.
   */
  public getSetting$<
    TKey extends UserSettingKey = UserSettingKey,
    TValue extends UserSettingValue<TKey> = UserSettingValue<TKey>,
  >(key: TKey): Observable<Setting<TValue> | null> {
    return this.odm()
      .child<ISettingData<TValue>>('settings', (ref) => {
        return ref.where('key', '==', key);
      })
      .snapshotChanges()
      .pipe(
        map((settings) => {
          if (settings.length === 0) {
            return null;
          }

          return Setting.fromPayloadDocument(settings[0].payload.doc) as Setting<TValue>;
        })
      );
  }

  /* private _orders$: Observable<Order[]> | undefined;
  get orders$() {
    if (!this._orders$) {
      this._orders$ = this.getOrders$((ref) => {
        return ref.orderBy('updatedAt', 'desc');
      });
    }

    return this._orders$;
  }
  getOrders$(filters?: QueryFn<IOrderData>) {
    const orders$ = this.odm()
      .child<IOrderData>('orders', filters)
      .snapshotChanges()
      .pipe(
        map((snaps) => {
          return snaps.map(({ payload }) => {
            return Order.fromPayloadDocument(payload.doc);
          });
        })
      );
      console.log(orders$);
    return orders$;
  }*/
}
