import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, filter, first, switchMap, timeout } from 'rxjs/operators';
import { SessionService } from '~shared/services/session.service';

/**
 * Los módulos principales ('/', '/attendant', '/customer') no se deben proteger con este Guard.
 */
@Injectable({ providedIn: 'root' })
export class TableSelectedGuardService {
  constructor(
    protected restaurantService: SessionService,
    private router: Router
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.restaurantService.table) {
      return true;
    } else if (this.restaurantService.table === null) {
      return this.getRedirectionUrl(state);
    }

    const result = this.restaurantService.table$.pipe(
      filter((r) => r !== undefined),
      timeout(5000),
      first(),
      switchMap((r) => {
        // console.log(r);
        if (!r) {
          return of(this.getRedirectionUrl(state));
        }

        return of(true);
      }),
      catchError((err) => {
        // FIXME: Autenticar primero
        console.error('Error al capturar la mesa actual', err);
        return of(this.getRedirectionUrl(state));
      })
    );

    return result;
  }

  protected getRedirectionUrl(state: RouterStateSnapshot): UrlTree {
    const intendedUrlBase = state.url.replace(/^(\/|\s)+|(\s|\/)+$/g, '').split('/')[0] ?? '';
    let redirectUrl = '/';

    switch (intendedUrlBase) {
      default:
        redirectUrl = '/scan-qr';
    }

    return this.router.parseUrl(redirectUrl);
  }
}

export const TableSelectedGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  return inject(TableSelectedGuardService).canActivate(next, state);
};
