import { Setting } from '~/database/models/setting';
import IFirebaseData from '~lib/database/firebase-data.interface';
import { defaultedData } from '~lib/helpers';

export type ItemsLayout = 'list' | 'small-grid' | 'cards';

export interface IPosition extends IFirebaseData {
  /**
   * Indica si el elemento está ocultado o visible.
   */
  hidden: boolean;

  vertical: 'top' | 'bottom' | 'middle';

  horizontal: 'left' | 'right' | 'center';
}

export namespace IPosition {
  export const defaulted = (partialData: Partial<IPosition> = {}): IPosition => {
    const defaultData: IPosition = {
      hidden: false,
      vertical: 'bottom',
      horizontal: 'left',
    };

    return defaultedData(partialData, defaultData);
  };
}

export type ColumnsCount = 1 | 2 | 3 | 4 | 6 | 12;

export type BreakpointSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type ColumnsSize = 'auto' | '*' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface IResponsiveColumns extends Record<BreakpointSize, ColumnsCount> {}
export interface IResponsiveColumnsSizes extends Record<BreakpointSize, ColumnsSize> {}

export namespace IResponsiveColumns {
  export const defaulted = (partialData: Partial<IResponsiveColumns> = {}): IResponsiveColumns => {
    const defaultData: IResponsiveColumns = {
      xs: 1,
      sm: 1,
      md: 2,
      lg: 3,
      xl: 4,
    };

    return defaultedData(partialData, defaultData);
  };

  export const toColumnSizes = (columns: Partial<IResponsiveColumns>): IResponsiveColumnsSizes => {
    const TOTAL_COLUMNS = 12;
    return {
      xs: !columns.xs ? 'auto' : ((TOTAL_COLUMNS / columns.xs) as ColumnsSize),
      sm: !columns.sm ? 'auto' : ((TOTAL_COLUMNS / columns.sm) as ColumnsSize),
      md: !columns.md ? 'auto' : ((TOTAL_COLUMNS / columns.md) as ColumnsSize),
      lg: !columns.lg ? 'auto' : ((TOTAL_COLUMNS / columns.lg) as ColumnsSize),
      xl: !columns.xl ? 'auto' : ((TOTAL_COLUMNS / columns.xl) as ColumnsSize),
    };
  };
}

export namespace IResponsiveColumnsSizes {
  export const defaulted = (partialData: Partial<IResponsiveColumnsSizes> = {}): IResponsiveColumnsSizes => {
    const defaultData: IResponsiveColumnsSizes = IResponsiveColumns.toColumnSizes(IResponsiveColumns.defaulted());

    return defaultedData(partialData, defaultData);
  };
}

/**
 * Structure for Ionic Layout Settings.
 */
export interface IIonicLayoutSetting extends IFirebaseData {
  disabled: boolean;

  categoriesColumns: IResponsiveColumns;

  categoriesLayout: ItemsLayout;

  productsColumns: IResponsiveColumns;

  productsLayout: ItemsLayout;

  productLabelPosition: IPosition;

  categoryLabelPosition: IPosition;
}

export namespace IIonicLayoutSetting {
  export const KEY = 'restaurant:ionic_layout';

  export const valueIs = (value: unknown): value is IIonicLayoutSetting => {
    // TODO: Mejorar detección
    return typeof value === 'object';
  };

  export const settingIs = (setting: Setting<any>): setting is Setting<IIonicLayoutSetting> => {
    return setting.key === KEY && valueIs(setting.value);
  };

  export const defaultValue = (partialData: Partial<IIonicLayoutSetting> = {}): IIonicLayoutSetting => {
    const defaultData: IIonicLayoutSetting = {
      disabled: false,
      categoriesLayout: 'cards',
      productsLayout: 'cards',
      productLabelPosition: IPosition.defaulted(),
      categoryLabelPosition: IPosition.defaulted(),
      categoriesColumns: IResponsiveColumns.defaulted(partialData.categoriesColumns),
      productsColumns: IResponsiveColumns.defaulted(partialData.productsColumns),
    };

    const data = defaultedData(partialData, defaultData);

    data.productLabelPosition = IPosition.defaulted(data.productLabelPosition);
    data.categoryLabelPosition = IPosition.defaulted(data.categoryLabelPosition);

    return data;
  };
}
