// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-textarea {
  --background: var(--ion-color-light);
  --border-radius: 10px;
  --border-style: none;
  --padding-start: 25px;
  --padding-end: 25px;
  --highlight-height: none;
  margin-top: 10px;
  --highlight-color: none !important;
  --padding-bottom: 10px !important;
}

.has-focus.ion-valid.sc-ion-textarea-md-h,
.ion-touched.ion-invalid.sc-ion-textarea-md-h {
  --border-style: none !important;
  --border-width: 0px !important;
  border: none !important;
  outline: none !important;
  --highlight-color: none !important;
}

ion-label {
  color: var(--ion-text-color) !important;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/rating-modal/rating-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,qBAAA;EACA,oBAAA;EACA,qBAAA;EACA,mBAAA;EACA,wBAAA;EACA,gBAAA;EACA,kCAAA;EACA,iCAAA;AACF;;AAEA;;EAEE,+BAAA;EACA,8BAAA;EACA,uBAAA;EACA,wBAAA;EACA,kCAAA;AACF;;AACA;EACE,uCAAA;AAEF;;AAAA;EACE,aAAA;AAGF;;AADA;EACE,cAAA;AAIF","sourcesContent":["ion-textarea {\n  --background: var(--ion-color-light);\n  --border-radius: 10px;\n  --border-style: none;\n  --padding-start: 25px;\n  --padding-end: 25px;\n  --highlight-height: none;\n  margin-top: 10px;\n  --highlight-color: none !important;\n  --padding-bottom: 10px !important;\n}\n\n.has-focus.ion-valid.sc-ion-textarea-md-h,\n.ion-touched.ion-invalid.sc-ion-textarea-md-h {\n  --border-style: none !important;\n  --border-width: 0px !important;\n  border: none !important;\n  outline: none !important;\n  --highlight-color: none !important;\n}\nion-label {\n  color: var(--ion-text-color) !important;\n}\n.d-none {\n  display: none;\n}\n.d-block {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
