import { ApiForm } from '~lib/helpers/api-form';
import { IJsonMap } from '~lib/types';
import { UriRoute } from '~shared/services/laravel-api.service';

export interface CreateAddressFormData extends IJsonMap {
  city: string | null;
  collection: string | null;
  country_id: string | null;
  geo_point: { latitude: number; longitude: number } | null;
  is_default: boolean | false;
  postal_code: string | null;
  title: string | null;
  extraLines: string | null;
  reference: string | null;
}

/**
 * Formulario para crear un nuevo cliente y agregarlo a la sesión.
 */
export class CreateAddressForm extends ApiForm<CreateAddressFormData> {
  constructor(id: string, initialData: Partial<CreateAddressFormData> = {}) {
    super(
      {
        method: 'POST',
        uri: new UriRoute('users/{id}/addresses ', { id }),
      },
      initialData,
      [
        'city',
        'collection',
        'country_id',
        'geo_point',
        'is_default',
        'postal_code',
        'title',
        'extraLines',
        'reference',
      ]
    );
  }
}
