// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: contents;
}
:host .pending-customer::part(native) {
  color: var(--ion-color-warning);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/customer-item/customer-item.component.scss"],"names":[],"mappings":"AAAA;EAIE,iBAAA;AAFF;AADE;EACE,+BAAA;AAGJ","sourcesContent":[":host {\n  .pending-customer::part(native) {\n    color: var(--ion-color-warning);\n  }\n  display: contents;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
