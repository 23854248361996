import { QueryFn } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs/internal/Observable';
import { distinct, map, shareReplay } from 'rxjs/operators';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';
import { Category } from './category';
import { ICategoryData } from './category-data.interface';
import { Configuration, IConfigurationData } from './configuration';
import { ILegacyAddressData, LegacyAddress } from './legacy-address';
import { IPaymentOptionsData, PaymentOptions } from './payment_options';
import { Plan } from './plan';
import { IRestaurantData } from './restaurant-data.interface';
import { Setting } from './setting';
import { ISettingData } from './setting.data';
import { Table } from './table';
import { ITableData } from './table-data.interface';
import { ITableLocationData, TableLocation } from './table-locations';
import { TableSession } from './table-session';
import { ITableSessionData } from './table-session-data.interface';
import { IWebsiteData, Website } from './website';

export class Restaurant extends Model<IRestaurantData> {
  public static type = 'restaurants';

  constructor(data: Partial<IRestaurantData>, id: string | null = null, parentPath?: string) {
    const defaultData: IRestaurantData = {
      createdAt: null,
      name: '',
      type: 'restaurant',
      updatedAt: null,
      userId: '',
      personalTeam: false,
      id: null,
      planId: null,
      subscriptionId: null,
      planExpiresAt: null,
      planExpired: false,
      addressId: null,
      websiteId: null,
      restaurantRating: 0.0,
      restaurantRatingCount: 0.0,
    };

    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
    // console.log(this.rawData.id,this.rawData.restaurantRating)
    return this.rawData;
  }

  _tables$: Observable<Table[]> | undefined;

  get tables$(): Observable<Table[]> {
    if (!this._tables$) {
      this._tables$ = this.odm()
        .child<ITableData>('tables', (ref) => {
          return ref.orderBy('updatedAt', 'desc');
        })
        .snapshotChanges()
        .pipe(
          map((tablesSnap) => {
            return tablesSnap.map(({ payload }) => {
              return Table.fromPayloadDocument(payload.doc);
            });
          })
        );
    }

    return this._tables$;
  }

  private _categories$: Observable<Category[]> | undefined;

  /**
   * Stream de las categorías del restaurante.
   */
  get categories$(): Observable<Category[]> {
    if (!this._categories$) {
      this._categories$ = this.odm()
        .child<ICategoryData>('categories', (ref) => {
          return ref
            .where('deletedAt', '==', null)
            .where('enabled', 'in', [
              // Accept enabled values of true or 1
              true,
              1, // FIXME: Ajustar en el backend para siempre usar boolean
            ])
            .orderBy('order', 'asc');
        })
        .snapshotChanges()
        .pipe(
          map((snapshot) => {
            return snapshot.map(({ payload }) => {
              return Category.fromPayloadDocument(payload.doc);
            });
          }),
          shareReplay(1)
        );
    }

    return this._categories$;
  }

  _booking_tables$: Observable<Table[]> | undefined;

  get booking_tables$(): Observable<Table[]> {
    if (!this._booking_tables$) {
      this._booking_tables$ = this.odm()
        .child<ITableData>('tables', (ref) => {
          return ref.orderBy('seats', 'asc');
        })
        .snapshotChanges()
        .pipe(
          map((tablesSnap) => {
            return tablesSnap.map(({ payload }) => {
              return Table.fromPayloadDocument(payload.doc);
            });
          }),
          distinct((e) => this.data.seats)
        );
    }

    return this._booking_tables$;
  }

  private _plan$: Observable<Plan | null> | undefined;

  get plan$(): Observable<Plan | null> {
    if (!this._plan$) {
      this._plan$ = new Plan({}, this.data.planId)
        .odm()
        .doc()
        .snapshotChanges()
        .pipe(
          map((snap) => {
            return Plan.fromDocumentChange(snap.payload);
          })
        );
    }

    return this._plan$;
  }

  private _address$: Observable<LegacyAddress[] | null> | undefined;

  get address$(): Observable<LegacyAddress[] | null> {
    if (!this._address$) {
      this._address$ = this.odm()
        .child<ILegacyAddressData>('addresses', (ref) => {
          return ref.orderBy('updatedAt', 'desc');
        })
        .snapshotChanges()
        .pipe(
          map((snap) => {
            return snap.map(({ payload }) => {
              return LegacyAddress.fromPayloadDocument(payload.doc);
            });
          })
        );
    }

    return this._address$;
  }

  private _website$: Observable<Website[] | null> | undefined;

  get website$(): Observable<Website[] | null> {
    if (!this._website$) {
      this._website$ = this.odm()
        .child<IWebsiteData>('websites', (ref) => {
          return ref.orderBy('updatedAt', 'desc');
        })
        .snapshotChanges()
        .pipe(
          map((snap) => {
            return snap.map(({ payload }) => {
              return Website.fromPayloadDocument(payload.doc);
            });
          })
        );
    }

    return this._website$;
  }

  _configuration$: Observable<Configuration[]> | undefined;

  get configuration$(): Observable<Configuration[]> {
    if (!this._configuration$) {
      this._configuration$ = this.odm()
        .child<IConfigurationData>('configurations', (ref) => {
          return ref.orderBy('updatedAt', 'desc');
        })
        .snapshotChanges()
        .pipe(
          map((tablesSnap) => {
            return tablesSnap.map(({ payload }) => {
              return Configuration.fromPayloadDocument(payload.doc);
            });
          })
        );
    }

    return this._configuration$;
  }

  private _settings$: Observable<Setting[]> | undefined;

  get settings$(): Observable<Setting[]> {
    if (!this._settings$) {
      this._settings$ = this.odm()
        .child<ISettingData>('settings')
        .snapshotChanges()
        .pipe(
          map((settingSnap) => {
            return settingSnap.map(({ payload }) => {
              return Setting.fromPayloadDocument(payload.doc);
            });
          })
        );
    }

    return this._settings$;
  }

  _tableLocation$: Observable<TableLocation[]> | undefined;

  get tableLocation$(): Observable<TableLocation[]> {
    if (!this._tableLocation$) {
      this._tableLocation$ = this.odm()
        .child<ITableLocationData>('table_locations', (ref) => {
          return ref.orderBy('updatedAt', 'desc');
        })
        .snapshotChanges()
        .pipe(
          map((tablesSnap) => {
            return tablesSnap.map(({ payload }) => {
              return TableLocation.fromPayloadDocument(payload.doc);
            });
          })
        );
    }

    return this._tableLocation$;
  }
  _paymentOptions$: Observable<PaymentOptions[]> | undefined;

  get paymentOptions$(): Observable<PaymentOptions[]> {
    if (!this._paymentOptions$) {
      this._paymentOptions$ = this.odm()
        .child<IPaymentOptionsData>('payment_options', (ref) => {
          return ref.orderBy('updatedAt', 'desc');
        })
        .snapshotChanges()
        .pipe(
          map((tablesSnap) => {
            return tablesSnap.map(({ payload }) => {
              return PaymentOptions.fromPayloadDocument(payload.doc);
            });
          })
        );
    }

    return this._paymentOptions$;
  }

  /**
   * Obtiene un stream con las sesiones.
   */
  sessions$(ref?: QueryFn<ITableSessionData>) {
    return this.odm()
      .child<ITableSessionData>('table_sessions', ref)
      .snapshotChanges()
      .pipe(
        map((tablesSnap) => {
          return tablesSnap.map(({ payload }) => {
            return TableSession.fromPayloadDocument(payload.doc);
          });
        })
      );
  }

  /**
   * Obtiene un stream con los cambios hechos en las sesiones del restaurant.
   *
   * Permite construir una lista mutable de elementos según vayan cambiando.
   */
  public tableSessionsStateChanges$(ref?: QueryFn<ITableSessionData>) {
    return this.odm().child<ITableSessionData>('table_sessions', ref).stateChanges();
  }
}
