// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: contents;
}

/**
 * Este cambia su display a none cuando .can-go-back es presente (ver theme/utilities.scss).
 *
 * Si se usa el atributo \`standalone\`, no se aplica ese display y lo muestra de todos modos.
 * */`, "",{"version":3,"sources":["webpack://./src/app/shared/components/back-button-fallback/back-button-fallback.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;;;;IAAA","sourcesContent":[":host {\n  display: contents;\n}\n\n/**\n * Este cambia su display a none cuando .can-go-back es presente (ver theme/utilities.scss).\n *\n * Si se usa el atributo `standalone`, no se aplica ese display y lo muestra de todos modos.\n * */\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
