// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ion-item [slot=start] {
  font-size: 1.6em;
  margin-right: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/customer-registration/components/cart-popover/cart-popover.component.scss"],"names":[],"mappings":"AAEI;EACE,gBAAA;EACA,iBAAA;AADN","sourcesContent":[":host {\n  ion-item {\n    [slot='start'] {\n      font-size: 1.6em;\n      margin-right: 8px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
