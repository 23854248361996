import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import IFirebaseData from '~lib/database/firebase-data.interface';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';
export interface IPaymentOptionsData extends IFirebaseData {
  id: string | null;
  userId: string;
  teamId: string;
  paymentOptions: [];
  createdAt: string | null;
  updatedAt: string | null;
  deletedAt: string | null;
}
/**
 * Representa un plan que agrupa módulos.
 */
export class PaymentOptions extends Model<IPaymentOptionsData> {
  public static type = 'payment_options';

  constructor(data: Partial<IPaymentOptionsData> = {}, id: string | null = null, parentPath?: string) {
    const defaultData: IPaymentOptionsData = {
      id: null,
      userId: '',
      teamId: '',
      paymentOptions: [],
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
    };

    // Eliminar los ya predeterminados
    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
    return this.rawData;
  }
}
