import { animate, animateChild, query, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ModalController, PopoverController } from '@ionic/angular';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { CartPopoverComponent } from '~/customer-registration/components/cart-popover/cart-popover.component';
import { Product } from '~/database/models/product';
import { Restaurant } from '~/database/models/restaurant';
import { RestaurantThemeService } from '~shared/services/restaurant-theme.service';
import { Specification } from '../add-to-cart-modal/add-to-cart-modal.component';

export interface AddToCartCustomerData {
  quantity: number;
  price: number;
  product: Product;
  notes: string;
  offerApplicable: boolean;
  promocode: string;
  promoquantity: number;
  extras: string;
  paiditems: string;
  productPosition: number;
  teamId: string;
}

@Component({
  selector: 'app-modal [view="add-to-cart-customer"]',
  templateUrl: './add-to-cart-customer-modal.component.html',
  styleUrls: ['./add-to-cart-customer-modal.component.scss'],
  animations: [
    trigger('ngIfAnimation', [transition(':enter, :leave', [query('@*', animateChild())])]),
    trigger('panelInOut', [
      transition('void => *', [
        style({ transform: 'translateY(100%)' }),
        animate(800),
      ]),
      transition('* => void', [
        animate(800, style({ transform: 'translateY(100%)' })),
      ]),
    ]),
    trigger('easeInOut', [
      transition('void => *', [
        style({
          opacity: 0,
        }),
        animate(
          '500ms ease-in',
          style({
            opacity: 1,
          })
        ),
      ]),
      transition('* => void', [
        style({
          opacity: 1,
        }),
        animate(
          '500ms ease-in',
          style({
            opacity: 0,
          })
        ),
      ]),
    ]),
  ],
})
export class AddToCartCustomerModalComponent implements OnInit {
  adding = false;
  #quantity = 1;
  form: UntypedFormGroup;
  promotiondata: any;
  promotion: any;
  promocodeApplicable = false;
  allshow = false;
  promo_code = '';
  finalQuantity = 0;
  show: boolean = false;
  showDescription: boolean = false;
  allSpecification: Specification[] = [];
  selectedspec: string = '';
  allselectedSpec: { specIndex: string; value: string }[] = [];
  weekday = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  extras: any = [];
  paidItems: any = [];
  max_calcualtion: any = [];
  do_selected: any = [];
  result: any[] = [];
  extra_total: number = 0;

  selectedValuesMap: any[] = [];

  public get max() {
    // FIXME: También tomar en cuenta si ya este mismo producto ya está en el carrito para el stock.
    return this.product.availableStock;
  }

  requiredSelected: { specIndex: string; value: string }[] = [];
  requiredCount: number = 0;
  allRequiredSelected: boolean = false;
  selectedRequiredCount: number = 0;

  @Input()
  editing = false;

  @Input()
  isAttendant = false;

  @Input()
  public categories = '';

  @Input()
  public restaurant!: Restaurant;

  @Input()
  public usertype = '';

  @Input()
  public notes = '';

  @Input()
  public product!: Product;

  @Input()
  public extraitems: any = '';

  @Input()
  public allpaiditems: any = '';

  @Input()
  public productPosition = 0;

  @Input()
  set quantity(value) {
    if (value > this.max) {
      this.#quantity = this.max;
    }

    this.#quantity = value;
  }

  get quantity() {
    return this.#quantity;
  }

  get price() {
    return +this.product.data.price;
  }
  get newprice() {
    //  const promo = JSON.parse(JSON.stringify(this.promotiondata));
    this.promotion = this.product;
    const promo = this.promotion.rawData.promotionId;
    if (promo.type == 'percent') {
      return this.product.data.price - (this.product.data.price * promo.percentage) / 100;
    } else if (promo.type == 'amount') {
      return this.product.data.price - promo.discountAmount;
    } else {
      return +this.product.data.price;
    }
  }

  get total() {
    this.promotion = this.product;
    const pr = this.promotion.rawData.promotionId;

    const promo = _.mapKeys(pr, (v, k) => _.camelCase(k));

    // const promo = JSON.parse(JSON.stringify(this.promotiondata));
    if (this.promocodeApplicable) {
      if (promo.type == 'percent') {
        return (this.product.data.price - (this.product.data.price * promo.percentage) / 100) * this.quantity;
      }
      if (promo.type == 'amount') {
        return (this.product.data.price - promo.discountAmount) * this.quantity;
      }
      if (promo.type == 'quantity') {
        if (this.quantity == promo.firstQuantity) {
          this.finalQuantity = promo.secondQuantity;

          return this.product.data.price * promo.secondQuantity;
        } else if (this.quantity > promo.firstQuantity) {
          const remainder = this.quantity % promo.firstQuantity;
          const quotient = Math.round((this.quantity - remainder) / promo.firstQuantity);
          this.finalQuantity = quotient * promo.secondQuantity + remainder;

          return this.product.data.price * this.finalQuantity;
        } else {
          this.finalQuantity = this.quantity;

          return this.product.data.price * this.finalQuantity;
        }
      }
    } else {
      return this.price * this.quantity;
    }
  }

  constructor(
    public modalController: ModalController,
    public popoverController: PopoverController,
    private cdr: ChangeDetectorRef
  ) {
    this.form = new UntypedFormGroup({
      notes: new UntypedFormControl(),
    });
  }

  ngOnInit() {
    console.log(this.product.data.price);
  }

  async ionViewDidEnter() {
    if (this.product?.data?.specifications) {
      this.allSpecification = JSON.parse(this.product.data.specifications);
      console.log(this.allSpecification);
    }
    if (this.extraitems && this.allSpecification.length > 0) {
      this.extras = JSON.parse(this.extraitems);
    }
    if (this.allpaiditems && this.allSpecification.length > 0) {
      this.paidItems = JSON.parse(this.allpaiditems);
      let newtotal = 0;
      JSON.parse(this.allpaiditems).forEach((element: any) => {
        newtotal = newtotal + parseFloat(element.value);
      });

      this.extra_total = newtotal;
    }
    if ((this.extraitems || this.paidItems) && this.allSpecification.length > 0) {
      let indexToBeChecked = 0; // Initialize index counter
      let selectedItem: any = 0;
      this.allSpecification.forEach((spec: any, index: any) => {
        if (spec.required) {
          let getextractedSpec = this.extractNameFromJson(spec.required, spec.values, spec.myname, spec.enabled);
          console.log(getextractedSpec);
          this.extras.forEach((extra: any) => {
            if (getextractedSpec) {
              const getindex = getextractedSpec.findIndex((item) => item === extra.value);
              if (getindex != -1) {
                this.allselectedSpec[index] = extra.value;
              }
            }
          });
        }

        if (!spec.required) {
          let typeKey: any = `type_${indexToBeChecked + 1}`;
          console.log(typeKey);

          if (!this.result[typeKey]) this.result[typeKey] = [];

          if (spec.enabled) {
            let getextractedAray = this.extractNameFromJson(spec.required, spec.values, spec.myname, spec.enabled);
            console.log(getextractedAray);

            this.extras.forEach((extra: any) => {
              if (getextractedAray) {
                const getindex = getextractedAray.findIndex((item) => item === extra.value);
                if (getindex != -1) {
                  this.result[typeKey].push(getindex);
                  if (this.max_calcualtion[typeKey]) {
                    this.max_calcualtion[typeKey] = {
                      max: spec.number,
                      selected: parseInt(this.max_calcualtion[typeKey].selected) + 1,
                    };
                  } else {
                    this.max_calcualtion[typeKey] = { max: spec.number, selected: parseInt(selectedItem) + 1 };
                  }
                }
              }
            });
            if (this.max_calcualtion[typeKey]?.selected >= Number(this.max_calcualtion[typeKey]?.max)) {
              for (let i = 0; i < spec.count; i++) {
                if (!this.result[typeKey].includes(i)) {
                  this.do_selected[typeKey + '_' + i] = true;
                }
              }
            } else {
              for (let i = 0; i < spec.count; i++) {
                if (this.result[typeKey] && !this.result[typeKey].includes(i)) {
                  this.do_selected[typeKey + '_' + i] = false;
                }
              }
            }
          }
          if (!spec.enabled) {
            let getextractedAray1 = this.extractNameFromJson(spec.required, spec.values, spec.myname, spec.enabled);
            console.log(getextractedAray1);

            this.paidItems.forEach((element: any) => {
              if (getextractedAray1) {
                const keyToFind = element.key; // The key you want to find
                const keys = Object.keys(getextractedAray1);

                const getindex11 = keys.indexOf(keyToFind);

                console.log(getindex11);

                if (getindex11 != -1) {
                  this.result[typeKey].push(getindex11);
                }
              }
            });
          }

          // Increment the index for the next occurrence
          indexToBeChecked++;
        }
      });
      console.log(this.allselectedSpec);
    }

    this.promotiondata = JSON.parse(JSON.stringify(this.product.data.promotionId));
    this.promotion = this.product;
    const promo1 = this.promotion.rawData.promotionId;

    if (promo1 && promo1.code != '' && promo1.code != undefined) {
      this.promo_code = promo1.label;
      const array = JSON.parse(promo1.time);
      const d = new Date();
      const currentDay = this.weekday[d.getDay()];
      const currentTime = d.getHours() + ':' + d.getMinutes();
      this.promocodeApplicable = false;
      array.forEach((value: any) => {
        const start_time = value.Start.hours + ':' + value.Start.minutes;
        const end_time = value.End.hours + ':' + value.End.minutes;
        if (
          currentDay == value.Day &&
          this.compareTime(start_time, currentTime) >= 0 &&
          this.compareTime(currentTime, end_time) >= 0
        ) {
          this.promocodeApplicable = true;
        }
      });
    }
    if (promo1 && promo1.code == 'undefined') {
      this.promocodeApplicable = false;
    }

    this.requiredCount = this.allSpecification.filter((spec) => spec.required).length;

    if (this.extraitems || this.paidItems) {
      let indexToBeChecked = 0; // Initialize index counter
      let selectedItem: any = 0;
      this.allSpecification.forEach((spec: any, index: any) => {
        if (spec.required) {
          let getextractedSpec = this.extractNameFromJson(spec.required, spec.values, spec.myname, spec.enabled);
          console.log(getextractedSpec);
          this.extras.forEach((extra: any) => {
            if (getextractedSpec) {
              const getindex = getextractedSpec.findIndex((item) => item === extra.value);
              if (getindex != -1) {
                this.allselectedSpec[index] = extra.value;
              }
            }
          });
        }

        if (!spec.required) {
          let typeKey: any = `type_${indexToBeChecked + 1}`;
          console.log(typeKey);

          if (!this.result[typeKey]) this.result[typeKey] = [];

          if (spec.enabled) {
            let getextractedAray = this.extractNameFromJson(spec.required, spec.values, spec.myname, spec.enabled);
            console.log(getextractedAray);

            this.extras.forEach((extra: any) => {
              if (getextractedAray) {
                const getindex = getextractedAray.findIndex((item) => item === extra.value);
                if (getindex != -1) {
                  this.result[typeKey].push(getindex);
                  if (this.max_calcualtion[typeKey]) {
                    this.max_calcualtion[typeKey] = {
                      max: spec.number,
                      selected: parseInt(this.max_calcualtion[typeKey].selected) + 1,
                    };
                  } else {
                    this.max_calcualtion[typeKey] = { max: spec.number, selected: parseInt(selectedItem) + 1 };
                  }
                }
              }
            });
            if (this.max_calcualtion[typeKey]?.selected >= Number(this.max_calcualtion[typeKey]?.max)) {
              for (let i = 0; i < spec.count; i++) {
                if (!this.result[typeKey].includes(i)) {
                  this.do_selected[typeKey + '_' + i] = true;
                }
              }
            } else {
              for (let i = 0; i < spec.count; i++) {
                if (this.result[typeKey] && !this.result[typeKey].includes(i)) {
                  this.do_selected[typeKey + '_' + i] = false;
                }
              }
            }
          }
          if (!spec.enabled) {
            let getextractedAray1 = this.extractNameFromJson(spec.required, spec.values, spec.myname, spec.enabled);
            console.log(getextractedAray1);

            this.paidItems.forEach((element: any) => {
              if (getextractedAray1) {
                const keyToFind = element.key; // The key you want to find
                const keys = Object.keys(getextractedAray1);

                const getindex11 = keys.indexOf(keyToFind);

                console.log(getindex11);

                if (getindex11 != -1) {
                  this.result[typeKey].push(getindex11);
                }
              }
            });
          }

          // Increment the index for the next occurrence
          indexToBeChecked++;
        }
      });
      console.log(this.allselectedSpec);
    }
  }

  public dismiss() {
    this.modalController.dismiss(null, 'cancel');
  }

  public confirm() {
    if (!this.isButtonDisabled()) {
      const searchValue = this.form.controls.notes.value;
      this.notes = searchValue;

      if (this.promocodeApplicable) {
        const data: AddToCartCustomerData = {
          quantity: this.quantity,
          price: this.newprice,
          product: this.product,
          notes: searchValue,
          offerApplicable: this.promocodeApplicable,
          promocode: this.promo_code,
          promoquantity: this.finalQuantity,
          extras: this.extras,
          paiditems: this.paidItems,
          productPosition: this.productPosition,
          teamId: this.usertype && this.usertype == 'guest' ? this.restaurant.data.name : '',
        };

        this.modalController.dismiss(data, 'submit');
      } else {
        console.log('elsepromocodeApplicable');
        const data: AddToCartCustomerData = {
          quantity: this.quantity,
          price: this.price,
          product: this.product,
          notes: searchValue,
          offerApplicable: this.promocodeApplicable,
          promocode: '',
          promoquantity: 0,
          extras: this.extras,
          paiditems: this.paidItems,
          productPosition: this.productPosition,
          teamId: this.usertype && this.usertype == 'guest' ? this.restaurant.data.name : '',
        };

        this.modalController.dismiss(data, 'submit');
      }
    }
  }
  isButtonDisabled(): boolean {
    if (this.adding) {
      return this.requiredCount > 0 && !this.allRequiredSelected;
    } else {
      return false;
    }
  }
  toggleAdd() {
    this.adding = !this.adding;
    this.show = !this.show;
  }
  setRequiredItems(event: any, specId: string) {
    const selectedOption = { specIndex: specId, value: event.target.value };

    const index = this.requiredSelected.findIndex((item) => item.specIndex === specId);

    if (index !== -1) {
      this.requiredSelected[index] = selectedOption;
    } else {
      this.requiredSelected.push(selectedOption);
    }

    // Check if all required options are selected
    this.allRequiredSelected = this.requiredSelected.length === this.requiredCount;

    // Append the requiredSelected options to extras and only show the value, not the key
    this.requiredSelected.forEach((item) => {
      const indexToUpdate = this.extras.findIndex((extra: any) => extra.key === item.specIndex);
      if (indexToUpdate >= 0) {
        this.extras[indexToUpdate] = { key: item.specIndex, value: item.value };
      } else {
        this.extras.push({ key: item.specIndex, value: item.value });
      }
    });

    // this.allselectedSpec[0] = event.target.value
  }
  setFreeItems(event: any, maximum_number: number, totalItems: number | undefined) {
    let selectedtype = event.target.title;
    var s1 = selectedtype.substr(0, selectedtype.lastIndexOf('_'));
    var index = selectedtype.lastIndexOf('_');

    if (event.target.checked == true) {
      if (!this.result[s1]) this.result[s1] = [];

      if (this.result[s1].indexOf(Number(selectedtype.substr(index + 1))) == -1) {
        this.result[s1].push(Number(selectedtype.substr(index + 1)));
      }
    } else {
      if (
        this.result &&
        Array.isArray(this.result[s1]) &&
        this.result[s1].indexOf(Number(selectedtype.substr(index + 1))) >= 0
      ) {
        this.result[s1].splice(this.result[s1].indexOf(Number(selectedtype.substr(index + 1))), 1);
      }
    }
    console.log(this.result);
    if (this.max_calcualtion[s1]) {
      if (event.target.checked == true) {
        this.max_calcualtion[s1] = { max: maximum_number, selected: parseInt(this.max_calcualtion[s1].selected) + 1 };
      } else {
        this.max_calcualtion[s1] = { max: maximum_number, selected: parseInt(this.max_calcualtion[s1].selected) - 1 };
      }
    } else {
      this.max_calcualtion[s1] = { max: maximum_number, selected: 1 };
    }

    console.log('Total items:', totalItems); // Add this line

    if (this.max_calcualtion[s1]?.selected >= Number(this.max_calcualtion[s1]?.max)) {
      if (totalItems) {
        for (let i = 0; i < totalItems; i++) {
          if (!this.result[s1].includes(i)) {
            this.do_selected[s1 + '_' + i] = true;
          }
        }
      }
    } else {
      if (totalItems) {
        for (let i = 0; i < totalItems; i++) {
          if (this.result[s1] && !this.result[s1].includes(i)) {
            this.do_selected[s1 + '_' + i] = false;
          }
        }
      }
    }

    if (event.target.dataset.free == 'no' || event.target.dataset.free == 'yes') {
      let indexToUpdate = this.extras.findIndex((item: any) => item.key === event.target.name);

      if (indexToUpdate >= 0) {
        if (!event.target.checked) {
          // Unchecked, remove the item
          this.extras.splice(indexToUpdate, 1);
        } else {
          // Checked, update the item
          this.extras[indexToUpdate] = { key: event.target.name, value: event.target.value };
        }
      } else {
        // Not found, add a new item
        let collectedData = { key: event.target.name, value: event.target.value };
        this.extras.push(collectedData);
      }
    }

    if (event.target.dataset.free == 'no') {
      let indexToUpdate1 = this.paidItems.findIndex((item: any) => item.key === event.target.name);

      if (indexToUpdate1 >= 0) {
        if (!event.target.checked) {
          // Unchecked, remove the item
          this.extra_total -= parseFloat(event.target.value);
          this.paidItems.splice(indexToUpdate1, 1);
        } else {
          // Checked, update the item
          this.paidItems[indexToUpdate1] = { key: event.target.name, value: event.target.value };
        }
      } else {
        // Not found, add a new item
        let collectedData1 = { key: event.target.name, value: event.target.value };
        this.extra_total += parseFloat(event.target.value);
        this.paidItems.push(collectedData1);
      }
    }
    console.log(this.extras);
  }
  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: CartPopoverComponent,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  showDescriptionArea() {
    this.showDescription = !this.showDescription;
  }
  add_zero(num: number): string {
    if (num < 9) return '0o' + num;
    else return '' + num;
  }
  compareTime(str2: string, str1: string) {
    if (str1 === str2) {
      return 0;
    }
    var time1 = str1.split(':');
    var time2 = str2.split(':');
    if (eval(time1[0]) > eval(time2[0])) {
      return 1;
    } else if (eval(time1[0]) == eval(time2[0]) && eval(time1[1]) >= eval(time2[1])) {
      return 1;
    } else {
      return -1;
    }
  }
  extractNameFromJson(
    required: boolean,
    obj: string,
    obj2?: string, // obj2 is optional because is only used when required is false and free is false
    free?: boolean // free is optional because is only used when required is false
  ) {
    if (required == true) {
      let array = [];
      array = Object.values(obj);
      return array;
    }
    if (required == false && free == true) {
      let array = [];
      array = Object.values(obj);
      return array;
    }
    if (required == false && free == false && obj2) {
      let combineArray: any[] = [];
      let array = [];
      array = Object.values(obj2);
      let array2: any = [];
      array2 = Object.values(obj);
      array.forEach((element, key) => {
        let key2 = array2[key];
        combineArray[key2] = element;
      });
      return combineArray;
    }
  }
  getmax_calcualtion_value(i: number) {
    if (
      this.max_calcualtion['type_' + i]?.selected != undefined &&
      this.max_calcualtion['type_' + i]?.max != undefined
    ) {
      if (
        this.max_calcualtion['type_' + i]?.selected &&
        this.max_calcualtion['type_' + i]?.selected >= this.max_calcualtion['type_' + i]?.max
      ) {
        return false;
      }
    }
    return true;
  }
  checkvalue(key: any, value: any, i: number, totalItems: number | undefined, max?: number, j?: number) {
    if (this.extraitems) {
      var indexToUpdate = JSON.parse(this.extraitems).findIndex((item: any) => item.key === key);
      if (indexToUpdate >= 0) {
        return true;
      }
    }
  }
  // radiovalue(key:any,value:any,indexkey:number){
  //   if(this.extraitems){
  //    var indexToUpdate = JSON.parse(this.extraitems).findIndex((item:any) =>item.value == value );
  //    if(indexToUpdate >= 0) {

  //       this.allselectedSpec[indexkey] = value;

  //       // Clear all other selections in this radio group
  //       const radioGroup = document.getElementById('mygroup_' + indexkey);
  //       if (radioGroup) {
  //         const radioButtons = radioGroup.querySelectorAll('ion-radio');
  //         radioButtons.forEach((radioButton: any) => {
  //           radioButton.checked = radioButton.value === value;
  //         });
  //       }

  //       this.cdr.detectChanges();
  //    }
  //   }

  //  }
  public toggle(event: any) {
    if (event.target.classList.contains('selected')) {
      event.target.classList.remove('selected');
      event.target.parentElement.classList.remove('selected');
    } else {
      event.target.classList.add('selected');
      event.target.parentElement.classList.add('selected');
    }
    // console.log(event.target);
  }

  public increaseQuantity(quantity = 1) {
    if (this.quantity <= this.max - quantity) {
      this.quantity += quantity;
    }
  }

  public decreaseQuantity(quantity = 1) {
    if (this.quantity > 1) {
      this.quantity -= quantity;
    }
  }
}
