import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { RestaurantCurrencyService } from '~shared/services/currency.service';
import { LocaleService } from '~shared/services/locale.service';

const CURRENCY_SYMBOL_MAP: { [key: string]: string } = {
  VED: 'Bs. ',
  CLP: '$',
  CHF: 'Fr',
  USD: '$',
  EUR: '€',
};

@Pipe({
  name: 'dynamicCurrency',
})
export class DynamicCurrencyPipe implements PipeTransform {
  private currentLocale: string | undefined;

  constructor(
    private currencyPipe: CurrencyPipe,
    private currencyService: RestaurantCurrencyService,
    private localeService: LocaleService
  ) {
    localeService.localeSubject$.subscribe((locale) => {
      this.currentLocale = locale;
    });
  }

  transform(
    value: number | string,
    currencyCode?: string,
    display: 'code' | 'symbol' | 'symbol-narrow' | string | boolean = 'symbol',
    digitsInfo?: string,
    locale?: string
  ): Observable<string | null> {
    if (currencyCode) {
      // If a currency code is provided directly, use it
      const symbol = CURRENCY_SYMBOL_MAP[currencyCode] || currencyCode; // Use mapped symbol or default to currency code
      return new Observable<string | null>((observer) => {
        if (typeof value === 'string') {
          value = Number(value);
        }
        observer.next(this.currencyPipe.transform(value, currencyCode, symbol, digitsInfo, this.currentLocale));
        observer.complete();
      });
    }

    return this.currencyService.currencySetting$.pipe(
      map((setting) => {
        if (!setting || !setting.data.value) {
          return 'USD'; // Return a default value if the setting is not initialized or null
        }

        if (typeof setting.data.value === 'string') {
          return setting.data.value;
        } else {
          return 'USD'; // Default value
        }
      }),
      switchMap((currencyCodeFromService) => {
        const symbol = CURRENCY_SYMBOL_MAP[currencyCodeFromService] || currencyCodeFromService; // Use mapped symbol or default to currency code
        return new Observable<string | null>((observer) => {
          if (typeof value === 'string') {
            value = Number(value);
          }
          observer.next(
            this.currencyPipe.transform(value, currencyCodeFromService, symbol, digitsInfo, this.currentLocale)
          );
          observer.complete();
        });
      })
    );
  }
}
