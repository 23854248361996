import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { AlertController, NavController } from '@ionic/angular';
import { IonSelectCustomEvent, SelectChangeEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import 'firebase/compat/auth';
import { Observable, Subscription } from 'rxjs';
import { filter, first, map, share, take } from 'rxjs/operators';
import { register } from 'swiper/element/bundle';
import { ITableData } from '~/database/models/table-data.interface';
import { TableSession } from '~/database/models/table-session';
import { ITableSessionData } from '~/database/models/table-session-data.interface';
import { runInZone } from '~lib/helpers/rxjs-operators';
import { AuthenticationService } from '~shared/services/authentication.service';
import { GoogleMapLoaderService } from '~shared/services/google-map-loader.service';
import {
  ILaravelApiCollectionResult,
  ILaravelApiSingleResult,
  LaravelApiService,
  UriRoute,
  resultIsCollection,
} from '~shared/services/laravel-api.service';
import { LocaleService } from '~shared/services/locale.service';
import { RestaurantThemeService } from '~shared/services/restaurant-theme.service';
import { SessionService } from '~shared/services/session.service';
import { ThemeService } from '~shared/services/theme.service';
import { UserSettingForm } from '~shared/services/user-setting.form';
import { UserSettingsService } from '~shared/services/user-settings.service';
import { Restaurant } from './database/models/restaurant';
import { IRole } from './database/models/role.interface';
import { User, UserSettingValue } from './database/models/user';

const convertResultToInstanceForTable = (
  result: ILaravelApiSingleResult<ITableSessionData> | ILaravelApiCollectionResult<ITableSessionData>
) => {
  if (resultIsCollection(result)) {
    return result.data.map((tableData) => {
      return new TableSession(tableData, tableData.id);
    });
  }

  throw new Error('Invalid response');
};

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  faCoffee = faCoffee;
  public restaurant$: Observable<Restaurant | null | undefined>;
  currentUserRole: IRole | null | undefined;
  showButton = true;
  showthisButton = true;
  activeTableSession: string | undefined;
  showQRButton = true;

  public get isDark() {
    return this.theme.isDark;
  }

  selectedLanguage: 'en' | 'es' | string | undefined;

  public currentUser: User | null | undefined;
  public openSession: any | null | undefined;
  protected readonly subscriptions = new Subscription();
  tablesSession$: Observable<TableSession[]> | undefined | null;

  protected colorSchemeSettingForm: UserSettingForm | undefined;

  constructor(
    private auth: AuthenticationService,
    private restaurantService: SessionService,
    private navCtrl: NavController,
    private fireAuth: AngularFireAuth,
    private alertCtrl: AlertController,
    private router: Router,
    private zone: NgZone,
    public theme: ThemeService,
    private api: LaravelApiService,
    private translate: TranslateService,

    private ref: ChangeDetectorRef,
    private googleMapsLoader: GoogleMapLoaderService,
    private locale: LocaleService,

    public userSettingsService: UserSettingsService
  ) {
    this.restaurant$ = restaurantService.restaurant$;
    const userRoleSub = this.auth.role$.subscribe((role) => {
      this.currentUserRole = role;

      if (!this.auth.currentUser?.uid) {
        console.error('No user id');
        return;
      }
      this.tablesSession$ = this.api
        .get<ITableSessionData>(new UriRoute('list-current-table/{userid}', { userid: this.auth.currentUser.uid }))
        .pipe(share(), map(convertResultToInstanceForTable), runInZone(this.zone));

      this.tablesSession$.subscribe((table) => {
        if (table) {
          this.activeTableSession = table[0]?.data.tableId;
        }
      });
    });
    this.subscriptions.add(userRoleSub);
  }

  async showAlert() {
    const userdata = await this.auth.currentUserInstance$
      .pipe(
        filter((s) => s !== undefined),
        first()
      )
      .toPromise();
    console.log(userdata?.data.id);
    const alert = await this.alertCtrl.create({
      header: 'Access Table Session',
      cssClass: 'custom-alert',
      message:
        ' You have an active table session you can access the table session in the table session area or click yes to go to your table session',
      buttons: [
        {
          text: 'Yes',
          cssClass: 'alert-button-confirm',
          handler: async () => {
            await this.navCtrl.navigateRoot('/regcustomer/table/' + userdata?.data.openTableSession);
          },
        },
        {
          text: 'Not now',
          cssClass: 'alert-button-cancel',
          role: 'dismiss',
          handler: async () => {
            console.log('Not Now');
          },
        },
      ],
    });
    //await alert.present();
  }

  public ngOnInit() {
    this.initializeApp();
    this.initTranslate();
    this.googleMapsLoader
      .load()
      .then(() => {
        console.log('Google Maps script loaded');
      })
      .catch((error) => {
        console.error('Error loading Google Maps:', error);
      });

    const colorSchemeSub = this.theme.colorScheme$.subscribe((newColorScheme) => {
      const shouldUseDark = !newColorScheme || newColorScheme === 'dark'; // Por defecto dark si no está definido aún.

      // Ionic < 8
      document.body.classList.toggle('dark', shouldUseDark); // TODO: Remover al actualizar a Ionic 8.

      // Ionic >= 8
      document.documentElement.classList.toggle('ion-palette-dark', shouldUseDark);
    });

    this.subscriptions.add(colorSchemeSub);

    const userSub = this.auth.currentUserInstance$.subscribe((user) => {
      if (!!user) {
        this.showButton = false;

        if (user.id && !this.colorSchemeSettingForm) {
          this.colorSchemeSettingForm = new UserSettingForm(user.id, 'app:color_scheme');
        }
      }

      this.currentUser = user;
    });

    this.subscriptions.add(userSub);

    const settingsSub = this.userSettingsService.colorScheme$.subscribe((colorScheme) => {
      if (!this.colorSchemeSettingForm) {
        if (!this.currentUser?.id) {
          return;
        }

        this.colorSchemeSettingForm = new UserSettingForm(this.currentUser.id, 'app:color_scheme', {
          value: colorScheme,
        });
      } else {
        this.colorSchemeSettingForm.patchValue({ value: colorScheme });
      }
    });

    this.subscriptions.add(settingsSub);

    // new Feature()
    //   .odm()
    //   .collection((q) => {
    //     return q.orderBy('slug', 'asc');
    //   })
    //   .get()
    //   .subscribe((r) => {
    //     console.log(r.docs.map((m) => m.data()).map((m) => m.slug));
    //   });
  }
  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  protected initializeApp() {
    // Configurar deep links
    // https://capacitorjs.com/docs/guides/deep-links#deep-link-routing-using-the-capacitor-app-api
    App.addListener('appUrlOpen', (data) => {
      this.zone.run(() => {
        // console.log('Navegando', data.url);
        const slug = data.url.split('.skyliner.cl').pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
  }

  async initTranslate() {
    this.changeLanguage('es');
    // const deviceLocale = (await Device.getLanguageCode()).value;

    // // console.log(deviceLocale);

    // if (deviceLocale.startsWith('es')) {
    //   this.changeLanguage('es');
    // } else {
    //   this.changeLanguage('en');
    // }
  }

  onSelectedLanguage(event: Event) {
    const { value } = (event as CustomEvent<{ value: string }>).detail;

    this.changeLanguage(value);
  }

  changeLanguage(lang: 'en' | 'es' | string) {
    if (this.selectedLanguage === undefined) {
      this.selectedLanguage = lang;
    }
    // console.log(lang);
    return this.translate.use(lang) && this.locale.setCurrentLang(lang);
  }

  protected async onSelectedColorSchemePreference(
    ev: IonSelectCustomEvent<SelectChangeEventDetail<UserSettingValue<'app:color_scheme'>>>
  ): Promise<void> {
    if (!this.colorSchemeSettingForm) {
      return;
    }

    const value = ev.detail.value;
    if (value) {
      await this.colorSchemeSettingForm.submit();
    }
    // console.log('onSelectedColorSchemePreference', ev);
  }

  async signOut() {
    await this.auth.signOut();
    await this.fireAuth.signOut();
    this.router.navigate(['/']);
    setTimeout(() => {
      window.location.reload();
    }, 600);
  }

  async redirectAndGoTo(path: string) {
    const alert = await this.alertCtrl.create({
      header: 'Log In',
      cssClass: 'custom-alert',
      message:
        'Are you sure you want to leave current session ? if you proceed your current session will be lost. you need to create a new cart but your order will be sync.',
      buttons: [
        {
          text: 'Yes',
          cssClass: 'alert-button-confirm',
          handler: async () => {
            await this.auth.signOut();
            await this.goTo(path, 'root');
          },
        },
        {
          text: 'Not now',
          cssClass: 'alert-button-cancel',
          role: 'dismiss',
          handler: async () => {
            console.log('Not Now');
          },
        },
      ],
    });
    await alert.present();
  }

  async leaveTable(tableId: string) {
    const alert = await this.alertCtrl.create({
      header: 'Leave Table',
      cssClass: 'custom-alert',
      message: 'Would you like to leave table right now?',
      buttons: [
        {
          text: 'Yes',
          cssClass: 'alert-button-confirm',
          handler: async () => {
            const uri = new UriRoute('table/{table}/removeUser', { table: tableId });
            await this.api.post<ITableData>(uri, { userId: this.auth.currentUser?.uid }).pipe(first()).toPromise();
            await this.zone.run(async () => {
              this.auth.currentUserInstance$.subscribe((user) => {
                this.currentUser = user;
              });
              this.showthisButton = false;
              this.showQRButton = false;
              window.location.assign('/regcustomer/home');
              //  await this.navCtrl.navigateRoot(['/regcustomer/home']);
            });
          },
        },
        {
          text: 'Not now',
          cssClass: 'alert-button-cancel',
          role: 'dismiss',
          handler: async () => {
            console.log('Not Now');
          },
        },
      ],
    });
    await alert.present();
  }

  async goToMainMenu(path: string, direction: 'root' | 'forward' | 'backward' = 'forward') {
    // this.restaurantService.clear();
    //  window.location.reload();
    this.subscriptions.unsubscribe();
    await this.zone.run(async () => {
      // window.location.reload();
      switch (direction) {
        case 'backward':
          await this.navCtrl.navigateBack([path]);
          break;

        case 'forward':
          await this.navCtrl.navigateForward([path]);
          break;

        case 'root':
          await this.navCtrl.navigateRoot([path]);
          break;
      }
    });
  }

  async goTo(path: string, direction: 'root' | 'forward' | 'backward' = 'forward') {
    this.subscriptions.unsubscribe();
    await this.zone.run(async () => {
      switch (direction) {
        case 'backward':
          await this.navCtrl.navigateBack([path]);
          break;

        case 'forward':
          await this.navCtrl.navigateForward([path]);
          break;

        case 'root':
          await this.navCtrl.navigateRoot([path]);
          break;
      }
    });
  }
  async getRestaurant() {
    const restaurant = await this.restaurantService.restaurant$
      .pipe(
        filter((s) => s !== undefined),
        first()
      )
      .toPromise();
    this.openSession = restaurant?.data.name;
    return restaurant?.data.name;
  }
}
