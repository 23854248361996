import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IonModal } from '@ionic/angular/common';
import { distinct } from 'rxjs';
import { Restaurant } from '~/database/models/restaurant';
import { RestaurantLoadingService } from '~shared/services/restaurant-loading.service';
import { ThemeService } from '~shared/services/theme.service';

@Component({
  selector: 'app-restaurant-customization-loading-modal',
  templateUrl: './restaurant-customization-loading-modal.component.html',
  styleUrls: ['./restaurant-customization-loading-modal.component.scss'],
})
export class RestaurantCustomizationLoadingModalComponent implements OnInit {
  @ViewChild('modal', { static: true }) modal!: IonModal;

  // loadingProgress: Observable<number> | undefined;
  restaurantData: { name: string | null; restaurant: Restaurant | undefined } | null | undefined;
  isLoadingData: boolean = false;
  isLoadingCustomizations: boolean = false;
  isLoadingFeatures: boolean = false;

  constructor(
    protected loadingService: RestaurantLoadingService,
    public theme: ThemeService
  ) {}

  public ngOnInit(): void {
    this.loadingService.status$.pipe(distinct()).subscribe(async (status) => {
      console.time('🆘 ' + status);

      switch (status) {
        case 'loading':
        case 'unloading':
          await this.present();

          break;

        case 'loaded':
        case 'unloaded':
          await setTimeout(() => {
            this.dismiss();
          }, 2000);

          break;
        default:
          await setTimeout(() => {
            this.dismiss();
          }, 8000);
      }

      console.timeEnd('🆘 ' + status);
    });

    this.loadingService.restaurantData$.subscribe((data) => {
      this.restaurantData = data;
    });

    // this.loadingService.getDataStatus$().subscribe((status) => {
    //   if (status === 'loading') {
    //     this.isLoadingData = true;
    //   } else if (status === 'loaded') {
    //     this.isLoadingData = false;
    //   }
    // });

    // this.loadingService.customizationStatus$.subscribe((status) => {
    //   this.isLoadingCustomizations = status === 'loading';
    // });

    // this.loadingService.getFeaturesStatus().subscribe((status) => {
    //   this.isLoadingFeatures = status === 'loading';
    // });

    // this.loadingService.data$.subscribe((data) => {
    //   this.restaurantData = data;
    //   // Haz algo con los datos aquí
    // });
    // // this.restaurantData = this.loadingService.getRestaurantData();
    // this.loadingService.combinedLoadingProgress$.subscribe((combinedLoadingProgress) => {
    //   this.loadingProgress = this.loadingService.combinedLoadingProgress$;
    // });
  }

  async present() {
    await this.modal.present();
  }

  async dismiss() {
    // TODO: Agregar delay de 2 segundos
    await this.modal.dismiss();
  }

  // loadData() {
  //   this.loadingService.loadData();
  // }

  // loadCustomization() {
  //   this.loadingService.loadCustomizations();
  // }

  // loadFeatures() {
  //   this.loadingService.loadFeatures();
  // }
}
