import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, Subscription } from 'rxjs';
import { first, map, retry, shareReplay, startWith } from 'rxjs/operators';
import { Restaurant } from '~/database/models/restaurant';
import { IRestaurantData } from '~/database/models/restaurant-data.interface';
import { Table } from '~/database/models/table';
import { ITableData } from '~/database/models/table-data.interface';
import { TableSession } from '~/database/models/table-session';
import { ITableSessionData } from '~/database/models/table-session-data.interface';
import { LaravelApiService, resultIsCollection, UriRoute } from './laravel-api.service';

/**
 * Controla la session del restaurant activo.
 */
@Injectable({ providedIn: 'root' })
export class SessionService {
  private _initializing = false; // tslint:disable-line variable-name

  public get initializing(): boolean {
    return this._initializing;
  }

  /**
   */
  protected readonly restaurantSubject: BehaviorSubject<Restaurant | null | undefined>;

  /**
   * Si el valor es undefined, significa que aún no se ha inicializado.
   */
  public readonly restaurant$: Observable<Restaurant | null | undefined>;

  /**
   * Si el valor es undefined, significa que aún no se ha inicializado.
   */
  public get restaurant(): Restaurant | null | undefined {
    return this.restaurantSubject.value;
  }

  /**
   */
  protected readonly tableSubject: BehaviorSubject<Table | null | undefined>;

  /**
   * Si el valor es undefined, significa que aún no se ha inicializado.
   */
  public get table$(): Observable<Table | null | undefined> {
    return this.tableSubject.asObservable();
  }

  /**
   * Si el valor es undefined, significa que aún no se ha inicializado.
   */
  public get table(): Table | null | undefined {
    return this.tableSubject.value;
  }

  /**
   */
  protected readonly sessionSubject: BehaviorSubject<TableSession | null | undefined>;

  /**
   * Si el valor es undefined, significa que aún no se ha inicializado.
   */
  public get session$(): Observable<TableSession | null | undefined> {
    return this.sessionSubject.asObservable();
  }

  /**
   * Si el valor es undefined, significa que aún no se ha inicializado.
   */
  public get session(): TableSession | null | undefined {
    return this.sessionSubject.value;
  }

  protected subscriptions: Subscription | undefined;

  constructor(protected apiService: LaravelApiService) {
    this.sessionSubject = new BehaviorSubject<TableSession | null | undefined>(undefined);
    this.tableSubject = new BehaviorSubject<Table | null | undefined>(undefined);
    this.restaurantSubject = new BehaviorSubject<Restaurant | null | undefined>(undefined);

    this.restaurant$ = this.restaurantSubject.asObservable().pipe(shareReplay(1));
  }

  /**
   * Este método sólo debería ser usado por el AuthenticationService, no está pensado para ser usado directamente.
   *
   * Usar AuthenticationService.setCurrentRestaurant().
   */
  public async init(
    payload:
      | string
      | ({ [key: string]: string | undefined } & (
          | { restaurantId: string }
          | { tableId: string }
          | { tableSessionId: string }
        )),
    options = { force: false }
  ): Promise<void> {
    // TODO: Modificar lógica para, en vez de evitar que se actualize, comprobar si es el mismo restaurant y saltar.
    // Si es otro, entonces más bien cancelar el resto del proceso anterior.
    if (this.initializing) {
      console.warn('Current restaurant/table is still initializing');

      return;
    }

    let restaurantId: string | undefined;
    let tableId: string | null | undefined;
    let tableSessionId: string | undefined;

    if (typeof payload === 'string') {
      restaurantId = payload;
      tableSessionId = undefined;
      tableId = null;
    } else {
      restaurantId = payload.restaurantId;
      tableSessionId = payload.tableSessionId;
      tableId = payload.tableId ?? null;
    }

    this._initializing = true;
    if (this.subscriptions && !this.subscriptions.closed) {
      this.subscriptions.unsubscribe();
    }

    this.subscriptions = new Subscription();

    if (tableSessionId) {
      // console.log(options.force);
      if (this.session?.id === tableSessionId && !options.force) {
        console.warn('Current table session is already initialized. Use `options` param to force reload');

        this._initializing = false;
        return;
      }

      let session: TableSession | null | undefined;
      this.tableSubject.next(undefined);
      this.restaurantSubject.next(undefined);

      try {
        session = await this.apiService
          .get<ITableSessionData>(new UriRoute('table-sessions/{table-session}', { 'table-session': tableSessionId }))
          .pipe(
            retry(2),
            first(),
            map((res) => {
              if (!res.data || resultIsCollection(res)) {
                return null;
              }

              return new TableSession(res.data, res.data.id);
            })
          )
          .toPromise();
      } catch (err) {
        this._initializing = false;

        throw err;
      }

      this.sessionSubject.next(session);

      tableId = session?.data.tableId;
    }
    // console.log('tableId' + tableId);
    if (tableId) {
      console.log(options.force);
      if (this.table?.id === tableId && !options.force) {
        console.warn('Current table is already initialized. Use `options` param to force reload');

        this._initializing = false;
        return;
      }

      let table: Table | null | undefined;
      this.tableSubject.next(table);
      this.restaurantSubject.next(undefined);

      try {
        table = await firstValueFrom(
          this.apiService.get<ITableData>(new UriRoute('tables/{table}', { table: tableId })).pipe(
            retry(2),
            first(),
            map((res) => {
              if (!res.data || resultIsCollection(res)) {
                return null;
              }

              return new Table(res.data, res.data.id);
            })
          )
        );
      } catch (err) {
        this._initializing = false;

        throw err;
      }

      this.tableSubject.next(table);

      if (table) {
        console.log(table);
        // Mantener sincronizada la data de la mesa desde Firestore en tiempo real
        const tableCopy = table;

        const tableSubscription = tableCopy
          .odm()
          .doc()
          .valueChanges()
          .pipe(
            map((tableData) => {
              // console.log(sessionData, tableCopy);
              return new Table(tableData ?? tableCopy?.data, tableCopy.id, tableCopy.parentPath);
            })
          )
          .subscribe((firestoreTable) => {
            this.tableSubject.next(firestoreTable);
          });
        if (this.subscriptions) {
          this.subscriptions.add(tableSubscription);
        }
      }

      if (this.session) {
        // TODO: Evitar tantos emit de las sesiones
        // Se necesita el path del restaurant para poder sincronizar desde Firestore
        // Mantener sincronizada la data de la sesión desde Firestore en tiempo real
        const sessionCopy = this.session;

        const sessionSubscription = sessionCopy
          .odm()
          .doc()
          .valueChanges()
          .pipe(
            map((sessionData) => {
              // console.log(sessionData, sessionCopy);
              return new TableSession(
                sessionData ?? sessionCopy?.data,
                sessionCopy.id,
                sessionCopy.parentPath ?? table?.parentPath
              );
            })
          )
          .subscribe((tableSession) => {
            this.sessionSubject.next(tableSession);
          });
        if (this.subscriptions) {
          this.subscriptions.add(sessionSubscription);
        }
      }

      restaurantId = table?.data.teamId;
    }

    if (restaurantId) {
      if (this.restaurant?.id === restaurantId && !options.force) {
        console.warn('Current restaurant is already initialized. Use `options` param to force reload');

        this._initializing = false;
        return;
      }

      if (this.session) {
        this.sessionSubject.next(new TableSession(this.session.data, this.session.id, `restaurants/${restaurantId}`));
      }

      if (!tableId) {
        // Al cambiar el restaurant la mesa se elimina
        this.tableSubject.next(null);
      }

      let restaurant: Restaurant | null | undefined;
      this.restaurantSubject.next(restaurant);

      try {
        restaurant = await this.apiService
          .get<IRestaurantData>(new UriRoute('teams/{team}', { team: restaurantId }))
          .pipe(
            retry(2),
            first(),
            map((res) => {
              if (!res.data || resultIsCollection(res)) {
                return null;
              }

              return new Restaurant(res.data, res.data.id);
            })
          )
          .toPromise();
        if (restaurant) {
          const restaurantSub = restaurant
            .odm()
            .doc()
            .valueChanges()
            .pipe(
              map((rData) => {
                if (!rData) {
                  return null;
                }

                return new Restaurant(rData, rData.id);
              })
            )
            .subscribe((res) => {
              this.restaurantSubject.next(res);
            });

          this.subscriptions.add(restaurantSub);
        }
      } catch (err) {
        this._initializing = false;

        throw err;
      }

      this.restaurantSubject.next(restaurant);
    }

    this._initializing = false;
  }

  /**
   * Elimina el restaurant y sesión activa.
   */
  public clear(): void {
    this.sessionSubject.next(null);
    this.tableSubject.next(null);
    this.restaurantSubject.next(null);

    if (this.subscriptions && !this.subscriptions.closed) {
      this.subscriptions.unsubscribe();
    }

    this.subscriptions = undefined;
  }
}
