import { GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import '@angular/common/locales/global/en';
import '@angular/common/locales/global/en-CH';
import '@angular/common/locales/global/es';
import '@angular/common/locales/global/es-419';
import '@angular/common/locales/global/es-CL';
import '@angular/common/locales/global/es-VE';

import { CurrencyPipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMap } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { FaConfig, FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { environment, firebaseConfig } from 'src/environments/environment';
import '~lib/extensions/string';
import { ServiceLocator } from '~lib/helpers/service-locator';
import { SharedComponentsModule } from '~shared/components/shared-components.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/**
 * Loader para las traducciones.
 */
export function translateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({ declarations: [
        AppComponent,
    ],
    exports: [
        FontAwesomeModule,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule.forRoot({ mode: 'md' }),
        AppRoutingModule,
        NgxSkeletonLoaderModule.forRoot(),
        TranslateModule.forRoot({
            defaultLanguage: 'es',
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoaderFactory,
                deps: [HttpClient],
            },
        }),
        AngularFireModule.initializeApp(firebaseConfig),
        AngularFireAuthModule,
        AngularFirestoreModule,
        FontAwesomeModule,
        SharedComponentsModule,
        BrowserAnimationsModule,
        SocialLoginModule,
        ZXingScannerModule,
        IonicStorageModule.forRoot()], providers: [
        // GooglePlus was a Cordova Plugin. Consider using @codetrix-studio/capacitor-google-auth@3.1.*
        // https://github.com/CodetrixStudio/CapacitorGoogleAuth/blob/3.1.4/package.json
        // GooglePlus,
        GoogleMap,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(environment.googleAuthClientId, {
                            scopes: 'email',
                            oneTapEnabled: false,
                            // oneTapEnabled: true,
                            // prompt: 'select_account',
                            // plugin_name: 'befaster-google-login',
                        }),
                    },
                ],
            } as SocialAuthServiceConfig,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
  constructor(library: FaIconLibrary, injector: Injector, faConfig: FaConfig) {
    library.addIconPacks(fas, far, fab);

    faConfig.fixedWidth = true;
    faConfig.defaultPrefix = 'fas';

    ServiceLocator.injector = injector;
  }
}
