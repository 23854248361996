import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinct, map, shareReplay } from 'rxjs/operators';
import { Restaurant } from '~/database/models/restaurant';
import { Setting } from '~/database/models/setting';
import { ISettingData } from '~/database/models/setting.data';
import { ICurrencySetting } from './currency-setting';
import { LocaleService } from './locale.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class RestaurantCurrencyService {
  private _initializing: boolean | undefined = undefined;

  public get initializing(): boolean | undefined {
    return this._initializing;
  }

  protected readonly currencySettingSubject: BehaviorSubject<Setting<ICurrencySetting> | null | undefined>;

  /**
   * Stream de configuración de monedas del restaurante.
   *
   * Nota: Si el valor es `undefined`, significa que aún no se ha inicializado.
   */
  public readonly currencySetting$: Observable<Setting<ICurrencySetting> | null | undefined>;

  protected subscriptions: Subscription | undefined;

  constructor(protected session: SessionService) {
    this.currencySettingSubject = new BehaviorSubject<Setting<ICurrencySetting> | null | undefined>(undefined);

    this.currencySetting$ = this.currencySettingSubject.asObservable().pipe(shareReplay(1));

    session.restaurant$
      .pipe(
        distinct((value) => {
          if (!value) {
            // Log filtered value
            return value;
          }

          const id = value.id;
          // Log restaurant id
          return id;
        })
      )
      .subscribe((restaurant) => {
        this.init({ restaurant });
      });
  }

  public init({ restaurant, force = false }: { restaurant: Restaurant | null | undefined; force?: boolean }) {
    if (!restaurant) {
      this.currencySettingSubject.next(restaurant === null ? null : undefined);
      return;
    }

    if (this.initializing) {
      return;
    }

    this.clean();

    this.subscriptions = new Subscription();
    const currencySettingSubscription = restaurant
      .odm()
      .child<ISettingData<ICurrencySetting>>('settings', (query) => {
        return query.where('key', '==', ICurrencySetting.KEY);
      })
      .snapshotChanges()
      .pipe(
        map((settingSnapshot) => {
          return settingSnapshot.map(({ payload }) => {
            return Setting.fromPayloadDocument(payload.doc) as Setting<ICurrencySetting>;
          });
        })
      )
      .subscribe((settings) => {
        if (settings.length === 0) {
          console.log('THERE ARE NO SETTINGS: ', settings);

          // Use default value if settings are not available
          const defaultSetting = new Setting<ICurrencySetting>({
            key: ICurrencySetting.KEY,
            value: ICurrencySetting.defaultValue(),
          });
          this.currencySettingSubject.next(defaultSetting);
          return;
        }
        console.log('THERE ARE SETTINGS :smile:', settings);

        const setting = settings[0];
        let updatedValue: ICurrencySetting;

        if (!setting.data.value) {
          console.log('NO HAY CURRENCY', settings);

          // Use default value if settings are not available
          const defaultSetting = new Setting<ICurrencySetting>({
            key: ICurrencySetting.KEY,
            value: ICurrencySetting.defaultValue(),
          });
          this.currencySettingSubject.next(defaultSetting);
          return;
        } else {
          console.log('HAY CURRENCY');
          // Use currency if currencies array is empty
          const updatedValue = new Setting<ICurrencySetting>({
            key: ICurrencySetting.KEY,
            value: setting.data.value,
          });
          this.currencySettingSubject.next(updatedValue);
        }
      });
    this.subscriptions.add(currencySettingSubscription);
  }
  public clean(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
      this.subscriptions = undefined;
    }

    this.currencySettingSubject.next(undefined);
    this._initializing = undefined;
  }
}
