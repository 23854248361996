import { animate, animateChild, query, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import _ from 'lodash-es';

@Component({
  selector: 'app-modal [view="payment-modal"]',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss'],
  animations: [
    trigger('ngIfAnimation', [transition(':enter, :leave', [query('@*', animateChild())])]),
    trigger('easeInOut', [
      transition('void => *', [
        style({
          opacity: 0,
        }),
        animate(
          '500ms ease-in',
          style({
            opacity: 1,
          })
        ),
      ]),
      transition('* => void', [
        style({
          opacity: 1,
        }),
        animate(
          '500ms ease-in',
          style({
            opacity: 0,
          })
        ),
      ]),
    ]),
  ],
})
export class PaymentModalComponent {
  @Input() firstName: string | undefined;
  @Input() lastName: string | undefined;
  @Input() middleInitial: string | undefined;
  @Input() imageUrl: string | undefined;
  constructor(public modalController: ModalController) {}
  public dismiss() {
    this.modalController.dismiss({}, 'cancel');
  }
}
