import { ElementRef, Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapService implements OnInit, OnDestroy {
  map: google.maps.Map | undefined;
  private geocoder: google.maps.Geocoder | undefined;

  public constructor() {}
  ngOnInit() {}
  ngOnDestroy(): void {}

  async initGeocoder(): Promise<void> {
    const { Geocoder } = (await google.maps.importLibrary('geocoding')) as google.maps.GeocodingLibrary;
    this.geocoder = new Geocoder();
  }

  getGeocoder(): any {
    if (!this.geocoder) {
      throw new Error('Geocoder not initialized. Call initGeocoder() first.');
    }
    return this.geocoder;
  }
}
