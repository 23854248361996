import { Injectable } from '@angular/core';
import { Query } from '@angular/fire/compat/firestore';
import * as _ from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IRatingData, Rating } from '~/database/models/rating';

import { AuthenticationService } from './authentication.service';
import { LaravelApiService } from './laravel-api.service';
import { SessionService } from './session.service';

export interface IRatingFilters {
  teamId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class RatingsService {
  #orders: Observable<Rating[]> | undefined;
  public list$: Observable<Rating[]> | undefined;
  constructor(
    protected readonly api: LaravelApiService,
    protected readonly auth: AuthenticationService,
    protected readonly currentRestaurant: SessionService
  ) {}

  getBookings(filters: IRatingFilters = {}): Observable<Rating[]> {
    filters = _.omitBy(filters, _.isUndefined) as IRatingFilters;
    console.log(this.currentRestaurant.restaurant);
    if (this.currentRestaurant.restaurant?.id) filters.teamId = this.currentRestaurant.restaurant?.id;
    // const currentUser = this.auth.currentUser?.uid;
    // if(!currentUser){
    //   throw new Error('Undefined Customer');
    // }

    const result = new Rating()
      .odm()
      .collection((ref) => {
        let q: Query<IRatingData> | undefined;

        _.each(filters, (value, filter) => {
          if (!q) {
            // FIXME: ¿Posible bug? ¿No debería asignar a q?
            q = ref.where(filter, '==', value);
          } else {
            q = q.where(filter, '==', value);
          }
        });

        if (q) {
          q = q.orderBy('updatedAt', 'desc');
        } else {
          q = ref.orderBy('updatedAt', 'desc');
        }

        return q;
      })
      .snapshotChanges()
      .pipe(
        map((action) => {
          return action.map((o) => {
            // TODO: Finetune payload.type
            const payments = new Rating(o.payload.doc.data(), o.payload.doc.id, o.payload.doc.ref.parent.parent?.path);

            return payments;
          });
        })
      );
    return result;
  }
}
