// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: contents;
}
:host ion-card {
  --background: transparent;
  margin: 0 0 5px;
  border-radius: 0px;
}
:host ion-card div.card-background {
  min-height: var(--card-min-height, 0);
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  text-decoration: none;
}
:host ion-card div.card-background .card-button-holder {
  position: absolute;
  left: 0;
  right: 0;
  padding: 15px;
}
:host ion-card div.card-background ion-badge {
  font-size: 1.5em;
  font-weight: 500;
  padding: 10px 15px;
  border-radius: 10px;
  border: none;
  opacity: 1;
  box-shadow: 0px 0px 10px #333;
}
@media screen and (max-width: 480px) {
  :host ion-card div.card-background ion-badge {
    font-size: 16px;
    border-radius: 10px;
  }
}
:host ion-card div.card-background ion-badge.promocode {
  font-size: 1.3em;
  white-space: normal;
  align-self: flex-end;
  border-radius: 10px;
  padding: 10px 3px;
}
:host ion-card div.card-background ion-badge.promodescription {
  font-size: 1em;
  background: rgba(0, 0, 0, 0.7);
  margin-bottom: 20px;
  white-space: normal;
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/category-card/category-card.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AACE;EACE,yBAAA;EACA,eAAA;EACA,kBAAA;AACJ;AACI;EACE,qCAAA;EACA,sCAAA;EACA,uCAAA;EACA,iCAAA;EACA,aAAA;EACA,wBAAA;EACA,oBAAA;EACA,qBAAA;AACN;AAAM;EACE,kBAAA;EACA,OAAA;EACA,QAAA;EACA,aAAA;AAER;AAAM;EACE,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,UAAA;EACA,6BAAA;AAER;AAAM;EACE;IACE,eAAA;IACA,mBAAA;EAER;AACF;AAAM;EACE,gBAAA;EACA,mBAAA;EACA,oBAAA;EACA,mBAAA;EACA,iBAAA;AAER;AAAM;EACE,cAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;AAER","sourcesContent":[":host {\n  display: contents;\n\n  ion-card {\n    --background: transparent;\n    margin: 0 0 5px;\n    border-radius: 0px;\n\n    div.card-background {\n      min-height: var(--card-min-height, 0);\n      background-position: center !important;\n      background-repeat: no-repeat !important;\n      background-size: cover !important;\n      display: flex;\n      justify-content: stretch;\n      align-items: stretch;\n      text-decoration: none;\n      .card-button-holder {\n        position: absolute;\n        left: 0;\n        right: 0;\n        padding: 15px;\n      }\n      ion-badge {\n        font-size: 1.5em;\n        font-weight: 500;\n        padding: 10px 15px;\n        border-radius: 10px;\n        border: none;\n        opacity: 1;\n        box-shadow: 0px 0px 10px #333;\n      }\n      @media screen and (max-width: 480px) {\n        ion-badge {\n          font-size: 16px;\n          border-radius: 10px;\n        }\n      }\n      ion-badge.promocode {\n        font-size: 1.3em;\n        white-space: normal;\n        align-self: flex-end;\n        border-radius: 10px;\n        padding: 10px 3px;\n      }\n      ion-badge.promodescription {\n        font-size: 1em;\n        background: rgba(0, 0, 0, 0.7);\n        margin-bottom: 20px;\n        white-space: normal;\n        border-radius: 10px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
