import IFirebaseData from '~lib/database/firebase-data.interface';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';
import { Gender } from './gender.enum';
import { IUserAddressData } from './user-address-data.interface';

/**
 * Representa un plan que agrupa módulos.
 */
export class UserAddress extends Model<IUserAddressData> {
  public static type = 'user_addresses';

  constructor(data: Partial<IUserAddressData> = {}, id: string | null = null, parentPath?: string) {
    const defaultData: IUserAddressData = {
      id: null,
      user_id: '',
      address: '',
      addressType: '',
      deliveryNotes: '',
      appartmentNumber: '',
      latitude: 0,
      longitude: 0,
      defaultAddress: false,
      image: null,
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
    };

    // Eliminar los ya predeterminados
    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
    return this.rawData;
  }
}
