import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestaurantType } from '~/database/models/restaurant-type';
import { User } from '~/database/models/user';
import { AuthenticationService } from '~shared/services/authentication.service';
import { RestaurantTypeService } from '~shared/services/restaurant-types.service';
/**
 * Servicio para inicializar y mantener un observable de los pedidos del cliente actual.
 */
@Injectable({
  providedIn: 'root',
})
export class AllRestaurantTypesService {
  #restaurants$: Observable<RestaurantType[]> | undefined;

  public get restaurants$(): Observable<RestaurantType[]> | undefined {
    return this.#restaurants$;
  }
  public currentUser: User | null | undefined;
  constructor(
    private restaurants: RestaurantTypeService,
    private auth: AuthenticationService
  ) {
    const userSub = this.auth.currentUserInstance$.subscribe((user) => {
      this.currentUser = user;
    });
  }

  initializeCommerces(): Observable<RestaurantType[]> {
    this.#restaurants$ = this.restaurants.getRestaurantName();
    return this.#restaurants$;
  }
}
