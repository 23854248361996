// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: contents;
}
:host .label-contrast {
  color: var(--ion-color-primary-contrast);
}
:host .text-color {
  color: var(--ion-text-color);
}
:host ion-card {
  margin: 0;
}
:host ion-card ion-grid {
  --ion-grid-column-padding: 5px;
}
:host .big-icon {
  font-size: 2.1em;
}
:host .ion-activatable {
  overflow: hidden;
  position: relative;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/order-card/order-card.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAAE;EACE,wCAAA;AAEJ;AAAE;EACE,4BAAA;AAEJ;AAAE;EACE,SAAA;AAEJ;AAAI;EACE,8BAAA;AAEN;AAEE;EACE,gBAAA;AAAJ;AAGE;EACE,gBAAA;EACA,kBAAA;EACA,eAAA;AADJ","sourcesContent":[":host {\n  display: contents;\n  .label-contrast {\n    color: var(--ion-color-primary-contrast);\n  }\n  .text-color {\n    color: var(--ion-text-color);\n  }\n  ion-card {\n    margin: 0;\n\n    ion-grid {\n      --ion-grid-column-padding: 5px;\n    }\n  }\n\n  .big-icon {\n    font-size: 2.1em;\n  }\n\n  .ion-activatable {\n    overflow: hidden;\n    position: relative;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
