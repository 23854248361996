import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { CreateAddressForm } from '~shared/forms/create-address.form';
import { UpdateAddressForm } from '~shared/forms/update-address.form';
import { CountryService, ICountryDataAttributes } from '~shared/services/country.service';
import { GoogleMapService } from '~shared/services/googlemap.service';

@Component({
  selector: 'app-google-maps-modal',
  templateUrl: './google-maps-modal.component.html',
  styleUrls: ['./google-maps-modal.component.scss'],
})
export class GoogleMapsModalComponent implements OnInit, AfterViewInit {
  @Input() public latitude: number | undefined;
  @Input() public longitude: number | undefined;
  @Input() public addresses: any | undefined;
  @Input() public currentUser: any | undefined;
  @Output() public updatedAddress = new EventEmitter<void>();
  @ViewChild('mapRef', { static: false }) public mapContainer: ElementRef | undefined;
  protected countries: ICountryDataAttributes[] | undefined;
  private map: google.maps.Map | undefined;
  private marker: google.maps.marker.AdvancedMarkerElement | undefined;
  private geoCoder: google.maps.Geocoder | undefined;
  private postal_code: string | undefined;
  private locality: string | undefined;
  private country_id: string | undefined;
  @Input() selectedValue: boolean | undefined;
  @ViewChild('search', { static: false }) public searchElementRef: ElementRef | undefined;
  private center: { lat: number; lng: number } | undefined;
  protected form: UpdateAddressForm | CreateAddressForm | undefined;
  protected addressTitle: string | undefined;
  private markerCoordinatesSubject = new BehaviorSubject<{ lat: number | undefined; lng: number | undefined }>({
    lat: 0,
    lng: 0,
  });
  private markerCoordinates$ = this.markerCoordinatesSubject.asObservable();
  protected isCountry: boolean = false;
  protected isModalOpen: boolean = false;
  protected isModalOpen2: boolean = false;
  protected error: boolean = false;
  public constructor(
    public modalController: ModalController,
    private googleMapService: GoogleMapService,
    private zone: NgZone,
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    private countryService: CountryService
  ) {
    this.countryService.availableCountries$.subscribe((data) => {
      this.countries = data;
    });
  }

  public ngOnInit() {}

  public async ngAfterViewInit() {
    await this.loadGeoCoder();
  }

  protected handleChange(e: { detail: { value: string } }) {
    console.log('ionChange fired with value: ' + e.detail.value);
    console.log(this.countries);

    let currentCountry = this.countries?.filter((country) => country.id == e.detail.value);
    if (currentCountry) {
      this.latitude = currentCountry[0].attributes.geoPoint.latitude;
      this.longitude = currentCountry[0].attributes.geoPoint.longitude;
      this.getAddress(this.latitude, this.longitude);
      this.initMap();
      this.loadAutoComplete();
      this.isCountry = true;
    }
  }
  private async initMap(): Promise<void> {
    const { Map } = (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary;
    const { AdvancedMarkerElement } = (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary;

    this.center = { lat: this.latitude || 0, lng: this.longitude || 0 };
    this.map = new Map(this.mapContainer?.nativeElement, {
      center: this.center,
      zoom: 16,
      mapId: 'MAP_ID',
      clickableIcons: false,
      fullscreenControl: true,
      streetViewControl: false,
      mapTypeControl: false,
    });

    console.log('Map loaded');

    this.marker = new AdvancedMarkerElement({
      map: this.map,
      position: this.center,
      gmpDraggable: true,
    });

    this.marker.addListener('dragend', (event: any) => {
      this.dragEnd(event);
    });
  }

  private dragEnd(event: any) {
    if (event) {
      let newCoords = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      this.markerCoordinatesSubject.next(newCoords);
      this.getAddress(newCoords.lat, newCoords.lng);
    } else {
      this.markerCoordinatesSubject.next({ lat: this.latitude, lng: this.longitude });
    }
  }
  private async loadGeoCoder() {
    await this.googleMapService.initGeocoder();
    // Example usage of Geocoder
    const geocoder = this.googleMapService.getGeocoder();
    this.geoCoder = geocoder;
  }
  private async loadAutoComplete(): Promise<void> {
    const { Autocomplete } = (await google.maps.importLibrary('places')) as google.maps.PlacesLibrary;
    //this.setCurrentLocation();
    if (this.searchElementRef?.nativeElement) {
      // Verificar que searchElementRef no sea undefined
      let autocomplete = new Autocomplete(this.searchElementRef?.nativeElement, {
        componentRestrictions: {
          country: [
            'VE',
            'CH',
            'CL',
          ],
        },
        types: ['address'],
      });

      autocomplete.addListener('place_changed', () => {
        this.zone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.latitude = place.geometry.location?.lat();
          this.longitude = place.geometry.location?.lng();

          this.getAddress(this.latitude, this.longitude);
          if (this.latitude && this.longitude) {
            this.center = { lat: this.latitude, lng: this.longitude };
          }
          this.initMap();
        });
      });
    }
  }
  private getAddress(latitude: number | undefined, longitude: number | undefined) {
    if (this.geoCoder) {
      this.geoCoder.geocode(
        { location: { lat: Number(latitude), lng: Number(longitude) } },
        (results: any, status: any) => {
          if (status === 'OK') {
            if (results[0]) {
              for (let i = 0; i < results[0].address_components.length; i++) {
                if (results[0].address_components[i].types[0] === 'postal_code') {
                  this.postal_code = results[0].address_components[i].long_name;
                }
                if (results[0].address_components[i].types[0] === 'locality') {
                  this.locality = results[0].address_components[i].long_name;
                }
                if (results[0].address_components[i].types[0] === 'country') {
                  this.country_id = results[0].address_components[i].short_name;
                }
              }
              this.addressTitle = results[0].formatted_address;
            } else {
              console.log('No results found');
            }
          } else {
            console.log('Geocoder failed due to: ' + status);
          }
        }
      );
    }
  }

  protected setOpen(isOpen: boolean) {
    this.isModalOpen = isOpen;

    if (this.addresses) {
      this.isCountry = true;
      this.addressTitle = this.addresses.data.extraLines;
      this.form = new UpdateAddressForm(this.addresses.id, {
        extraLines: this.addressTitle,
        reference: this.addresses.data.reference,
        title: this.addresses.data.title,
      });
    } else if (!this.addresses) {
      this.form = new CreateAddressForm(this.currentUser.id);
    }
    if (!isOpen) {
      this.isCountry = false;
    }
  }
  protected setOpen2(isOpen: boolean) {
    this.isModalOpen2 = isOpen;
    if (this.form) {
      this.form.patchValue({ extraLines: this.addressTitle });
    }

    if (isOpen && this.isCountry) {
      this.initMap();
      this.loadAutoComplete();
    } else if (!isOpen) {
      this.markerCoordinates$.subscribe((coords) => {
        if (coords.lat != 0 && coords.lat != 0) {
          this.latitude = coords.lat;
          this.longitude = coords.lng;
          this.getAddress(this.latitude, this.longitude);
        }
      });
    } else {
      console.log('NEW ADDRESS TO BE SAVED', this.latitude, this.longitude);
    }
  }

  protected async submit() {
    const loading = await this.loadingCtrl.create({
      cssClass: 'custom-loading',
      backdropDismiss: false,
      message: 'Saving your selection...',
    });

    if (this.form instanceof CreateAddressForm) {
      if (!this.postal_code || !this.locality || !this.country_id) {
        return (this.error = true);
      }
    }

    await loading.present();

    if (this.form) {
      this.form.patchValue({
        country_id: this.country_id,
        collection: 'delivery',
        extra_lines: this.addressTitle,
        geo_point: { latitude: this.latitude, longitude: this.longitude },
        is_default: this.selectedValue,
        postal_code: this.postal_code,
        city: this.locality,
      });
      await this.zone.runOutsideAngular(async () => {
        let success: string | boolean = false;

        try {
          if (this.form) {
            success = await this.form.submit();
          }
        } catch (err) {
          await loading.dismiss();

          console.error('Unhandled error', err);
          this.toastCtrl
            .create({
              message: 'No ha sido posible guardar el estado',
              duration: 3000,
              position: 'top',
              color: 'danger',
            })
            .then((toast) => {
              toast.present();
            });
        }

        this.zone.run(() => {
          if (this.form) {
            this.form.updateValueAndValidity();
          }

          if (success) {
            loading.dismiss();
            this.isModalOpen = false;
            this.updatedAddress.emit();
            this.toastCtrl
              .create({
                message: 'Address Updated.',
                duration: 3000,
                color: 'success',
                position: 'top',
              })
              .then((toast) => {
                toast.present();
              });
          } else {
            loading.dismiss();
            console.error('Unhandled error');
            this.toastCtrl
              .create({
                message: 'No ha sido posible guardar la direccion',
                duration: 3000,
                position: 'top',
                color: 'danger',
              })
              .then((toast) => {
                toast.present();
              });
            return;
          }
        });
      });
    }
  }
  handleCancel() {
    console.log('ionCancel fired');
  }
  handleDismiss() {
    console.log('ionDismiss fired');
  }
}
