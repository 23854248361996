// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ion-item-divider {
  border-color: var(--ion-text-color);
}
:host ion-item-divider:not(:first-child) {
  margin-top: 1em;
}
:host ion-item-divider ion-label {
  width: 100%;
}
:host ion-item-divider [slot=start] {
  margin-right: 8px;
}
:host [app-viewport],
:host [overlay-portal],
:host [nav-viewport],
:host [tab-portal],
:host .nav-decor {
  display: none !important;
  background: none transparent !important;
}
:host ion-alert.custom-alert {
  --backdrop-opacity: 0.7;
}
:host .custom-alert .alert-button-group {
  padding: 8px;
}
:host button.alert-button.alert-button-confirm {
  background-color: var(--ion-color-success);
  color: var(--ion-color-success-contrast);
}
:host button.alert-button.alert-button-confirm {
  border-radius: 4px;
}
:host .custom-alert button.alert-button {
  border: 0.55px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.2);
}
:host button.alert-button.alert-button-cancel {
  border-right: 0;
  border-bottom-left-radius: 13px;
  border-top-left-radius: 13px;
}
:host button.alert-button.alert-button-confirm {
  border-bottom-right-radius: 13px;
  border-top-right-radius: 13px;
}
:host .usericon {
  margin-right: 20px;
}
:host ion-toolbar img.brand-logo {
  display: inline-block !important;
  max-height: 24px;
  object-fit: contain;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AACE;EACE,mCAAA;AAAJ;AACI;EACE,eAAA;AACN;AAEI;EACE,WAAA;AAAN;AAGI;EACE,iBAAA;AADN;AAIE;;;;;EAKE,wBAAA;EACA,uCAAA;AAFJ;AAKE;EACE,uBAAA;AAHJ;AAME;EACE,YAAA;AAJJ;AAOE;EACE,0CAAA;EACA,wCAAA;AALJ;AAQE;EACE,kBAAA;AANJ;AASE;EACE,kEAAA;AAPJ;AAUE;EACE,eAAA;EACA,+BAAA;EACA,4BAAA;AARJ;AAWE;EACE,gCAAA;EACA,6BAAA;AATJ;AAWE;EACE,kBAAA;AATJ;AAaM;EACE,gCAAA;EACA,gBAAA;EACA,mBAAA;AAXR","sourcesContent":[":host {\n  ion-item-divider {\n    border-color: var(--ion-text-color);\n    &:not(:first-child) {\n      margin-top: 1em;\n    }\n\n    ion-label {\n      width: 100%;\n    }\n\n    [slot='start'] {\n      margin-right: 8px;\n    }\n  }\n  [app-viewport],\n  [overlay-portal],\n  [nav-viewport],\n  [tab-portal],\n  .nav-decor {\n    display: none !important;\n    background: none transparent !important;\n  }\n\n  ion-alert.custom-alert {\n    --backdrop-opacity: 0.7;\n  }\n\n  .custom-alert .alert-button-group {\n    padding: 8px;\n  }\n\n  button.alert-button.alert-button-confirm {\n    background-color: var(--ion-color-success);\n    color: var(--ion-color-success-contrast);\n  }\n\n  button.alert-button.alert-button-confirm {\n    border-radius: 4px;\n  }\n\n  .custom-alert button.alert-button {\n    border: 0.55px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.2);\n  }\n\n  button.alert-button.alert-button-cancel {\n    border-right: 0;\n    border-bottom-left-radius: 13px;\n    border-top-left-radius: 13px;\n  }\n\n  button.alert-button.alert-button-confirm {\n    border-bottom-right-radius: 13px;\n    border-top-right-radius: 13px;\n  }\n  .usericon {\n    margin-right: 20px;\n  }\n  ion-toolbar {\n    img {\n      &.brand-logo {\n        display: inline-block !important;\n        max-height: 24px;\n        object-fit: contain;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
